import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { countries } from "country-flags-svg";
import { BehaviorSubject, map, Observable, of, shareReplay } from "rxjs";

import { UriConfig } from "@app/app.config";
import { languageCodesArray } from "@components/_common-modal-components/create-vendor-modal/locale-list";
import { LocalesEntityEnum } from "@enums/LocalesEntityEnum";
import { LocalesTypeEnum } from "@enums/LocalesTypeEnum";
import { CreateLocaleItemModel } from "@models/CreateLocaleItemModel";
import { CreateGoogleTranslateModel, GoogleTranslateModel } from "@models/GoogleTranslateModel";
import { LocaleItemModel } from "@models/LocaleItemModel";
import { LocaleModel } from "@models/LocaleModel";
import { LocalesItemRequestModel, LocalesRequestModel } from "@models/LocalesItemRequestModel";
import { LocaleItem, LocalesResponseModel } from "@models/LocalesResponseModel";
import { ProfileService } from "@services/profile.service";
import { QuestionsBankService } from "@services/questions-bank.service";

import { TextService } from "./text.service";

const CountryLanguage = require('@ladjs/country-language');
const langs = require('langs');

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  detectedLanguage: { lang: string, questionId?: number }[];

  fullLocaleData$: Observable<LocaleModel[]>;

  needUpdateLocaleList$  = new BehaviorSubject(true);

  vendorLocales$: Observable<LocaleItemModel[]>;

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
    private textService: TextService,
    private fb: NonNullableFormBuilder,
    private questionBankService: QuestionsBankService,
    private profileService: ProfileService
  ) {
  }

  getRoundLocales(roundId: number) {
    return this.http.get<LocalesResponseModel>(this.uriConfig.localizations + '/' + roundId).pipe(
      map((round) => {
        const isStreak = this.profileService.currentUser$.value?.isStreakVendor;
        if (!isStreak && round.questions) {
          round.questions = round.questions.map(question => {
            if (!question.answers) {
              return question;
            } else {
              question.answers = question.answers.filter(answer => !this.questionBankService.externalAnswerOptions.includes(answer.text));
              return question;
            }
          })
        }
        return round;
      })
    );
  }

  bulkSaveLocale(body: LocalesRequestModel) {
    return this.http.post<LocaleItem[]>(this.uriConfig.localizations, body);
  }

  bulkEditLocale(body: LocalesRequestModel) {
    return this.http.patch<LocaleItem[]>(this.uriConfig.localizations, body);
  }

  getGoogleTranslation(body: { items: CreateGoogleTranslateModel[] }) {
    return this.http.post<{ data: GoogleTranslateModel[] }>(this.uriConfig.localizations + '/translate', body);
  }

  getVendorLocales(): Observable<LocaleItemModel[]> {
    return this.http.get<LocaleItemModel[]>(this.uriConfig.locales);
  }

  createVendorLocale(vendorLocaleBody: CreateLocaleItemModel) {
    return this.http.post(this.uriConfig.locales, vendorLocaleBody);
  }

  editVendorLocale(vendorLocaleBody: CreateLocaleItemModel, localeId: number) {
    return this.http.patch(this.uriConfig.locales + '/' + localeId, vendorLocaleBody);
  }

  deleteVendorLocale(localeId: number) {
    return this.http.delete(this.uriConfig.locales + '/' + localeId);
  }

  fetchVendorLocale() {
    this.vendorLocales$ = this.getVendorLocales().pipe(
      shareReplay(1)
    )
  }

  /**
   * @param entity - entity
   * Prepare value for first column inn table, include name of entity and additional text or name value
   */
  getEntityValue(entity: string) {
    switch (entity) {
      case LocalesEntityEnum.SPORT_EVENT:
        return `${this.textService.capitalizeFirstLetter(entity).split('_').join(' ')} name`;
      case LocalesEntityEnum.BRAND_URL:
        return `Brand Upsell Source Url`;
      case LocalesEntityEnum.LEADERBOARD_NAME:
        return `Leaderboard Name`;
      case LocalesEntityEnum.TIER_NAME:
        return `Tier Name`;
      case LocalesEntityEnum.UPSELL_TITLE:
        return `Upsell Title`;
      case LocalesEntityEnum.UPSELL_DESCRIPTION:
        return `Upsell Description`;
      case LocalesEntityEnum.UPSELL_BUTTON:
        return `Upsell Button Name`;
      case LocalesEntityEnum.UPSELL_WEB_LINK:
        return `Upsell Web Link`;
      case LocalesEntityEnum.UPSELL_MOBILE_LINK:
        return `Upsell App Link`;
      case LocalesEntityEnum.ROUND:
        return `${this.textService.capitalizeFirstLetter(entity)} name`;
      case LocalesEntityEnum.STREAK:
        return `${this.textService.capitalizeFirstLetter(entity)} name`;
      case LocalesEntityEnum.TEAM:
        return `${this.textService.capitalizeFirstLetter(entity)} name`;
      case LocalesEntityEnum.ANSWER:
      case LocalesEntityEnum.QUESTION:
      case LocalesEntityEnum.PRIZE:
      case LocalesEntityEnum.LEADERBOARD:
        return `${this.textService.capitalizeFirstLetter(entity)} text`;
      case LocalesEntityEnum.DESCRIPTION:
        return `Round Description`; }
  }

  /**
   * @param localization - localization for entity with present value
   * create object where is key is locale ('es') and value is object with locale id and value
   */
  getLocaleValues(localization) {
    if (localization && localization.length) {
      return localization.reduce((acc, item) => {
        const { id, text } = item;
        acc[item.locale.i18n] = { text, id };
        return acc;
      }, {});
    }
    return null;
  }

  /**
   * @param entityList - list of entity
   * @param locales - vendor locale list
   * Prepare array of objects to send to google translation, include all questions text for all locales
   */
  createGoogleTranslateBody(entityList, locales) {
    return locales.reduce((acc, target) => {
      acc.push({
        target: target.i18n,
        q: entityList.map(entity => entity.text || entity.name)
      });
      return acc;
    }, []);
  }

  /**
   * @param entityLocaleSetting - locale details for current entity
   * @param entity - entity
   * @param vendorLocale - vendor locale list
   * Create form array, witch contain form control for each vendor locale based on entity
   * Array have additional controls:
   * id - id of current entity needs to create locale
   * localeId - this id present if locale was created, need to edit locale
   * entityValue - show as difinition in first column in table
   * entity - need to prepare request for save entity
   */
  prepareLocaleObject(entityLocaleSetting, entity, vendorLocale, maxLenght?) {
    const itemFormGroup = this.fb.group({});
    vendorLocale.forEach(localeItem => {
      const values = this.getLocaleValues(entityLocaleSetting.localizations);
      itemFormGroup.addControl(localeItem.i18n, this.fb.control(values && values[localeItem.i18n] ? values[localeItem.i18n].text : ''));
      if (maxLenght) {
        itemFormGroup.get(localeItem.i18n).addValidators([Validators.maxLength(maxLenght)])
      }
      itemFormGroup.addControl(`${localeItem.i18n}Id`, this.fb.control(values && values[localeItem.i18n] ? values[localeItem.i18n].id : ''));
    })

    if (entityLocaleSetting.leaderboardId) {
      itemFormGroup.addControl('id', this.fb.control(entityLocaleSetting.leaderboardId));
    }

    if (entityLocaleSetting.tierId) {
      itemFormGroup.addControl('id', this.fb.control(entityLocaleSetting.tierId));
    }

    if (entityLocaleSetting.roundDescriptionId) {
      itemFormGroup.addControl('id', this.fb.control(entityLocaleSetting.roundDescriptionId));
    }

    if(entityLocaleSetting.id) {
      itemFormGroup.addControl('id', this.fb.control(entityLocaleSetting.id));
    }

    if (entity !== LocalesEntityEnum.BRAND_URL && this.detectedLanguage && this.detectedLanguage.length) {
      let targetKey = 'id'
      if (entityLocaleSetting.leaderboardId) targetKey = 'leaderboardId';
      if (entityLocaleSetting.roundDescriptionId) targetKey =  'roundDescriptionId';
      const detectedLocale = this.detectedLanguage.find(lang => lang.questionId === entityLocaleSetting[targetKey])
      const detectedLanguage = languageCodesArray.find(localeItem => localeItem.localeCode === detectedLocale.lang)?.country;
      itemFormGroup.addControl('detected', this.fb.control(detectedLanguage));
    }

    itemFormGroup.addControl('text', this.fb.control(entityLocaleSetting.text || entityLocaleSetting.name));
    const entityValue = this.getEntityValue(entity);
    itemFormGroup.addControl('entityValue', this.fb.control(entityValue));
    itemFormGroup.addControl('entity', this.fb.control(entity));
    itemFormGroup.addControl('actions', this.fb.control(true));
    return itemFormGroup;
  }

  /**
   * Prepare list of locales object with flags of country
   * First convert ISO-639 code to  ISO 3166-1 code
   * Get flag by language name, if no flag present get locale by ISO name
   * If no flag found, get list of country where this langulage is using ang get this flag
   * For english select USA flag
   */
  createLocaleObjectData() {
    const allLaguage = langs.all();

    const fullLocaleData = languageCodesArray.map(({ localeCode, country, simplify }) => {
      let countryName = country;
      //If in country list use clarification in brackets, and this locale, marked as simplify
      //(there approved languages which can be simplify) use country name without clarification
      //to search flag

      if (simplify) {
        countryName = country.split(' ')[0];
      }

      const countryIso = allLaguage.find(c => c.name.toLowerCase() === countryName.toLowerCase());
      let flag: string;

      //Need hardcode only for english, becouse there we need USA flag
      if (localeCode === 'en') {
        flag = countries.find(countryItem => countryItem.iso3.toLowerCase() === 'usa')?.flag;
      } else {
        flag = countries.find(countryItem => countryItem.demonym.toLowerCase() === countryName.toLowerCase())?.flag;
      }

      if (!flag && countryIso) {
        flag = countries.find(countryItem => countryItem.iso3.toLowerCase() === countryIso['2B'].toLowerCase())?.flag;
      }

      if (!flag) {
        CountryLanguage.getLanguageCountries(localeCode, (err, languages) => {
          //Need only if language use in one country
          if (languages && languages.length && languages.length === 1) {
            const indic = languages[0]['code_3'];
            flag = countries.find(countryItem => countryItem.iso3.toLowerCase() === indic.toLowerCase())?.flag;
          }
        });
      }
      return new LocaleModel(localeCode, country, undefined, flag);
    });
    return fullLocaleData;
  }

  prepareFullLocaleData() {
    this.fullLocaleData$ = of(this.createLocaleObjectData());
  }

  prepareVendorLocales(vendorLocale) {
    return vendorLocale.map(localeData => {
      const countryName = languageCodesArray.find(country => country.localeCode === localeData.i18n).country;
      const { id, i18n } = localeData;
      return {
        locale: i18n,
        countryName,
        id
      }
    });
  }

  getLocalizationTableColumn(vendorLocales, additionColumns = []) {
    const locales = vendorLocales.map(localeItem => localeItem.locale);
    return ['entityValue', 'text', ...locales, ...additionColumns];
  }

  createLocaleRequestObject(itemFormGroup, vendorLocales) {
    const items = [];
    const editedItems = [];
    const { id } = itemFormGroup.value;

    vendorLocales.forEach(locale => {
      const currentFormControl = itemFormGroup.get(locale.locale)
      let text = itemFormGroup.value[locale.locale];
      const localeId = locale.id;
      if (!text && !currentFormControl.dirty) return;
      if (this.textService.hasSpacesAtStartOrEnd(text)) {
        text = text.trim();
        itemFormGroup.patchValue({
          [locale.locale]: text
        });
      }
      const requestBody: LocalesItemRequestModel = { text, localeId };
      if (itemFormGroup.value[`${locale.locale}Id`]) {
        requestBody.id = itemFormGroup.value[`${locale.locale}Id`];
        editedItems.push(requestBody);
      } else {
        switch (itemFormGroup.value.entity) {
          case LocalesEntityEnum.ANSWER:
            requestBody.answerId = id;
            break;
          case LocalesEntityEnum.QUESTION:
            requestBody.questionId = id;
            break;
          case LocalesEntityEnum.SPORT_EVENT:
            requestBody.sportEventId = id;
            break;
          case LocalesEntityEnum.TEAM:
            requestBody.teamId = id;
            break;
          case LocalesEntityEnum.BRAND_URL:
            requestBody.brandId = id;
            break;
          case LocalesEntityEnum.PRIZE:
            requestBody.prizeLabelId = id;
            break;
          case LocalesEntityEnum.TIER_NAME:
            requestBody.leaderboardTierId = id;
            break;
          case LocalesEntityEnum.LEADERBOARD:
          case LocalesEntityEnum.LEADERBOARD_NAME:
            requestBody.leaderboardId = id;
            break;
          case LocalesEntityEnum.DESCRIPTION:
            requestBody.roundDescriptionId = id
            break
        }
        items.push(requestBody);
      }
    })
    return { items, editedItems }
  }

  prepareLocalesList(vendorLocale) {
    return vendorLocale.map(localeData => {
      const country = languageCodesArray.find(country => country.localeCode === localeData.i18n).country;
      const { id, i18n } = localeData;
      return {
        i18n,
        country,
        id
      }
    });
  }


  mapTranslationsToCmsContentBody(eventName: string, dataArray: {[p: string]: string}[], translationsArray: GoogleTranslateModel[]) {
    dataArray.forEach(item => {
      translationsArray.forEach((translation: { locale: any; translations: { translatedText: any; }[]; }) => {
        item['Text value'] = eventName;

        const locale = translation.locale;
        const translatedText = translation.translations[0]?.translatedText || null;

        const labelKey = `Text value_${locale}`;

        if (translatedText !== null) {
          item[labelKey] = translatedText;
        }
      });

      Object.keys(item).forEach(key => {
        if (item[key] === null && key.includes('Text value')) {
          delete item[key];
        }
      });
    });
    return dataArray;
  }

  hasLocalizations(obj) {
    if (obj && typeof obj === 'object') {
      if ('localizations' in obj) {
        return true;
      }
      for (const key in obj) {
        if (this.hasLocalizations(obj[key])) {
          return true;
        }
      }
    }
    return false;
  }

  createLocaleSaveBody(items, entity, roundId) {
    const localeRequestBody: LocalesRequestModel = {
      items,
    };

    if (entity === LocalesTypeEnum.UPSELL) {
      localeRequestBody.fromUpsell = true;
    }

    if (this.isRoundType(entity)) {
      localeRequestBody.roundId = roundId;
    }

    return localeRequestBody;
  }

  isRoundType (entity) {
    return entity === LocalesTypeEnum.ROUND || entity === LocalesTypeEnum.GLOBAL_STREAK_ROUND;
  }
}
