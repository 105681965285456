"use strict";

function createUrl(svgUrl) {
  return "https://upload.wikimedia.org/wikipedia/".concat(svgUrl);
}
module.exports = {
  'ABW': createUrl('commons/f/f6/Flag_of_Aruba.svg'),
  'AFG': createUrl('commons/5/5c/Flag_of_the_Taliban.svg'),
  'AGO': createUrl('commons/9/9d/Flag_of_Angola.svg'),
  'AIA': createUrl('commons/b/b4/Flag_of_Anguilla.svg'),
  'ALA': createUrl('commons/5/52/Flag_of_%C3%85land.svg'),
  'ALB': createUrl('commons/3/36/Flag_of_Albania.svg'),
  'AND': createUrl('commons/1/19/Flag_of_Andorra.svg'),
  'ANT': createUrl('commons/e/eb/Flag_of_the_Netherlands_Antilles_(1959%E2%80%931986).svg'),
  'ARE': createUrl('commons/c/cb/Flag_of_the_United_Arab_Emirates.svg'),
  'ARG': createUrl('commons/1/1a/Flag_of_Argentina.svg'),
  'ARM': createUrl('commons/2/2f/Flag_of_Armenia.svg'),
  'ASM': createUrl('commons/8/87/Flag_of_American_Samoa.svg'),
  'ATG': createUrl('commons/8/89/Flag_of_Antigua_and_Barbuda.svg'),
  'AUS': createUrl('commons/8/88/Flag_of_Australia_(converted).svg'),
  'AUT': createUrl('commons/4/41/Flag_of_Austria.svg'),
  'AZE': createUrl('commons/d/dd/Flag_of_Azerbaijan.svg'),
  'BDI': createUrl('commons/5/50/Flag_of_Burundi.svg'),
  'BEL': createUrl('commons/6/65/Flag_of_Belgium.svg'),
  'BEN': createUrl('commons/0/0a/Flag_of_Benin.svg'),
  'BES': createUrl('commons/2/20/Flag_of_the_Netherlands.svg'),
  'BFA': createUrl('commons/3/31/Flag_of_Burkina_Faso.svg'),
  'BGD': createUrl('commons/f/f9/Flag_of_Bangladesh.svg'),
  'BGR': createUrl('commons/9/9a/Flag_of_Bulgaria.svg'),
  'BHR': createUrl('commons/2/2c/Flag_of_Bahrain.svg'),
  'BHS': createUrl('commons/9/93/Flag_of_the_Bahamas.svg'),
  'BIH': createUrl('commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg'),
  'BLM': createUrl('commons/0/03/Saint-Barthelémy_Icône.svg'),
  'BLR': createUrl('commons/8/85/Flag_of_Belarus.svg'),
  'BLZ': createUrl('commons/e/e7/Flag_of_Belize.svg'),
  'BMU': createUrl('commons/b/bf/Flag_of_Bermuda.svg'),
  'BOL': createUrl('commons/5/5b/Bolivia_Flag.svg'),
  'BRA': createUrl('commons/0/05/Flag_of_Brazil.svg'),
  'BRB': createUrl('commons/e/ef/Flag_of_Barbados.svg'),
  'BRN': createUrl('commons/9/9c/Flag_of_Brunei.svg'),
  'BTN': createUrl('commons/9/91/Flag_of_Bhutan.svg'),
  'BWA': createUrl('commons/f/fa/Flag_of_Botswana.svg'),
  'CAF': createUrl('commons/6/6f/Flag_of_the_Central_African_Republic.svg'),
  'CAN': createUrl('commons/d/d9/Flag_of_Canada_(Pantone).svg'),
  'CCK': createUrl('commons/7/74/Flag_of_the_Cocos_(Keeling)_Islands.svg'),
  'CHE': createUrl('commons/f/f3/Flag_of_Switzerland.svg'),
  'CHL': createUrl('commons/7/78/Flag_of_Chile.svg'),
  'CHN': createUrl('commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg'),
  'CIV': createUrl('commons/f/fe/Flag_of_Côte_d%27Ivoire.svg'),
  'CMR': createUrl('commons/4/4f/Flag_of_Cameroon.svg'),
  'COD': createUrl('commons/1/11/Flag_of_the_Democratic_Republic_of_the_Congo_(3-2).svg'),
  'COG': createUrl('commons/9/92/Flag_of_the_Republic_of_the_Congo.svg'),
  'COK': createUrl('commons/3/35/Flag_of_the_Cook_Islands.svg'),
  'COL': createUrl('commons/2/21/Flag_of_Colombia.svg'),
  'COM': createUrl('commons/d/df/Flag_of_the_Comoros_(3-2).svg'),
  'CPV': createUrl('commons/3/38/Flag_of_Cape_Verde.svg'),
  'CRI': createUrl('commons/b/bc/Flag_of_Costa_Rica_(state).svg'),
  'CUB': createUrl('commons/b/bd/Flag_of_Cuba.svg'),
  'CUW': createUrl('commons/b/b1/Flag_of_Curaçao.svg'),
  'CXR': createUrl('commons/6/67/Flag_of_Christmas_Island.svg'),
  'CYM': createUrl('commons/0/0f/Flag_of_the_Cayman_Islands.svg'),
  'CYP': createUrl('commons/d/d4/Flag_of_Cyprus.svg'),
  'CZE': createUrl('commons/c/cb/Flag_of_the_Czech_Republic.svg'),
  'DEU': createUrl('commons/b/ba/Flag_of_Germany.svg'),
  'DJI': createUrl('commons/3/34/Flag_of_Djibouti.svg'),
  'DMA': createUrl('commons/c/c4/Flag_of_Dominica.svg'),
  'DNK': createUrl('commons/9/9c/Flag_of_Denmark.svg'),
  'DOM': createUrl('commons/9/9f/Flag_of_the_Dominican_Republic.svg'),
  'DZA': createUrl('commons/7/77/Flag_of_Algeria.svg'),
  'ECU': createUrl('commons/e/e8/Flag_of_Ecuador.svg'),
  'EGY': createUrl('commons/f/fe/Flag_of_Egypt.svg'),
  'ERI': createUrl('commons/2/29/Flag_of_Eritrea.svg'),
  'ESH': createUrl('commons/2/26/Flag_of_the_Sahrawi_Arab_Democratic_Republic.svg'),
  'ESP': createUrl('commons/9/9a/Flag_of_Spain.svg'),
  'EST': createUrl('commons/8/8f/Flag_of_Estonia.svg'),
  'ETH': createUrl('commons/7/71/Flag_of_Ethiopia.svg'),
  'FIN': createUrl('commons/b/bc/Flag_of_Finland.svg'),
  'FJI': createUrl('commons/b/ba/Flag_of_Fiji.svg'),
  'FLK': createUrl('commons/8/83/Flag_of_the_Falkland_Islands.svg'),
  'FRA': createUrl('commons/c/c3/Flag_of_France.svg'),
  'FRO': createUrl('commons/3/3c/Flag_of_the_Faroe_Islands.svg'),
  'FSM': createUrl('commons/e/e4/Flag_of_the_Federated_States_of_Micronesia.svg'),
  'GAB': createUrl('commons/0/04/Flag_of_Gabon.svg'),
  'GBR': createUrl('commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg'),
  'GEO': createUrl('commons/0/0f/Flag_of_Georgia.svg'),
  'GGY': createUrl('commons/f/fa/Flag_of_Guernsey.svg'),
  'GHA': createUrl('commons/1/19/Flag_of_Ghana.svg'),
  'GIB': createUrl('commons/0/02/Flag_of_Gibraltar.svg'),
  'GIN': createUrl('commons/e/ed/Flag_of_Guinea.svg'),
  'GLP': createUrl('commons/9/9f/Flag_of_France_%287x10%29.svg'),
  'GMB': createUrl('commons/7/77/Flag_of_The_Gambia.svg'),
  'GNB': createUrl('commons/0/01/Flag_of_Guinea-Bissau.svg'),
  'GNQ': createUrl('commons/3/31/Flag_of_Equatorial_Guinea.svg'),
  'GRC': createUrl('commons/5/5c/Flag_of_Greece.svg'),
  'GRD': createUrl('commons/b/bc/Flag_of_Grenada.svg'),
  'GRL': createUrl('commons/0/09/Flag_of_Greenland.svg'),
  'GTM': createUrl('commons/e/ec/Flag_of_Guatemala.svg'),
  'GUF': createUrl('commons/e/ed/Flag_of_France_%28Pantone%29.svg'),
  'GUM': createUrl('commons/0/07/Flag_of_Guam.svg'),
  'GUY': createUrl('commons/9/99/Flag_of_Guyana.svg'),
  'HKG': createUrl('commons/5/5b/Flag_of_Hong_Kong.svg'),
  'HND': createUrl('commons/8/82/Flag_of_Honduras.svg'),
  'HRV': createUrl('commons/1/1b/Flag_of_Croatia.svg'),
  'HTI': createUrl('commons/5/56/Flag_of_Haiti.svg'),
  'HUN': createUrl('commons/c/c1/Flag_of_Hungary.svg'),
  'IDN': createUrl('commons/9/9f/Flag_of_Indonesia.svg'),
  'IMN': createUrl('commons/b/bc/Flag_of_the_Isle_of_Man.svg'),
  'IND': createUrl('commons/4/41/Flag_of_India.svg'),
  'IOT': createUrl('commons/6/65/Flag_of_the_Commissioner_of_the_British_Indian_Ocean_Territory.svg'),
  'IRL': createUrl('commons/c/c0/Republic_of_Ireland_Flag.svg'),
  'IRN': createUrl('commons/c/ca/Flag_of_Iran.svg'),
  'IRQ': createUrl('commons/f/f6/Flag_of_Iraq.svg'),
  'ISL': createUrl('commons/c/ce/Flag_of_Iceland.svg'),
  'ISR': createUrl('commons/d/d4/Flag_of_Israel.svg'),
  'ITA': createUrl('commons/0/03/Flag_of_Italy.svg'),
  'JAM': createUrl('commons/0/0a/Flag_of_Jamaica.svg'),
  'JEY': createUrl('commons/1/1c/Flag_of_Jersey.svg'),
  'JOR': createUrl('commons/c/c0/Flag_of_Jordan.svg'),
  'JPN': createUrl('commons/b/bc/Flag_of_Japan%28bordered%29.svg'),
  'KAZ': createUrl('commons/d/d3/Flag_of_Kazakhstan.svg'),
  'KEN': createUrl('commons/4/49/Flag_of_Kenya.svg'),
  'KGZ': createUrl('commons/c/c7/Flag_of_Kyrgyzstan.svg'),
  'KHM': createUrl('commons/8/83/Flag_of_Cambodia.svg'),
  'KIR': createUrl('commons/d/d3/Flag_of_Kiribati.svg'),
  'KNA': createUrl('commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg'),
  'KOR': createUrl('commons/0/09/Flag_of_South_Korea.svg'),
  'KWT': createUrl('commons/a/aa/Flag_of_Kuwait.svg'),
  'LAO': createUrl('commons/5/56/Flag_of_Laos.svg'),
  'LBN': createUrl('commons/5/59/Flag_of_Lebanon.svg'),
  'LBR': createUrl('commons/b/b8/Flag_of_Liberia.svg'),
  'LBY': createUrl('commons/0/05/Flag_of_Libya.svg'),
  'LCA': createUrl('commons/9/9f/Flag_of_Saint_Lucia.svg'),
  'LIE': createUrl('commons/4/47/Flag_of_Liechtenstein.svg'),
  'LKA': createUrl('commons/1/11/Flag_of_Sri_Lanka.svg'),
  'LSO': createUrl('commons/4/4a/Flag_of_Lesotho.svg'),
  'LTU': createUrl('commons/1/11/Flag_of_Lithuania.svg'),
  'LUX': createUrl('commons/d/da/Flag_of_Luxembourg.svg'),
  'LVA': createUrl('commons/8/84/Flag_of_Latvia.svg'),
  'MAC': createUrl('commons/6/63/Flag_of_Macau.svg'),
  'MAF': createUrl('commons/d/dd/Flag_of_Saint-Martin_%28fictional%29.svg'),
  'MAR': createUrl('commons/2/2c/Flag_of_Morocco.svg'),
  'MCO': createUrl('commons/e/ea/Flag_of_Monaco.svg'),
  'MDA': createUrl('commons/2/27/Flag_of_Moldova.svg'),
  'MDG': createUrl('commons/b/bc/Flag_of_Madagascar.svg'),
  'MDV': createUrl('commons/0/0f/Flag_of_Maldives.svg'),
  'MEX': createUrl('commons/f/fc/Flag_of_Mexico.svg'),
  'MHL': createUrl('commons/2/2e/Flag_of_the_Marshall_Islands.svg'),
  'MKD': createUrl('commons/7/79/Flag_of_North_Macedonia.svg'),
  'MLI': createUrl('commons/9/92/Flag_of_Mali.svg'),
  'MLT': createUrl('commons/7/73/Flag_of_Malta.svg'),
  'MMR': createUrl('commons/8/8c/Flag_of_Myanmar.svg'),
  'MNE': createUrl('commons/6/64/Flag_of_Montenegro.svg'),
  'MNG': createUrl('commons/4/4c/Flag_of_Mongolia.svg'),
  'MNP': createUrl('commons/e/e0/Flag_of_the_Northern_Mariana_Islands.svg'),
  'MOZ': createUrl('commons/d/d0/Flag_of_Mozambique.svg'),
  'MRT': createUrl('commons/4/43/Flag_of_Mauritania.svg'),
  'MSR': createUrl('commons/d/d0/Flag_of_Montserrat.svg'),
  'MTQ': createUrl('commons/2/21/Flag_of_the_Territorial_Collectivity_of_Martinique.svg'),
  'MUS': createUrl('commons/7/77/Flag_of_Mauritius.svg'),
  'MWI': createUrl('commons/d/d1/Flag_of_Malawi.svg'),
  'MYS': createUrl('commons/6/66/Flag_of_Malaysia.svg'),
  'MYT': createUrl('commons/c/c3/Flag_of_France.svg'),
  'NAM': createUrl('commons/0/00/Flag_of_Namibia.svg'),
  'NCL': createUrl('commons/6/66/Flag_of_FLNKS.svg'),
  'NER': createUrl('commons/f/f4/Flag_of_Niger.svg'),
  'NFK': createUrl('commons/4/48/Flag_of_Norfolk_Island.svg'),
  'NGA': createUrl('commons/7/79/Flag_of_Nigeria.svg'),
  'NIC': createUrl('commons/1/19/Flag_of_Nicaragua.svg'),
  'NIU': createUrl('commons/0/01/Flag_of_Niue.svg'),
  'NLD': createUrl('commons/2/20/Flag_of_the_Netherlands.svg'),
  'NOR': createUrl('commons/d/d9/Flag_of_Norway.svg'),
  'NPL': createUrl('commons/9/9b/Flag_of_Nepal.svg'),
  'NRU': createUrl('commons/3/30/Flag_of_Nauru.svg'),
  'NZL': createUrl('commons/3/3e/Flag_of_New_Zealand.svg'),
  'OMN': createUrl('commons/d/dd/Flag_of_Oman.svg'),
  'PAK': createUrl('commons/3/32/Flag_of_Pakistan.svg'),
  'PAN': createUrl('commons/a/ab/Flag_of_Panama.svg'),
  'PCN': createUrl('commons/8/88/Flag_of_the_Pitcairn_Islands.svg'),
  'PER': createUrl('commons/c/cf/Flag_of_Peru.svg'),
  'PHL': createUrl('commons/9/99/Flag_of_the_Philippines.svg'),
  'PLW': createUrl('commons/4/48/Flag_of_Palau.svg'),
  'PNG': createUrl('commons/e/e3/Flag_of_Papua_New_Guinea.svg'),
  'POL': createUrl('commons/1/12/Flag_of_Poland.svg'),
  'PRI': createUrl('commons/2/28/Flag_of_Puerto_Rico.svg'),
  'PRK': createUrl('commons/5/51/Flag_of_North_Korea.svg'),
  'PRT': createUrl('commons/5/5c/Flag_of_Portugal.svg'),
  'PRY': createUrl('commons/2/27/Flag_of_Paraguay.svg'),
  'PSE': createUrl('commons/f/f4/Palestine_Flag.svg'),
  'PYF': createUrl('commons/d/db/Flag_of_French_Polynesia.svg'),
  'QAT': createUrl('commons/6/65/Flag_of_Qatar.svg'),
  'REU': createUrl('commons/5/5a/Flag_of_Réunion.svg'),
  'ROU': createUrl('commons/7/73/Flag_of_Romania.svg'),
  'RUS': createUrl('commons/f/f3/Flag_of_Russia.svg'),
  'RWA': createUrl('commons/1/17/Flag_of_Rwanda.svg'),
  'SAU': createUrl('commons/0/0d/Flag_of_Saudi_Arabia.svg'),
  'SDN': createUrl('commons/0/01/Flag_of_Sudan.svg'),
  'SEN': createUrl('commons/f/fd/Flag_of_Senegal.svg'),
  'SGP': createUrl('commons/4/48/Flag_of_Singapore.svg'),
  'SGS': createUrl('commons/e/ed/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg'),
  'SHN': createUrl('commons/0/00/Flag_of_Saint_Helena.svg'),
  'SJM': createUrl('commons/d/d9/Flag_of_Norway.svg'),
  'SLB': createUrl('commons/7/74/Flag_of_the_Solomon_Islands.svg'),
  'SLE': createUrl('commons/1/17/Flag_of_Sierra_Leone.svg'),
  'SLV': createUrl('commons/3/34/Flag_of_El_Salvador.svg'),
  'SMR': createUrl('commons/b/b1/Flag_of_San_Marino.svg'),
  'SOM': createUrl('commons/a/a0/Flag_of_Somalia.svg'),
  'SPM': createUrl('commons/7/74/Flag_of_Saint-Pierre_and_Miquelon.svg'),
  'SRB': createUrl('commons/f/ff/Flag_of_Serbia.svg'),
  'SSD': createUrl('commons/7/7a/Flag_of_South_Sudan.svg'),
  'STP': createUrl('commons/4/4f/Flag_of_Sao_Tome_and_Principe.svg'),
  'SUR': createUrl('commons/6/60/Flag_of_Suriname.svg'),
  'SVK': createUrl('commons/e/e6/Flag_of_Slovakia.svg'),
  'SVN': createUrl('commons/f/f0/Flag_of_Slovenia.svg'),
  'SWE': createUrl('commons/4/4c/Flag_of_Sweden.svg'),
  'SWZ': createUrl('commons/f/fb/Flag_of_Eswatini.svg'),
  'SXM': createUrl('commons/d/d3/Flag_of_Sint_Maarten.svg'),
  'SYC': createUrl('commons/f/fc/Flag_of_Seychelles.svg'),
  'SYR': createUrl('commons/5/53/Flag_of_Syria.svg'),
  'TCA': createUrl('commons/a/a0/Flag_of_the_Turks_and_Caicos_Islands.svg'),
  'TCD': createUrl('commons/4/4b/Flag_of_Chad.svg'),
  'TGO': createUrl('commons/6/68/Flag_of_Togo.svg'),
  'THA': createUrl('commons/a/a9/Flag_of_Thailand.svg'),
  'TJK': createUrl('commons/d/d0/Flag_of_Tajikistan.svg'),
  'TKL': createUrl('commons/8/8e/Flag_of_Tokelau.svg'),
  'TKM': createUrl('commons/1/1b/Flag_of_Turkmenistan.svg'),
  'TLS': createUrl('commons/2/26/Flag_of_East_Timor.svg'),
  'TON': createUrl('commons/9/9a/Flag_of_Tonga.svg'),
  'TTO': createUrl('commons/6/64/Flag_of_Trinidad_and_Tobago.svg'),
  'TUN': createUrl('commons/c/ce/Flag_of_Tunisia.svg'),
  'TUR': createUrl('commons/b/b4/Flag_of_Turkey.svg'),
  'TUV': createUrl('commons/3/38/Flag_of_Tuvalu.svg'),
  'TWN': createUrl('commons/7/72/Flag_of_the_Republic_of_China.svg'),
  'TZA': createUrl('commons/3/38/Flag_of_Tanzania.svg'),
  'UGA': createUrl('commons/4/4e/Flag_of_Uganda.svg'),
  'UKR': createUrl('commons/4/49/Flag_of_Ukraine.svg'),
  'UMI': createUrl('commons/0/05/Flag_of_the_U.S..svg'),
  'URY': createUrl('commons/f/fe/Flag_of_Uruguay.svg'),
  'USA': createUrl('commons/a/a4/Flag_of_the_United_States.svg'),
  'UZB': createUrl('commons/8/84/Flag_of_Uzbekistan.svg'),
  'VAT': createUrl('commons/0/00/Flag_of_the_Vatican_City.svg'),
  'VCT': createUrl('commons/6/6d/Flag_of_Saint_Vincent_and_the_Grenadines.svg'),
  'VEN': createUrl('commons/7/7b/Flag_of_Venezuela_(state).svg'),
  'VGB': createUrl('commons/4/42/Flag_of_the_British_Virgin_Islands.svg'),
  'VIR': createUrl('commons/f/f8/Flag_of_the_United_States_Virgin_Islands.svg'),
  'VNM': createUrl('commons/2/21/Flag_of_Vietnam.svg'),
  'VUT': createUrl('commons/6/6e/Flag_of_Vanuatu_(official).svg'),
  'WLF': createUrl('commons/d/d2/Flag_of_Wallis_and_Futuna.svg'),
  'WSM': createUrl('commons/3/31/Flag_of_Samoa.svg'),
  'XXK': createUrl('commons/1/1f/Flag_of_Kosovo.svg'),
  'YEM': createUrl('commons/8/89/Flag_of_Yemen.svg'),
  'ZAF': createUrl('commons/a/af/Flag_of_South_Africa.svg'),
  'ZMB': createUrl('commons/0/06/Flag_of_Zambia.svg'),
  'ZWE': createUrl('commons/6/6a/Flag_of_Zimbabwe.svg')
};