export const languageCodesArray = [
  { localeCode: 'af', country: 'Afrikaans' },
  { localeCode: 'sq', country: 'Albanian' },
  { localeCode: 'am', country: 'Amharic' },
  { localeCode: 'ar', country: 'Arabic' },
  { localeCode: 'hy', country: 'Armenian' },
  { localeCode: 'as', country: 'Assamese' },
  { localeCode: 'ay', country: 'Aymara' },
  { localeCode: 'az', country: 'Azerbaijani' },
  { localeCode: 'bm', country: 'Bambara' },
  { localeCode: 'eu', country: 'Basque' },
  { localeCode: 'be', country: 'Belarusian' },
  { localeCode: 'bn', country: 'Bengali' },
  { localeCode: 'bho', country: 'Bhojpuri' },
  { localeCode: 'bs', country: 'Bosnian' },
  { localeCode: 'bg', country: 'Bulgarian' },
  { localeCode: 'ca', country: 'Catalan' },
  { localeCode: 'ceb', country: 'Cebuano' },
  { localeCode: 'zh-CN', country: 'Chinese (Simplified)', simplify: true },
  { localeCode: 'zh-TW', country: 'Chinese (Traditional)', simplify: true },
  { localeCode: 'co', country: 'Corsican' },
  { localeCode: 'hr', country: 'Croatian' },
  { localeCode: 'cs', country: 'Czech' },
  { localeCode: 'da', country: 'Danish' },
  { localeCode: 'dv', country: 'Dhivehi' },
  { localeCode: 'doi', country: 'Dogri' },
  { localeCode: 'nl', country: 'Dutch' },
  { localeCode: 'en', country: 'English' },
  { localeCode: 'eo', country: 'Esperanto' },
  { localeCode: 'et', country: 'Estonian' },
  { localeCode: 'ee', country: 'Ewe' },
  { localeCode: 'fil', country: 'Filipino (Tagalog)', simplify: true },
  { localeCode: 'fi', country: 'Finnish' },
  { localeCode: 'fr', country: 'French' },
  { localeCode: 'fy', country: 'Frisian' },
  { localeCode: 'gl', country: 'Galician' },
  { localeCode: 'ka', country: 'Georgian' },
  { localeCode: 'de', country: 'German' },
  { localeCode: 'el', country: 'Greek' },
  { localeCode: 'gn', country: 'Guarani' },
  { localeCode: 'gu', country: 'Gujarati' },
  { localeCode: 'ht', country: 'Haitian Creole' },
  { localeCode: 'ha', country: 'Hausa' },
  { localeCode: 'haw', country: 'Hawaiian' },
  { localeCode: 'he', country: 'Hebrew' },
  { localeCode: 'hi', country: 'Hindi' },
  { localeCode: 'hmn', country: 'Hmong' },
  { localeCode: 'hu', country: 'Hungarian' },
  { localeCode: 'is', country: 'Icelandic' },
  { localeCode: 'ig', country: 'Igbo' },
  { localeCode: 'ilo', country: 'Ilocano' },
  { localeCode: 'id', country: 'Indonesian' },
  { localeCode: 'ga', country: 'Irish' },
  { localeCode: 'it', country: 'Italian' },
  { localeCode: 'ja', country: 'Japanese' },
  { localeCode: 'jv', country: 'Javanese' },
  { localeCode: 'kn', country: 'Kannada' },
  { localeCode: 'kk', country: 'Kazakh' },
  { localeCode: 'km', country: 'Khmer' },
  { localeCode: 'rw', country: 'Kinyarwanda' },
  { localeCode: 'gom', country: 'Konkani' },
  { localeCode: 'ko', country: 'Korean' },
  { localeCode: 'kri', country: 'Krio' },
  { localeCode: 'ku', country: 'Kurdish' },
  { localeCode: 'ckb', country: 'Kurdish (Sorani)' },
  { localeCode: 'ky', country: 'Kyrgyz' },
  { localeCode: 'lo', country: 'Lao' },
  { localeCode: 'la', country: 'Latin' },
  { localeCode: 'lv', country: 'Latvian' },
  { localeCode: 'ln', country: 'Lingala' },
  { localeCode: 'lt', country: 'Lithuanian' },
  { localeCode: 'lg', country: 'Luganda' },
  { localeCode: 'lb', country: 'Luxembourgish' },
  { localeCode: 'mk', country: 'Macedonian' },
  { localeCode: 'mai', country: 'Maithili' },
  { localeCode: 'mg', country: 'Malagasy' },
  { localeCode: 'ms', country: 'Malay' },
  { localeCode: 'ml', country: 'Malayalam' },
  { localeCode: 'mt', country: 'Maltese' },
  { localeCode: 'mi', country: 'Maori' },
  { localeCode: 'mr', country: 'Marathi' },
  { localeCode: 'mni-Mtei', country: 'Meiteilon (Manipuri)', simplify: true },
  { localeCode: 'lus', country: 'Mizo' },
  { localeCode: 'mn', country: 'Mongolian' },
  { localeCode: 'my', country: 'Myanmar (Burmese)', simplify: true },
  { localeCode: 'ne', country: 'Nepali' },
  { localeCode: 'no', country: 'Norwegian' },
  { localeCode: 'ny', country: 'Nyanja (Chichewa)', simplify: true },
  { localeCode: 'or', country: 'Odia (Oriya)', simplify: true },
  { localeCode: 'om', country: 'Oromo' },
  { localeCode: 'ps', country: 'Pashto' },
  { localeCode: 'fa', country: 'Persian' },
  { localeCode: 'pl', country: 'Polish' },
  { localeCode: 'pt', country: 'Portuguese (Portugal, Brazil)', simplify: true },
  { localeCode: 'pa', country: 'Punjabi' },
  { localeCode: 'qu', country: 'Quechua' },
  { localeCode: 'ro', country: 'Romanian' },
  { localeCode: 'ru', country: 'Russian' },
  { localeCode: 'sm', country: 'Samoan' },
  { localeCode: 'sa', country: 'Sanskrit' },
  { localeCode: 'gd', country: 'Scots Gaelic' },
  { localeCode: 'nso', country: 'Sepedi' },
  { localeCode: 'sr', country: 'Serbian' },
  { localeCode: 'st', country: 'Sesotho' },
  { localeCode: 'sn', country: 'Shona' },
  { localeCode: 'sd', country: 'Sindhi' },
  { localeCode: 'si', country: 'Sinhala (Sinhalese)', simplify: true },
  { localeCode: 'sk', country: 'Slovak' },
  { localeCode: 'sl', country: 'Slovenian' },
  { localeCode: 'so', country: 'Somali' },
  { localeCode: 'es', country: 'Spanish' },
  { localeCode: 'su', country: 'Sundanese' },
  { localeCode: 'sw', country: 'Swahili' },
  { localeCode: 'sv', country: 'Swedish' },
  { localeCode: 'tl', country: 'Tagalog (Filipino)', simplify: true },
  { localeCode: 'tg', country: 'Tajik' },
  { localeCode: 'ta', country: 'Tamil' },
  { localeCode: 'tt', country: 'Tatar' },
  { localeCode: 'te', country: 'Telugu' },
  { localeCode: 'th', country: 'Thai' },
  { localeCode: 'ti', country: 'Tigrinya' },
  { localeCode: 'ts', country: 'Tsonga' },
  { localeCode: 'tr', country: 'Turkish' },
  { localeCode: 'tk', country: 'Turkmen' },
  { localeCode: 'ak', country: 'Twi (Akan)', simplify: true },
  { localeCode: 'uk', country: 'Ukrainian' },
  { localeCode: 'ur', country: 'Urdu' },
  { localeCode: 'ug', country: 'Uyghur' },
  { localeCode: 'uz', country: 'Uzbek' },
  { localeCode: 'vi', country: 'Vietnamese' },
  { localeCode: 'cy', country: 'Welsh' },
  { localeCode: 'xh', country: 'Xhosa' },
  { localeCode: 'yi', country: 'Yiddish' },
  { localeCode: 'yo', country: 'Yoruba' },
  { localeCode: 'zu', country: 'Zulu' }
];
