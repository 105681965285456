"use strict";

var countries = require("../data/countries");
function isEqual(str1, str2) {
  return str1.toUpperCase() === str2.toUpperCase();
}
function findFlagUrlByPredicate(countries, predicate) {
  var country = countries.find(predicate);
  return country ? country.flag : "";
}
module.exports = {
  findFlagUrlByCountryName: function findFlagUrlByCountryName(countryName) {
    return findFlagUrlByPredicate(countries, function (_ref) {
      var name = _ref.name,
        altSpellings = _ref.altSpellings;
      return isEqual(name, countryName) || altSpellings.some(function (altSpelling) {
        return isEqual(altSpelling, countryName);
      });
    });
  },
  findFlagUrlByNationality: function findFlagUrlByNationality(nationality) {
    return findFlagUrlByPredicate(countries, function (_ref2) {
      var demonym = _ref2.demonym;
      return isEqual(demonym, nationality);
    });
  },
  findFlagUrlByIso2Code: function findFlagUrlByIso2Code(iso2Code) {
    return findFlagUrlByPredicate(countries, function (_ref3) {
      var iso2 = _ref3.iso2;
      return isEqual(iso2, iso2Code);
    });
  },
  findFlagUrlByIso3Code: function findFlagUrlByIso3Code(iso3Code) {
    return findFlagUrlByPredicate(countries, function (_ref4) {
      var iso3 = _ref4.iso3;
      return isEqual(iso3, iso3Code);
    });
  }
};