import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(
    private snackBar: MatSnackBar
  ) { }
  showSnackBar(message: string, isError = false, action = 'ok', duration = 5000): void {
    const classes = ['snackbar'];
    if (isError) {
      classes.push('error-massage');
    }
    this.snackBar.open(message, action, {
      duration,
      panelClass: classes
    });
  }
}
