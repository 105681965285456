import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LocalStorageService } from "@services/local-storage.service";

@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService
  ) {
  }
  /**
   * Listen for the error api response and add header with token.
   * Is this is refresh request dont add anything
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.endsWith("refresh")) {
      if (request.headers.get('Authorization') !== null) {
        return next.handle(request);
      } else {
        const token = this.getCurrentUser();
        const refreshToken = this.localStorageService.getRefreshToken();
        let authReq = token
          ? request.clone({
            setHeaders: {
              Authorization: 'Bearer ' + token
            },
          })
          : request;
        if (request.url.endsWith('logout') && refreshToken) {
          authReq = authReq.clone({
            setHeaders: {
              'refresh-token': refreshToken
            },
          })
        }

        return next.handle(authReq);
      }
    } else {
      return next.handle(request)
    }
  }


  private getCurrentUser() {
    return this.localStorageService.getToken()
  }
}
