'use strict';

var $ = require('../internals/export');
var globalThis = require('../internals/global-this');
var clearImmediate = require('../internals/task').clear;

// `clearImmediate` method
// http://w3c.github.io/setImmediate/#si-clearImmediate
$({
  global: true,
  bind: true,
  enumerable: true,
  forced: globalThis.clearImmediate !== clearImmediate
}, {
  clearImmediate: clearImmediate
});