{
  "languageFamilies": [
    "Northwest Caucasian",
    "Afro-Asiatic",
    "Indo-European",
    "Niger–Congo",
    "Northeast Caucasian",
    "Aymaran",
    "Turkic",
    "Language isolate",
    "Creole",
    "Sino-Tibetan",
    "Austronesian",
    "Algonquian",
    "Constructed",
    "Uralic",
    "South Caucasian",
    "Tupian",
    "Eskimo–Aleut",
    "Japonic",
    "Dravidian",
    "Nilo-Saharan",
    "Austroasiatic",
    "Koreanic",
    "Tai–Kadai",
    "Mongolic",
    "Dené–Yeniseian",
    "Niger-Congo",
    "Quechuan"
  ],
  "languages": [
    {
      "iso639_1": "ab",
      "iso639_2": "abk",
      "iso639_2en": "abk",
      "iso639_3": "abk",
      "name": [
        "Abkhaz"
      ],
      "nativeName": [
        "аҧсуа бызшәа",
        "аҧсшәа"
      ],
      "direction": "LTR",
      "family": "Northwest Caucasian"
    },
    {
      "iso639_1": "aa",
      "iso639_2": "aar",
      "iso639_2en": "aar",
      "iso639_3": "aar",
      "name": [
        "Afar"
      ],
      "nativeName": [
        "Afaraf"
      ],
      "direction": "LTR",
      "family": "Afro-Asiatic",
      "countries": [
        "DJI"
      ]
    },
    {
      "iso639_1": "af",
      "iso639_2": "afr",
      "iso639_2en": "afr",
      "iso639_3": "afr",
      "name": [
        "Afrikaans"
      ],
      "nativeName": [
        "Afrikaans"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "ZAF"
      ],
      "langCultureMs": [
        {
          "langCultureName": "af-ZA",
          "displayName": "Afrikaans - South Africa",
          "cultureCode": "0x0436"
        }
      ]
    },
    {
      "iso639_1": "ak",
      "iso639_2": "aka",
      "iso639_2en": "aka",
      "iso639_3": "aka",
      "name": [
        "Akan"
      ],
      "nativeName": [
        "Akan"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "GHA"
      ]
    },
    {
      "iso639_1": "sq",
      "iso639_2": "sqi",
      "iso639_2en": "alb",
      "iso639_3": "sqi",
      "name": [
        "Albanian"
      ],
      "nativeName": [
        "gjuha shqipe"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "ALB"
      ],
      "langCultureMs": [
        {
          "langCultureName": "sq-AL",
          "displayName": "Albanian - Albania",
          "cultureCode": "0x041C"
        }
      ]
    },
    {
      "iso639_1": "am",
      "iso639_2": "amh",
      "iso639_2en": "amh",
      "iso639_3": "amh",
      "name": [
        "Amharic"
      ],
      "nativeName": [
        "አማርኛ"
      ],
      "direction": "LTR",
      "family": "Afro-Asiatic",
      "countries": [
        "ETH"
      ]
    },
    {
      "iso639_1": "ar",
      "iso639_2": "ara",
      "iso639_2en": "ara",
      "iso639_3": "ara",
      "name": [
        "Arabic"
      ],
      "nativeName": [
        "العربية"
      ],
      "direction": "RTL",
      "family": "Afro-Asiatic",
      "countries": [
        "DZA",
        "BHR",
        "TCD",
        "COM",
        "DJI",
        "EGY",
        "ERI",
        "IRQ",
        "ISR",
        "JOR",
        "KWT",
        "LBN",
        "LBY",
        "MRT",
        "MAR",
        "NER",
        "OMN",
        "QAT",
        "SAU",
        "SOM",
        "SDN",
        "SYR",
        "TUN",
        "ARE",
        "YEM"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-DZ",
          "displayName": "Arabic - Algeria",
          "cultureCode": "0x1401"
        },
        {
          "langCultureName": "ar-BH",
          "displayName": "Arabic - Bahrain",
          "cultureCode": "0x3C01"
        },
        {
          "langCultureName": "ar-EG",
          "displayName": "Arabic - Egypt",
          "cultureCode": "0x0C01"
        },
        {
          "langCultureName": "ar-IQ",
          "displayName": "Arabic - Iraq",
          "cultureCode": "0x0801"
        },
        {
          "langCultureName": "ar-JO",
          "displayName": "Arabic - Jordan",
          "cultureCode": "0x2C01"
        },
        {
          "langCultureName": "ar-KW",
          "displayName": "Arabic - Kuwait",
          "cultureCode": "0x3401"
        },
        {
          "langCultureName": "ar-LB",
          "displayName": "Arabic - Lebanon",
          "cultureCode": "0x3001"
        },
        {
          "langCultureName": "ar-LY",
          "displayName": "Arabic - Libya",
          "cultureCode": "0x1001"
        },
        {
          "langCultureName": "ar-MA",
          "displayName": "Arabic - Morocco",
          "cultureCode": "0x1801"
        },
        {
          "langCultureName": "ar-OM",
          "displayName": "Arabic - Oman",
          "cultureCode": "0x2001"
        },
        {
          "langCultureName": "ar-QA",
          "displayName": "Arabic - Qatar",
          "cultureCode": "0x4001"
        },
        {
          "langCultureName": "ar-SA",
          "displayName": "Arabic - Saudi Arabia",
          "cultureCode": "0x0401"
        },
        {
          "langCultureName": "ar-SY",
          "displayName": "Arabic - Syria",
          "cultureCode": "0x2801"
        },
        {
          "langCultureName": "ar-TN",
          "displayName": "Arabic - Tunisia",
          "cultureCode": "0x1C01"
        },
        {
          "langCultureName": "ar-AE",
          "displayName": "Arabic - United Arab Emirates",
          "cultureCode": "0x3801"
        },
        {
          "langCultureName": "ar-YE",
          "displayName": "Arabic - Yemen",
          "cultureCode": "0x2401"
        }
      ]
    },
    {
      "iso639_1": "an",
      "iso639_2": "arg",
      "iso639_2en": "arg",
      "iso639_3": "arg",
      "name": [
        "Aragonese"
      ],
      "nativeName": [
        "aragonés"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "hy",
      "iso639_2": "hye",
      "iso639_2en": "arm",
      "iso639_3": "hye",
      "name": [
        "Armenian"
      ],
      "nativeName": [
        "Հայերեն"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "ARM"
      ],
      "langCultureMs": [
        {
          "langCultureName": "hy-AM",
          "displayName": "Armenian - Armenia",
          "cultureCode": "0x042B"
        }
      ]
    },
    {
      "iso639_1": "as",
      "iso639_2": "asm",
      "iso639_2en": "asm",
      "iso639_3": "asm",
      "name": [
        "Assamese"
      ],
      "nativeName": [
        "অসমীয়া"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "av",
      "iso639_2": "ava",
      "iso639_2en": "ava",
      "iso639_3": "ava",
      "name": [
        "Avaric"
      ],
      "nativeName": [
        "авар мацӀ",
        "магӀарул мацӀ"
      ],
      "direction": "LTR",
      "family": "Northeast Caucasian"
    },
    {
      "iso639_1": "ae",
      "iso639_2": "ave",
      "iso639_2en": "ave",
      "iso639_3": "ave",
      "name": [
        "Avestan"
      ],
      "nativeName": [
        "avesta"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "ay",
      "iso639_2": "aym",
      "iso639_2en": "aym",
      "iso639_3": "aym",
      "name": [
        "Aymara"
      ],
      "nativeName": [
        "aymar aru"
      ],
      "direction": "LTR",
      "family": "Aymaran",
      "countries": [
        "BOL",
        "PER"
      ]
    },
    {
      "iso639_1": "az",
      "iso639_2": "aze",
      "iso639_2en": "aze",
      "iso639_3": "aze",
      "name": [
        "Azerbaijani"
      ],
      "nativeName": [
        "azərbaycan dili"
      ],
      "direction": "LTR",
      "family": "Turkic",
      "countries": [
        "AZE"
      ],
      "langCultureMs": [
        {
          "langCultureName": "Cy-az-AZ",
          "displayName": "Azeri (Cyrillic) - Azerbaijan",
          "cultureCode": "0x082C"
        },
        {
          "langCultureName": "Lt-az-AZ",
          "displayName": "Azeri (Latin) - Azerbaijan",
          "cultureCode": "0x042C"
        }
      ]
    },
    {
      "iso639_1": "bm",
      "iso639_2": "bam",
      "iso639_2en": "bam",
      "iso639_3": "bam",
      "name": [
        "Bambara"
      ],
      "nativeName": [
        "bamanankan"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "MLI"
      ]
    },
    {
      "iso639_1": "ba",
      "iso639_2": "bak",
      "iso639_2en": "bak",
      "iso639_3": "bak",
      "name": [
        "Bashkir"
      ],
      "nativeName": [
        "башҡорт теле"
      ],
      "direction": "LTR",
      "family": "Turkic"
    },
    {
      "iso639_1": "eu",
      "iso639_2": "eus",
      "iso639_2en": "baq",
      "iso639_3": "eus",
      "name": [
        "Basque"
      ],
      "nativeName": [
        "euskara",
        "euskera"
      ],
      "direction": "LTR",
      "family": "Language isolate",
      "langCultureMs": [
        {
          "langCultureName": "eu-ES",
          "displayName": "Basque - Basque",
          "cultureCode": "0x042D"
        }
      ]
    },
    {
      "iso639_1": "be",
      "iso639_2": "bel",
      "iso639_2en": "bel",
      "iso639_3": "bel",
      "name": [
        "Belarusian"
      ],
      "nativeName": [
        "беларуская мова"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "BLR"
      ],
      "langCultureMs": [
        {
          "langCultureName": "be-BY",
          "displayName": "Belarusian - Belarus",
          "cultureCode": "0x0423"
        }
      ]
    },
    {
      "iso639_1": "bn",
      "iso639_2": "ben",
      "iso639_2en": "ben",
      "iso639_3": "ben",
      "name": [
        "Bengali",
        "Bangla"
      ],
      "nativeName": [
        "বাংলা"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "BGD",
        "IND"
      ]
    },
    {
      "iso639_1": "",
      "iso639_2": "ber",
      "iso639_2en": "ber",
      "iso639_3": "ber",
      "name": [
        "Berber"
      ],
      "nativeName": [
        "Tamaziɣt",
        "Tamazight",
        "ⵜⴰⵎⴰⵣⵉⵖⵜ"
      ],
      "direction": "LTR",
      "family": "Afro-Asiatic",
      "countries": [
        "DZA",
        "MAR"
      ]
    },
    {
      "iso639_1": "bh",
      "iso639_2": "bih",
      "iso639_2en": "bih",
      "iso639_3": "",
      "name": [
        "Bihari"
      ],
      "nativeName": [
        "भोजपुरी"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "bi",
      "iso639_2": "bis",
      "iso639_2en": "bis",
      "iso639_3": "bis",
      "name": [
        "Bislama"
      ],
      "nativeName": [
        "Bislama"
      ],
      "direction": "LTR",
      "family": "Creole",
      "countries": [
        "VUT"
      ]
    },
    {
      "iso639_1": "bs",
      "iso639_2": "bos",
      "iso639_2en": "bos",
      "iso639_3": "bos",
      "name": [
        "Bosnian"
      ],
      "nativeName": [
        "bosanski jezik"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "BIH"
      ]
    },
    {
      "iso639_1": "br",
      "iso639_2": "bre",
      "iso639_2en": "bre",
      "iso639_3": "bre",
      "name": [
        "Breton"
      ],
      "nativeName": [
        "brezhoneg"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "bg",
      "iso639_2": "bul",
      "iso639_2en": "bul",
      "iso639_3": "bul",
      "name": [
        "Bulgarian"
      ],
      "nativeName": [
        "български език"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "BGR"
      ],
      "langCultureMs": [
        {
          "langCultureName": "bg-BG",
          "displayName": "Bulgarian - Bulgaria",
          "cultureCode": "0x0402"
        }
      ]
    },
    {
      "iso639_1": "my",
      "iso639_2": "mya",
      "iso639_2en": "bur",
      "iso639_3": "mya",
      "name": [
        "Burmese"
      ],
      "nativeName": [
        "ဗမာစာ"
      ],
      "direction": "LTR",
      "family": "Sino-Tibetan",
      "countries": [
        "MMR"
      ]
    },
    {
      "iso639_1": "ca",
      "iso639_2": "cat",
      "iso639_2en": "cat",
      "iso639_3": "cat",
      "name": [
        "Catalan",
        "Valencian"
      ],
      "nativeName": [
        "català",
        "valencià"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "AND"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ca-ES",
          "displayName": "Catalan - Catalan",
          "cultureCode": "0x0403"
        }
      ]
    },
    {
      "iso639_1": "ch",
      "iso639_2": "cha",
      "iso639_2en": "cha",
      "iso639_3": "cha",
      "name": [
        "Chamorro"
      ],
      "nativeName": [
        "Chamoru"
      ],
      "direction": "LTR",
      "family": "Austronesian"
    },
    {
      "iso639_1": "ce",
      "iso639_2": "che",
      "iso639_2en": "che",
      "iso639_3": "che",
      "name": [
        "Chechen"
      ],
      "nativeName": [
        "нохчийн мотт"
      ],
      "direction": "LTR",
      "family": "Northeast Caucasian"
    },
    {
      "iso639_1": "ny",
      "iso639_2": "nya",
      "iso639_2en": "nya",
      "iso639_3": "nya",
      "name": [
        "Chichewa",
        "Chewa",
        "Nyanja"
      ],
      "nativeName": [
        "chiCheŵa",
        "chinyanja"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "MWI"
      ]
    },
    {
      "iso639_1": "zh",
      "iso639_2": "zho",
      "iso639_2en": "chi",
      "iso639_3": "zho",
      "name": [
        "Chinese"
      ],
      "nativeName": [
        "中文 (Zhōngwén)",
        "汉语",
        "漢語"
      ],
      "direction": "LTR",
      "family": "Sino-Tibetan",
      "countries": [
        "HKG",
        "MAC",
        "CHN",
        "TWN",
        "SGP"
      ],
      "langCultureMs": [
        {
          "langCultureName": "zh-CN",
          "displayName": "Chinese - China",
          "cultureCode": "0x0804"
        },
        {
          "langCultureName": "zh-HK",
          "displayName": "Chinese - Hong Kong SAR",
          "cultureCode": "0x0C04"
        },
        {
          "langCultureName": "zh-MO",
          "displayName": "Chinese - Macau SAR",
          "cultureCode": "0x1404"
        },
        {
          "langCultureName": "zh-TW",
          "displayName": "Chinese - Taiwan",
          "cultureCode": "0x0404"
        },
        {
          "langCultureName": "zh-CHS",
          "displayName": "Chinese (Simplified)",
          "cultureCode": "0x0004"
        },
        {
          "langCultureName": "zh-CHT",
          "displayName": "Chinese (Traditional)",
          "cultureCode": "0x7C04"
        }
      ]
    },
    {
      "iso639_1": "cv",
      "iso639_2": "chv",
      "iso639_2en": "chv",
      "iso639_3": "chv",
      "name": [
        "Chuvash"
      ],
      "nativeName": [
        "чӑваш чӗлхи"
      ],
      "direction": "LTR",
      "family": "Turkic"
    },
    {
      "iso639_1": "kw",
      "iso639_2": "cor",
      "iso639_2en": "cor",
      "iso639_3": "cor",
      "name": [
        "Cornish"
      ],
      "nativeName": [
        "Kernewek"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "co",
      "iso639_2": "cos",
      "iso639_2en": "cos",
      "iso639_3": "cos",
      "name": [
        "Corsican"
      ],
      "nativeName": [
        "corsu",
        "lingua corsa"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "cr",
      "iso639_2": "cre",
      "iso639_2en": "cre",
      "iso639_3": "cre",
      "name": [
        "Cree"
      ],
      "nativeName": [
        "ᓀᐦᐃᔭᐍᐏᐣ"
      ],
      "direction": "LTR",
      "family": "Algonquian"
    },
    {
      "iso639_1": "hr",
      "iso639_2": "hrv",
      "iso639_2en": "hrv",
      "iso639_3": "hrv",
      "name": [
        "Croatian"
      ],
      "nativeName": [
        "hrvatski jezik"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "HRV",
        "BIH"
      ],
      "langCultureMs": [
        {
          "langCultureName": "hr-HR",
          "displayName": "Croatian - Croatia",
          "cultureCode": "0x041A"
        }
      ]
    },
    {
      "iso639_1": "cs",
      "iso639_2": "ces",
      "iso639_2en": "cze",
      "iso639_3": "ces",
      "name": [
        "Czech"
      ],
      "nativeName": [
        "čeština",
        "český jazyk"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "CZE",
        "SVK"
      ],
      "langCultureMs": [
        {
          "langCultureName": "cs-CZ",
          "displayName": "Czech - Czech Republic",
          "cultureCode": "0x0405"
        }
      ]
    },
    {
      "iso639_1": "da",
      "iso639_2": "dan",
      "iso639_2en": "dan",
      "iso639_3": "dan",
      "name": [
        "Danish"
      ],
      "nativeName": [
        "dansk"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "DNK",
        "FRO"
      ],
      "langCultureMs": [
        {
          "langCultureName": "da-DK",
          "displayName": "Danish - Denmark",
          "cultureCode": "0x0406"
        }
      ]
    },
    {
      "iso639_1": "",
      "iso639_2": "",
      "iso639_2en": "",
      "iso639_3": "prs",
      "name": [
        "Dari"
      ],
      "nativeName": [
        "فارسی دری"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "AFG"
      ]
    },
    {
      "iso639_1": "dv",
      "iso639_2": "div",
      "iso639_2en": "div",
      "iso639_3": "div",
      "name": [
        "Divehi",
        "Dhivehi",
        "Maldivian"
      ],
      "nativeName": [
        "ދިވެހި"
      ],
      "direction": "RTL",
      "family": "Indo-European",
      "countries": [
        "MDV"
      ],
      "langCultureMs": [
        {
          "langCultureName": "div-MV",
          "displayName": "Dhivehi - Maldives",
          "cultureCode": "0x0465"
        }
      ]
    },
    {
      "iso639_1": "nl",
      "iso639_2": "nld",
      "iso639_2en": "dut",
      "iso639_3": "nld",
      "name": [
        "Dutch"
      ],
      "nativeName": [
        "Nederlands",
        "Vlaams"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "BEL",
        "NLD",
        "ABW",
        "CUW",
        "SXM",
        "SUR"
      ],
      "langCultureMs": [
        {
          "langCultureName": "nl-BE",
          "displayName": "Dutch - Belgium",
          "cultureCode": "0x0813"
        },
        {
          "langCultureName": "nl-NL",
          "displayName": "Dutch - The Netherlands",
          "cultureCode": "0x0413"
        }
      ]
    },
    {
      "iso639_1": "dz",
      "iso639_2": "dzo",
      "iso639_2en": "dzo",
      "iso639_3": "dzo",
      "name": [
        "Dzongkha"
      ],
      "nativeName": [
        "རྫོང་ཁ"
      ],
      "direction": "LTR",
      "family": "Sino-Tibetan",
      "countries": [
        "BTN"
      ]
    },
    {
      "iso639_1": "en",
      "iso639_2": "eng",
      "iso639_2en": "eng",
      "iso639_3": "eng",
      "name": [
        "English"
      ],
      "nativeName": [
        "English"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "ATG",
        "AUS",
        "BHS",
        "BRB",
        "BLZ",
        "BWA",
        "CMR",
        "CAN",
        "CUW",
        "DMA",
        "ERI",
        "FJI",
        "GMB",
        "GHA",
        "GRD",
        "GUY",
        "HKG",
        "IND",
        "IRL",
        "JAM",
        "KEN",
        "KIR",
        "LSO",
        "LBR",
        "MWI",
        "MYS",
        "MLT",
        "MHL",
        "MUS",
        "FSM",
        "NAM",
        "NRU",
        "NZL",
        "NGA",
        "PAK",
        "PLW",
        "PNG",
        "PHL",
        "RWA",
        "KNA",
        "LCA",
        "VCT",
        "WSM",
        "SYC",
        "SLE",
        "SGP",
        "SXM",
        "SLB",
        "ZAF",
        "SSD",
        "LKA",
        "SDN",
        "SWZ",
        "TZA",
        "TON",
        "TTO",
        "TUV",
        "UGA",
        "GBR",
        "USA",
        "VUT",
        "ZMB",
        "ZWE"
      ],
      "langCultureMs": [
        {
          "langCultureName": "en-AU",
          "displayName": "English - Australia",
          "cultureCode": "0x0C09"
        },
        {
          "langCultureName": "en-BZ",
          "displayName": "English - Belize",
          "cultureCode": "0x2809"
        },
        {
          "langCultureName": "en-CA",
          "displayName": "English - Canada",
          "cultureCode": "0x1009"
        },
        {
          "langCultureName": "en-CB",
          "displayName": "English - Caribbean",
          "cultureCode": "0x2409"
        },
        {
          "langCultureName": "en-IE",
          "displayName": "English - Ireland",
          "cultureCode": "0x1809"
        },
        {
          "langCultureName": "en-JM",
          "displayName": "English - Jamaica",
          "cultureCode": "0x2009"
        },
        {
          "langCultureName": "en-NZ",
          "displayName": "English - New Zealand",
          "cultureCode": "0x1409"
        },
        {
          "langCultureName": "en-PH",
          "displayName": "English - Philippines",
          "cultureCode": "0x3409"
        },
        {
          "langCultureName": "en-ZA",
          "displayName": "English - South Africa",
          "cultureCode": "0x1C09"
        },
        {
          "langCultureName": "en-TT",
          "displayName": "English - Trinidad and Tobago",
          "cultureCode": "0x2C09"
        },
        {
          "langCultureName": "en-GB",
          "displayName": "English - United Kingdom",
          "cultureCode": "0x0809"
        },
        {
          "langCultureName": "en-US",
          "displayName": "English - United States",
          "cultureCode": "0x0409"
        },
        {
          "langCultureName": "en-ZW",
          "displayName": "English - Zimbabwe",
          "cultureCode": "0x3009"
        },
        {
          "langCultureName": "en-SG",
          "displayName": "English - Singapore",
          "cultureCode": "0x1004"
        }
      ]
    },
    {
      "iso639_1": "eo",
      "iso639_2": "epo",
      "iso639_2en": "epo",
      "iso639_3": "epo",
      "name": [
        "Esperanto"
      ],
      "nativeName": [
        "Esperanto"
      ],
      "direction": "LTR",
      "family": "Constructed"
    },
    {
      "iso639_1": "et",
      "iso639_2": "est",
      "iso639_2en": "est",
      "iso639_3": "est",
      "name": [
        "Estonian"
      ],
      "nativeName": [
        "eesti",
        "eesti keel"
      ],
      "direction": "LTR",
      "family": "Uralic",
      "countries": [
        "EST"
      ],
      "langCultureMs": [
        {
          "langCultureName": "et-EE",
          "displayName": "Estonian - Estonia",
          "cultureCode": "0x0425"
        }
      ]
    },
    {
      "iso639_1": "ee",
      "iso639_2": "ewe",
      "iso639_2en": "ewe",
      "iso639_3": "ewe",
      "name": [
        "Ewe"
      ],
      "nativeName": [
        "Eʋegbe"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "GHA",
        "TGO"
      ]
    },
    {
      "iso639_1": "fo",
      "iso639_2": "fao",
      "iso639_2en": "fao",
      "iso639_3": "fao",
      "name": [
        "Faroese"
      ],
      "nativeName": [
        "føroyskt"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "langCultureMs": [
        {
          "langCultureName": "fo-FO",
          "displayName": "Faroese - Faroe Islands",
          "cultureCode": "0x0438"
        }
      ]
    },
    {
      "iso639_1": "fj",
      "iso639_2": "fij",
      "iso639_2en": "fij",
      "iso639_3": "fij",
      "name": [
        "Fijian"
      ],
      "nativeName": [
        "vosa Vakaviti"
      ],
      "direction": "LTR",
      "family": "Austronesian",
      "countries": [
        "FJI"
      ]
    },
    {
      "iso639_1": "fi",
      "iso639_2": "fin",
      "iso639_2en": "fin",
      "iso639_3": "fin",
      "name": [
        "Finnish"
      ],
      "nativeName": [
        "suomi",
        "suomen kieli"
      ],
      "direction": "LTR",
      "family": "Uralic",
      "countries": [
        "FIN"
      ],
      "langCultureMs": [
        {
          "langCultureName": "fi-FI",
          "displayName": "Finnish - Finland",
          "cultureCode": "0x040B"
        }
      ]
    },
    {
      "iso639_1": "fr",
      "iso639_2": "fra",
      "iso639_2en": "fre",
      "iso639_3": "fra",
      "name": [
        "French"
      ],
      "nativeName": [
        "français",
        "langue française"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "BEL",
        "BEN",
        "BFA",
        "BDI",
        "CMR",
        "CAN",
        "CAF",
        "TCD",
        "COM",
        "CIV",
        "COD",
        "COG",
        "DJI",
        "GNQ",
        "FRA",
        "GUF",
        "PYF",
        "GLP",
        "MTQ",
        "MYT",
        "NCL",
        "REU",
        "BLM",
        "SPM",
        "WLF",
        "GAB",
        "GIN",
        "HTI",
        "ITA",
        "JEY",
        "LUX",
        "MDG",
        "MLI",
        "MUS",
        "MCO",
        "NER",
        "RWA",
        "SEN",
        "SYC",
        "CHE",
        "TGO",
        "VUT"
      ],
      "langCultureMs": [
        {
          "langCultureName": "fr-BE",
          "displayName": "French - Belgium",
          "cultureCode": "0x080C"
        },
        {
          "langCultureName": "fr-CA",
          "displayName": "French - Canada",
          "cultureCode": "0x0C0C"
        },
        {
          "langCultureName": "fr-FR",
          "displayName": "French - France",
          "cultureCode": "0x040C"
        },
        {
          "langCultureName": "fr-LU",
          "displayName": "French - Luxembourg",
          "cultureCode": "0x140C"
        },
        {
          "langCultureName": "fr-MC",
          "displayName": "French - Monaco",
          "cultureCode": "0x180C"
        },
        {
          "langCultureName": "fr-CH",
          "displayName": "French - Switzerland",
          "cultureCode": "0x100C"
        }
      ]
    },
    {
      "iso639_1": "ff",
      "iso639_2": "ful",
      "iso639_2en": "ful",
      "iso639_3": "ful",
      "name": [
        "Fula",
        "Fulah",
        "Pulaar",
        "Pular"
      ],
      "nativeName": [
        "Fulfulde",
        "Pulaar",
        "Pular"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "BEN",
        "BFA",
        "MLI",
        "NER",
        "SEN"
      ]
    },
    {
      "iso639_1": "gl",
      "iso639_2": "glg",
      "iso639_2en": "glg",
      "iso639_3": "glg",
      "name": [
        "Galician"
      ],
      "nativeName": [
        "galego"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "langCultureMs": [
        {
          "langCultureName": "gl-ES",
          "displayName": "Galician - Galician",
          "cultureCode": "0x0456"
        }
      ]
    },
    {
      "iso639_1": "ka",
      "iso639_2": "kat",
      "iso639_2en": "geo",
      "iso639_3": "kat",
      "name": [
        "Georgian"
      ],
      "nativeName": [
        "ქართული"
      ],
      "direction": "LTR",
      "family": "South Caucasian",
      "countries": [
        "GEO"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ka-GE",
          "displayName": "Georgian - Georgia",
          "cultureCode": "0x0437"
        }
      ]
    },
    {
      "iso639_1": "de",
      "iso639_2": "deu",
      "iso639_2en": "ger",
      "iso639_3": "deu",
      "name": [
        "German"
      ],
      "nativeName": [
        "Deutsch"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "AUT",
        "BEL",
        "DEU",
        "LIE",
        "LUX",
        "ITA",
        "CHE"
      ],
      "langCultureMs": [
        {
          "langCultureName": "de-AT",
          "displayName": "German - Austria",
          "cultureCode": "0x0C07"
        },
        {
          "langCultureName": "de-DE",
          "displayName": "German - Germany",
          "cultureCode": "0x0407"
        },
        {
          "langCultureName": "de-LI",
          "displayName": "German - Liechtenstein",
          "cultureCode": "0x1407"
        },
        {
          "langCultureName": "de-LU",
          "displayName": "German - Luxembourg",
          "cultureCode": "0x1007"
        },
        {
          "langCultureName": "de-CH",
          "displayName": "German - Switzerland",
          "cultureCode": "0x0807"
        }
      ]
    },
    {
      "iso639_1": "el",
      "iso639_2": "ell",
      "iso639_2en": "gre",
      "iso639_3": "ell",
      "name": [
        "Greek"
      ],
      "nativeName": [
        "ελληνικά"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "GRC",
        "CYP"
      ],
      "langCultureMs": [
        {
          "langCultureName": "el-GR",
          "displayName": "Greek - Greece",
          "cultureCode": "0x0408"
        }
      ]
    },
    {
      "iso639_1": "gn",
      "iso639_2": "grn",
      "iso639_2en": "grn",
      "iso639_3": "grn",
      "name": [
        "Guaraní"
      ],
      "nativeName": [
        "Avañe'ẽ"
      ],
      "direction": "LTR",
      "family": "Tupian",
      "countries": [
        "PRY",
        "BOL"
      ]
    },
    {
      "iso639_1": "gu",
      "iso639_2": "guj",
      "iso639_2en": "guj",
      "iso639_3": "guj",
      "name": [
        "Gujarati"
      ],
      "nativeName": [
        "ગુજરાતી"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "IND"
      ],
      "langCultureMs": [
        {
          "langCultureName": "gu-IN",
          "displayName": "Gujarati - India",
          "cultureCode": "0x0447"
        }
      ]
    },
    {
      "iso639_1": "ht",
      "iso639_2": "hat",
      "iso639_2en": "hat",
      "iso639_3": "hat",
      "name": [
        "Haitian",
        "Haitian Creole"
      ],
      "nativeName": [
        "Kreyòl ayisyen"
      ],
      "direction": "LTR",
      "family": "Creole",
      "countries": [
        "HTI"
      ]
    },
    {
      "iso639_1": "ha",
      "iso639_2": "hau",
      "iso639_2en": "hau",
      "iso639_3": "hau",
      "name": [
        "Hausa"
      ],
      "nativeName": [
        "(Hausa) هَوُسَ"
      ],
      "direction": "LTR",
      "family": "Afro-Asiatic",
      "countries": [
        "NER",
        "NGA"
      ]
    },
    {
      "iso639_1": "he",
      "iso639_2": "heb",
      "iso639_2en": "heb",
      "iso639_3": "heb",
      "name": [
        "Hebrew"
      ],
      "nativeName": [
        "עברית"
      ],
      "direction": "RTL",
      "family": "Afro-Asiatic",
      "countries": [
        "ISR"
      ],
      "langCultureMs": [
        {
          "langCultureName": "he-IL",
          "displayName": "Hebrew - Israel",
          "cultureCode": "0x040D"
        }
      ]
    },
    {
      "iso639_1": "hz",
      "iso639_2": "her",
      "iso639_2en": "her",
      "iso639_3": "her",
      "name": [
        "Herero"
      ],
      "nativeName": [
        "Otjiherero"
      ],
      "direction": "LTR",
      "family": "Niger–Congo"
    },
    {
      "iso639_1": "hi",
      "iso639_2": "hin",
      "iso639_2en": "hin",
      "iso639_3": "hin",
      "name": [
        "Hindi"
      ],
      "nativeName": [
        "हिन्दी",
        "हिंदी"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "IND",
        "FJI"
      ],
      "langCultureMs": [
        {
          "langCultureName": "hi-IN",
          "displayName": "Hindi - India",
          "cultureCode": "0x0439"
        }
      ]
    },
    {
      "iso639_1": "ho",
      "iso639_2": "hmo",
      "iso639_2en": "hmo",
      "iso639_3": "hmo",
      "name": [
        "Hiri Motu"
      ],
      "nativeName": [
        "Hiri Motu"
      ],
      "direction": "LTR",
      "family": "Austronesian",
      "countries": [
        "PNG"
      ]
    },
    {
      "iso639_1": "hu",
      "iso639_2": "hun",
      "iso639_2en": "hun",
      "iso639_3": "hun",
      "name": [
        "Hungarian"
      ],
      "nativeName": [
        "magyar"
      ],
      "direction": "LTR",
      "family": "Uralic",
      "countries": [
        "HUN"
      ],
      "langCultureMs": [
        {
          "langCultureName": "hu-HU",
          "displayName": "Hungarian - Hungary",
          "cultureCode": "0x040E"
        }
      ]
    },
    {
      "iso639_1": "ia",
      "iso639_2": "ina",
      "iso639_2en": "ina",
      "iso639_3": "ina",
      "name": [
        "Interlingua"
      ],
      "nativeName": [
        "Interlingua"
      ],
      "direction": "LTR",
      "family": "Constructed"
    },
    {
      "iso639_1": "id",
      "iso639_2": "ind",
      "iso639_2en": "ind",
      "iso639_3": "ind",
      "name": [
        "Indonesian"
      ],
      "nativeName": [
        "Bahasa Indonesia"
      ],
      "direction": "LTR",
      "family": "Austronesian",
      "countries": [
        "IDN"
      ],
      "langCultureMs": [
        {
          "langCultureName": "id-ID",
          "displayName": "Indonesian - Indonesia",
          "cultureCode": "0x0421"
        }
      ]
    },
    {
      "iso639_1": "ie",
      "iso639_2": "ile",
      "iso639_2en": "ile",
      "iso639_3": "ile",
      "name": [
        "Interlingue"
      ],
      "nativeName": [
        "Interlingue"
      ],
      "direction": "LTR",
      "family": "Constructed"
    },
    {
      "iso639_1": "ga",
      "iso639_2": "gle",
      "iso639_2en": "gle",
      "iso639_3": "gle",
      "name": [
        "Irish"
      ],
      "nativeName": [
        "Gaeilge"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "IRL"
      ]
    },
    {
      "iso639_1": "ig",
      "iso639_2": "ibo",
      "iso639_2en": "ibo",
      "iso639_3": "ibo",
      "name": [
        "Igbo"
      ],
      "nativeName": [
        "Asụsụ Igbo"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "NGA"
      ]
    },
    {
      "iso639_1": "ik",
      "iso639_2": "ipk",
      "iso639_2en": "ipk",
      "iso639_3": "ipk",
      "name": [
        "Inupiaq"
      ],
      "nativeName": [
        "Iñupiaq",
        "Iñupiatun"
      ],
      "direction": "LTR",
      "family": "Eskimo–Aleut"
    },
    {
      "iso639_1": "io",
      "iso639_2": "ido",
      "iso639_2en": "ido",
      "iso639_3": "ido",
      "name": [
        "Ido"
      ],
      "nativeName": [
        "Ido"
      ],
      "direction": "LTR",
      "family": "Constructed"
    },
    {
      "iso639_1": "is",
      "iso639_2": "isl",
      "iso639_2en": "ice",
      "iso639_3": "isl",
      "name": [
        "Icelandic"
      ],
      "nativeName": [
        "Íslenska"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "ISL"
      ],
      "langCultureMs": [
        {
          "langCultureName": "is-IS",
          "displayName": "Icelandic - Iceland",
          "cultureCode": "0x040F"
        }
      ]
    },
    {
      "iso639_1": "it",
      "iso639_2": "ita",
      "iso639_2en": "ita",
      "iso639_3": "ita",
      "name": [
        "Italian"
      ],
      "nativeName": [
        "italiano"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "ITA",
        "HRV",
        "SMR",
        "SVN",
        "CHE",
        "VAT"
      ],
      "langCultureMs": [
        {
          "langCultureName": "it-IT",
          "displayName": "Italian - Italy",
          "cultureCode": "0x0410"
        },
        {
          "langCultureName": "it-CH",
          "displayName": "Italian - Switzerland",
          "cultureCode": "0x0810"
        }
      ]
    },
    {
      "iso639_1": "iu",
      "iso639_2": "iku",
      "iso639_2en": "iku",
      "iso639_3": "iku",
      "name": [
        "Inuktitut"
      ],
      "nativeName": [
        "ᐃᓄᒃᑎᑐᑦ"
      ],
      "direction": "LTR",
      "family": "Eskimo–Aleut"
    },
    {
      "iso639_1": "ja",
      "iso639_2": "jpn",
      "iso639_2en": "jpn",
      "iso639_3": "jpn",
      "name": [
        "Japanese"
      ],
      "nativeName": [
        "日本語 (にほんご)"
      ],
      "direction": "LTR",
      "family": "Japonic",
      "countries": [
        "JPN"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ja-JP",
          "displayName": "Japanese - Japan",
          "cultureCode": "0x0411"
        }
      ]
    },
    {
      "iso639_1": "jv",
      "iso639_2": "jav",
      "iso639_2en": "jav",
      "iso639_3": "jav",
      "name": [
        "Javanese"
      ],
      "nativeName": [
        "basa Jawa"
      ],
      "direction": "LTR",
      "family": "Austronesian"
    },
    {
      "iso639_1": "kl",
      "iso639_2": "kal",
      "iso639_2en": "kal",
      "iso639_3": "kal",
      "name": [
        "Kalaallisut",
        "Greenlandic"
      ],
      "nativeName": [
        "kalaallisut",
        "kalaallit oqaasii"
      ],
      "direction": "LTR",
      "family": "Eskimo–Aleut"
    },
    {
      "iso639_1": "kn",
      "iso639_2": "kan",
      "iso639_2en": "kan",
      "iso639_3": "kan",
      "name": [
        "Kannada"
      ],
      "nativeName": [
        "ಕನ್ನಡ"
      ],
      "direction": "LTR",
      "family": "Dravidian",
      "langCultureMs": [
        {
          "langCultureName": "kn-IN",
          "displayName": "Kannada - India",
          "cultureCode": "0x044B"
        }
      ]
    },
    {
      "iso639_1": "kr",
      "iso639_2": "kau",
      "iso639_2en": "kau",
      "iso639_3": "kau",
      "name": [
        "Kanuri"
      ],
      "nativeName": [
        "Kanuri"
      ],
      "direction": "LTR",
      "family": "Nilo-Saharan",
      "countries": [
        "NER"
      ]
    },
    {
      "iso639_1": "ks",
      "iso639_2": "kas",
      "iso639_2en": "kas",
      "iso639_3": "kas",
      "name": [
        "Kashmiri"
      ],
      "nativeName": [
        "कश्मीरी",
        "كشميري‎"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "kk",
      "iso639_2": "kaz",
      "iso639_2en": "kaz",
      "iso639_3": "kaz",
      "name": [
        "Kazakh"
      ],
      "nativeName": [
        "қазақ тілі"
      ],
      "direction": "LTR",
      "family": "Turkic",
      "countries": [
        "KAZ"
      ],
      "langCultureMs": [
        {
          "langCultureName": "kk-KZ",
          "displayName": "Kazakh - Kazakhstan",
          "cultureCode": "0x043F"
        }
      ]
    },
    {
      "iso639_1": "km",
      "iso639_2": "khm",
      "iso639_2en": "khm",
      "iso639_3": "khm",
      "name": [
        "Khmer"
      ],
      "nativeName": [
        "ខ្មែរ",
        "ខេមរភាសា",
        "ភាសាខ្មែរ"
      ],
      "direction": "LTR",
      "family": "Austroasiatic",
      "countries": [
        "KHM"
      ]
    },
    {
      "iso639_1": "ki",
      "iso639_2": "kik",
      "iso639_2en": "kik",
      "iso639_3": "kik",
      "name": [
        "Kikuyu",
        "Gikuyu"
      ],
      "nativeName": [
        "Gĩkũyũ"
      ],
      "direction": "LTR",
      "family": "Niger–Congo"
    },
    {
      "iso639_1": "rw",
      "iso639_2": "kin",
      "iso639_2en": "kin",
      "iso639_3": "kin",
      "name": [
        "Kinyarwanda"
      ],
      "nativeName": [
        "Ikinyarwanda"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "RWA"
      ]
    },
    {
      "iso639_1": "ky",
      "iso639_2": "kir",
      "iso639_2en": "kir",
      "iso639_3": "kir",
      "name": [
        "Kyrgyz"
      ],
      "nativeName": [
        "Кыргызча",
        "Кыргыз тили"
      ],
      "direction": "LTR",
      "family": "Turkic",
      "countries": [
        "KGZ"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ky-KZ",
          "displayName": "Kyrgyz - Kazakhstan",
          "cultureCode": "0x0440"
        }
      ]
    },
    {
      "iso639_1": "kv",
      "iso639_2": "kom",
      "iso639_2en": "kom",
      "iso639_3": "kom",
      "name": [
        "Komi"
      ],
      "nativeName": [
        "коми кыв"
      ],
      "direction": "LTR",
      "family": "Uralic"
    },
    {
      "iso639_1": "kg",
      "iso639_2": "kon",
      "iso639_2en": "kon",
      "iso639_3": "kon",
      "name": [
        "Kongo"
      ],
      "nativeName": [
        "Kikongo"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "AGO",
        "COD",
        "COG"
      ]
    },
    {
      "iso639_1": "ko",
      "iso639_2": "kor",
      "iso639_2en": "kor",
      "iso639_3": "kor",
      "name": [
        "Korean"
      ],
      "nativeName": [
        "한국어",
        "조선어"
      ],
      "direction": "LTR",
      "family": "Koreanic",
      "countries": [
        "PRK",
        "KOR"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ko-KR",
          "displayName": "Korean - Korea",
          "cultureCode": "0x0412"
        }
      ]
    },
    {
      "iso639_1": "ku",
      "iso639_2": "kur",
      "iso639_2en": "kur",
      "iso639_3": "kur",
      "name": [
        "Kurdish"
      ],
      "nativeName": [
        "Kurdî",
        "كوردی‎"
      ],
      "direction": "RTL",
      "family": "Indo-European",
      "countries": [
        "IRQ"
      ]
    },
    {
      "iso639_1": "kj",
      "iso639_2": "kua",
      "iso639_2en": "kua",
      "iso639_3": "kua",
      "name": [
        "Kwanyama",
        "Kuanyama"
      ],
      "nativeName": [
        "Kuanyama"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "AGO"
      ]
    },
    {
      "iso639_1": "la",
      "iso639_2": "lat",
      "iso639_2en": "lat",
      "iso639_3": "lat",
      "name": [
        "Latin"
      ],
      "nativeName": [
        "latine",
        "lingua latina"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "VAT"
      ]
    },
    {
      "iso639_1": "lb",
      "iso639_2": "ltz",
      "iso639_2en": "ltz",
      "iso639_3": "ltz",
      "name": [
        "Luxembourgish",
        "Letzeburgesch"
      ],
      "nativeName": [
        "Lëtzebuergesch"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "LUX"
      ]
    },
    {
      "iso639_1": "lg",
      "iso639_2": "lug",
      "iso639_2en": "lug",
      "iso639_3": "lug",
      "name": [
        "Ganda"
      ],
      "nativeName": [
        "Luganda"
      ],
      "direction": "LTR",
      "family": "Niger–Congo"
    },
    {
      "iso639_1": "li",
      "iso639_2": "lim",
      "iso639_2en": "lim",
      "iso639_3": "lim",
      "name": [
        "Limburgish",
        "Limburgan",
        "Limburger"
      ],
      "nativeName": [
        "Limburgs"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "ln",
      "iso639_2": "lin",
      "iso639_2en": "lin",
      "iso639_3": "lin",
      "name": [
        "Lingala"
      ],
      "nativeName": [
        "Lingála"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "COD",
        "COG"
      ]
    },
    {
      "iso639_1": "lo",
      "iso639_2": "lao",
      "iso639_2en": "lao",
      "iso639_3": "lao",
      "name": [
        "Lao"
      ],
      "nativeName": [
        "ພາສາລາວ"
      ],
      "direction": "LTR",
      "family": "Tai–Kadai",
      "countries": [
        "LAO"
      ]
    },
    {
      "iso639_1": "lt",
      "iso639_2": "lit",
      "iso639_2en": "lit",
      "iso639_3": "lit",
      "name": [
        "Lithuanian"
      ],
      "nativeName": [
        "lietuvių kalba"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "LTU"
      ],
      "langCultureMs": [
        {
          "langCultureName": "lt-LT",
          "displayName": "Lithuanian - Lithuania",
          "cultureCode": "0x0427"
        }
      ]
    },
    {
      "iso639_1": "lu",
      "iso639_2": "lub",
      "iso639_2en": "lub",
      "iso639_3": "lub",
      "name": [
        "Luba-Katanga"
      ],
      "nativeName": [
        "Tshiluba"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "COD"
      ]
    },
    {
      "iso639_1": "lv",
      "iso639_2": "lav",
      "iso639_2en": "lav",
      "iso639_3": "lav",
      "name": [
        "Latvian"
      ],
      "nativeName": [
        "latviešu valoda"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "LVA"
      ],
      "langCultureMs": [
        {
          "langCultureName": "lv-LV",
          "displayName": "Latvian - Latvia",
          "cultureCode": "0x0426"
        }
      ]
    },
    {
      "iso639_1": "gv",
      "iso639_2": "glv",
      "iso639_2en": "glv",
      "iso639_3": "glv",
      "name": [
        "Manx"
      ],
      "nativeName": [
        "Gaelg",
        "Gailck"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "mk",
      "iso639_2": "mkd",
      "iso639_2en": "mac",
      "iso639_3": "mkd",
      "name": [
        "Macedonian"
      ],
      "nativeName": [
        "македонски јазик"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "MKD"
      ],
      "langCultureMs": [
        {
          "langCultureName": "mk-MK",
          "displayName": "Macedonian (FYROM)",
          "cultureCode": "0x042F"
        }
      ]
    },
    {
      "iso639_1": "mg",
      "iso639_2": "mlg",
      "iso639_2en": "mlg",
      "iso639_3": "mlg",
      "name": [
        "Malagasy"
      ],
      "nativeName": [
        "fiteny malagasy"
      ],
      "direction": "LTR",
      "family": "Austronesian",
      "countries": [
        "MDG"
      ]
    },
    {
      "iso639_1": "ms",
      "iso639_2": "msa",
      "iso639_2en": "may",
      "iso639_3": "msa",
      "name": [
        "Malay"
      ],
      "nativeName": [
        "bahasa Melayu",
        "بهاس ملايو‎"
      ],
      "direction": "LTR",
      "family": "Austronesian",
      "countries": [
        "MYS",
        "BRN",
        "SGP",
        "IDN"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ms-BN",
          "displayName": "Malay - Brunei",
          "cultureCode": "0x083E"
        },
        {
          "langCultureName": "ms-MY",
          "displayName": "Malay - Malaysia",
          "cultureCode": "0x043E"
        }
      ]
    },
    {
      "iso639_1": "ml",
      "iso639_2": "mal",
      "iso639_2en": "mal",
      "iso639_3": "mal",
      "name": [
        "Malayalam"
      ],
      "nativeName": [
        "മലയാളം"
      ],
      "direction": "LTR",
      "family": "Dravidian"
    },
    {
      "iso639_1": "mt",
      "iso639_2": "mlt",
      "iso639_2en": "mlt",
      "iso639_3": "mlt",
      "name": [
        "Maltese"
      ],
      "nativeName": [
        "Malti"
      ],
      "direction": "LTR",
      "family": "Afro-Asiatic",
      "countries": [
        "MLT"
      ]
    },
    {
      "iso639_1": "mi",
      "iso639_2": "mri",
      "iso639_2en": "mao",
      "iso639_3": "mri",
      "name": [
        "Māori"
      ],
      "nativeName": [
        "te reo Māori"
      ],
      "direction": "LTR",
      "family": "Austronesian",
      "countries": [
        "NZL"
      ]
    },
    {
      "iso639_1": "mr",
      "iso639_2": "mar",
      "iso639_2en": "mar",
      "iso639_3": "mar",
      "name": [
        "Marathi (Marāṭhī)"
      ],
      "nativeName": [
        "मराठी"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "langCultureMs": [
        {
          "langCultureName": "mr-IN",
          "displayName": "Marathi - India",
          "cultureCode": "0x044E"
        }
      ]
    },
    {
      "iso639_1": "mh",
      "iso639_2": "mah",
      "iso639_2en": "mah",
      "iso639_3": "mah",
      "name": [
        "Marshallese"
      ],
      "nativeName": [
        "Kajin M̧ajeļ"
      ],
      "direction": "LTR",
      "family": "Austronesian",
      "countries": [
        "MHL"
      ]
    },
    {
      "iso639_1": "mn",
      "iso639_2": "mon",
      "iso639_2en": "mon",
      "iso639_3": "mon",
      "name": [
        "Mongolian"
      ],
      "nativeName": [
        "монгол"
      ],
      "direction": "LTR",
      "family": "Mongolic",
      "countries": [
        "MNG"
      ],
      "langCultureMs": [
        {
          "langCultureName": "mn-MN",
          "displayName": "Mongolian - Mongolia",
          "cultureCode": "0x0450"
        }
      ]
    },
    {
      "iso639_1": "na",
      "iso639_2": "nau",
      "iso639_2en": "nau",
      "iso639_3": "nau",
      "name": [
        "Nauru"
      ],
      "nativeName": [
        "Ekakairũ Naoero"
      ],
      "direction": "LTR",
      "family": "Austronesian"
    },
    {
      "iso639_1": "nv",
      "iso639_2": "nav",
      "iso639_2en": "nav",
      "iso639_3": "nav",
      "name": [
        "Navajo",
        "Navaho"
      ],
      "nativeName": [
        "Diné bizaad",
        "Dinékʼehǰí"
      ],
      "direction": "LTR",
      "family": "Dené–Yeniseian"
    },
    {
      "iso639_1": "nb",
      "iso639_2": "nob",
      "iso639_2en": "nob",
      "iso639_3": "nob",
      "name": [
        "Norwegian Bokmål"
      ],
      "nativeName": [
        "Norsk bokmål"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "NOR"
      ],
      "langCultureMs": [
        {
          "langCultureName": "nb-NO",
          "displayName": "Norwegian (Bokmål) - Norway",
          "cultureCode": "0x0414"
        }
      ]
    },
    {
      "iso639_1": "nd",
      "iso639_2": "nde",
      "iso639_2en": "nde",
      "iso639_3": "nde",
      "name": [
        "Northern Ndebele"
      ],
      "nativeName": [
        "isiNdebele"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "ZAF",
        "ZWE"
      ]
    },
    {
      "iso639_1": "",
      "iso639_2": "nso",
      "iso639_2en": "nso",
      "iso639_3": "nso",
      "name": [
        "Northern Sotho"
      ],
      "nativeName": [
        "Sesotho sa Leboa"
      ],
      "direction": "LTR",
      "family": "Niger-Congo",
      "countries": [
        "ZAF"
      ]
    },
    {
      "iso639_1": "ne",
      "iso639_2": "nep",
      "iso639_2en": "nep",
      "iso639_3": "nep",
      "name": [
        "Nepali"
      ],
      "nativeName": [
        "नेपाली"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "NPL"
      ]
    },
    {
      "iso639_1": "ng",
      "iso639_2": "ndo",
      "iso639_2en": "ndo",
      "iso639_3": "ndo",
      "name": [
        "Ndonga"
      ],
      "nativeName": [
        "Owambo"
      ],
      "direction": "LTR",
      "family": "Niger–Congo"
    },
    {
      "iso639_1": "nn",
      "iso639_2": "nno",
      "iso639_2en": "nno",
      "iso639_3": "nno",
      "name": [
        "Norwegian Nynorsk"
      ],
      "nativeName": [
        "Norsk nynorsk"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "NOR"
      ],
      "langCultureMs": [
        {
          "langCultureName": "nn-NO",
          "displayName": "Norwegian (Nynorsk) - Norway",
          "cultureCode": "0x0814"
        }
      ]
    },
    {
      "iso639_1": "no",
      "iso639_2": "nor",
      "iso639_2en": "nor",
      "iso639_3": "nor",
      "name": [
        "Norwegian"
      ],
      "nativeName": [
        "Norsk"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "NOR"
      ]
    },
    {
      "iso639_1": "ii",
      "iso639_2": "iii",
      "iso639_2en": "iii",
      "iso639_3": "iii",
      "name": [
        "Nuosu"
      ],
      "nativeName": [
        "Nuosuhxop"
      ],
      "direction": "LTR",
      "family": "Sino-Tibetan"
    },
    {
      "iso639_1": "nr",
      "iso639_2": "nbl",
      "iso639_2en": "nbl",
      "iso639_3": "nbl",
      "name": [
        "Southern Ndebele"
      ],
      "nativeName": [
        "isiNdebele"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "ZAF"
      ]
    },
    {
      "iso639_1": "oc",
      "iso639_2": "oci",
      "iso639_2en": "oci",
      "iso639_3": "oci",
      "name": [
        "Occitan"
      ],
      "nativeName": [
        "occitan",
        "lenga d'òc"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "oj",
      "iso639_2": "oji",
      "iso639_2en": "oji",
      "iso639_3": "oji",
      "name": [
        "Ojibwe",
        "Ojibwa"
      ],
      "nativeName": [
        "ᐊᓂᔑᓈᐯᒧᐎᓐ"
      ],
      "direction": "LTR",
      "family": "Algonquian"
    },
    {
      "iso639_1": "cu",
      "iso639_2": "chu",
      "iso639_2en": "chu",
      "iso639_3": "chu",
      "name": [
        "Old Church Slavonic",
        "Church Slavonic",
        "Old Bulgarian"
      ],
      "nativeName": [
        "ѩзыкъ словѣньскъ"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "om",
      "iso639_2": "orm",
      "iso639_2en": "orm",
      "iso639_3": "orm",
      "name": [
        "Oromo"
      ],
      "nativeName": [
        "Afaan Oromoo"
      ],
      "direction": "LTR",
      "family": "Afro-Asiatic"
    },
    {
      "iso639_1": "or",
      "iso639_2": "ori",
      "iso639_2en": "ori",
      "iso639_3": "ori",
      "name": [
        "Oriya"
      ],
      "nativeName": [
        "ଓଡ଼ିଆ"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "os",
      "iso639_2": "oss",
      "iso639_2en": "oss",
      "iso639_3": "oss",
      "name": [
        "Ossetian",
        "Ossetic"
      ],
      "nativeName": [
        "ирон æвзаг"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": []
    },
    {
      "iso639_1": "pa",
      "iso639_2": "pan",
      "iso639_2en": "pan",
      "iso639_3": "pan",
      "name": [
        "Panjabi",
        "Punjabi"
      ],
      "nativeName": [
        "ਪੰਜਾਬੀ",
        "پنجابی‎"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "IND"
      ],
      "langCultureMs": [
        {
          "langCultureName": "pa-IN",
          "displayName": "Punjabi - India",
          "cultureCode": "0x0446"
        }
      ]
    },
    {
      "iso639_1": "pi",
      "iso639_2": "pli",
      "iso639_2en": "pli",
      "iso639_3": "pli",
      "name": [
        "Pāli"
      ],
      "nativeName": [
        "पाऴि"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "fa",
      "iso639_2": "fas",
      "iso639_2en": "per",
      "iso639_3": "fas",
      "name": [
        "Persian",
        "Farsi"
      ],
      "nativeName": [
        "فارسی"
      ],
      "direction": "RTL",
      "family": "Indo-European",
      "countries": [
        "IRN",
        "AFG",
        "TJK"
      ],
      "langCultureMs": [
        {
          "langCultureName": "fa-IR",
          "displayName": "Farsi - Iran",
          "cultureCode": "0x0429"
        }
      ]
    },
    {
      "iso639_1": "pl",
      "iso639_2": "pol",
      "iso639_2en": "pol",
      "iso639_3": "pol",
      "name": [
        "Polish"
      ],
      "nativeName": [
        "język polski",
        "polszczyzna"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "POL"
      ],
      "langCultureMs": [
        {
          "langCultureName": "pl-PL",
          "displayName": "Polish - Poland",
          "cultureCode": "0x0415"
        }
      ]
    },
    {
      "iso639_1": "ps",
      "iso639_2": "pus",
      "iso639_2en": "pus",
      "iso639_3": "pus",
      "name": [
        "Pashto",
        "Pushto"
      ],
      "nativeName": [
        "پښتو"
      ],
      "direction": "RTL",
      "family": "Indo-European",
      "countries": [
        "AFG"
      ]
    },
    {
      "iso639_1": "pt",
      "iso639_2": "por",
      "iso639_2en": "por",
      "iso639_3": "por",
      "name": [
        "Portuguese"
      ],
      "nativeName": [
        "português"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "AGO",
        "BRA",
        "CPV",
        "TLS",
        "GNQ",
        "GNB",
        "MAC",
        "MOZ",
        "PRT",
        "STP"
      ],
      "langCultureMs": [
        {
          "langCultureName": "pt-BR",
          "displayName": "Portuguese - Brazil",
          "cultureCode": "0x0416"
        },
        {
          "langCultureName": "pt-PT",
          "displayName": "Portuguese - Portugal",
          "cultureCode": "0x0816"
        }
      ]
    },
    {
      "iso639_1": "qu",
      "iso639_2": "que",
      "iso639_2en": "que",
      "iso639_3": "que",
      "name": [
        "Quechua"
      ],
      "nativeName": [
        "Runa Simi",
        "Kichwa"
      ],
      "direction": "LTR",
      "family": "Quechuan",
      "countries": [
        "BOL",
        "PER"
      ]
    },
    {
      "iso639_1": "rm",
      "iso639_2": "roh",
      "iso639_2en": "roh",
      "iso639_3": "roh",
      "name": [
        "Romansh"
      ],
      "nativeName": [
        "rumantsch grischun"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "CHE"
      ]
    },
    {
      "iso639_1": "rn",
      "iso639_2": "run",
      "iso639_2en": "run",
      "iso639_3": "run",
      "name": [
        "Kirundi"
      ],
      "nativeName": [
        "Ikirundi"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "BDI"
      ]
    },
    {
      "iso639_1": "ro",
      "iso639_2": "ron",
      "iso639_2en": "rum",
      "iso639_3": "ron",
      "name": [
        "Romanian"
      ],
      "nativeName": [
        "limba română"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "ROU",
        "MDA"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ro-RO",
          "displayName": "Romanian - Romania",
          "cultureCode": "0x0418"
        }
      ]
    },
    {
      "iso639_1": "ru",
      "iso639_2": "rus",
      "iso639_2en": "rus",
      "iso639_3": "rus",
      "name": [
        "Russian"
      ],
      "nativeName": [
        "русский язык"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "RUS",
        "BLR",
        "KAZ",
        "KGZ",
        "TJK"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ru-RU",
          "displayName": "Russian - Russia",
          "cultureCode": "0x0419"
        }
      ]
    },
    {
      "iso639_1": "sa",
      "iso639_2": "san",
      "iso639_2en": "san",
      "iso639_3": "san",
      "name": [
        "Sanskrit (Saṁskṛta)"
      ],
      "nativeName": [
        "संस्कृतम्"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "langCultureMs": [
        {
          "langCultureName": "sa-IN",
          "displayName": "Sanskrit - India",
          "cultureCode": "0x044F"
        }
      ]
    },
    {
      "iso639_1": "sc",
      "iso639_2": "srd",
      "iso639_2en": "srd",
      "iso639_3": "srd",
      "name": [
        "Sardinian"
      ],
      "nativeName": [
        "sardu"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "sd",
      "iso639_2": "snd",
      "iso639_2en": "snd",
      "iso639_3": "snd",
      "name": [
        "Sindhi"
      ],
      "nativeName": [
        "सिन्धी",
        "سنڌي، سندھی‎"
      ],
      "direction": "RTL",
      "family": "Indo-European"
    },
    {
      "iso639_1": "se",
      "iso639_2": "sme",
      "iso639_2en": "sme",
      "iso639_3": "sme",
      "name": [
        "Northern Sami"
      ],
      "nativeName": [
        "Davvisámegiella"
      ],
      "direction": "LTR",
      "family": "Uralic"
    },
    {
      "iso639_1": "sm",
      "iso639_2": "smo",
      "iso639_2en": "smo",
      "iso639_3": "smo",
      "name": [
        "Samoan"
      ],
      "nativeName": [
        "gagana fa'a Samoa"
      ],
      "direction": "LTR",
      "family": "Austronesian"
    },
    {
      "iso639_1": "sg",
      "iso639_2": "sag",
      "iso639_2en": "sag",
      "iso639_3": "sag",
      "name": [
        "Sango"
      ],
      "nativeName": [
        "yângâ tî sängö"
      ],
      "direction": "LTR",
      "family": "Creole",
      "countries": [
        "CAF"
      ]
    },
    {
      "iso639_1": "sr",
      "iso639_2": "srp",
      "iso639_2en": "srp",
      "iso639_3": "srp",
      "name": [
        "Serbian"
      ],
      "nativeName": [
        "српски језик"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "SRB",
        "BIH"
      ],
      "langCultureMs": [
        {
          "langCultureName": "Cy-sr-SP",
          "displayName": "Serbian (Cyrillic) - Serbia",
          "cultureCode": "0x0C1A"
        },
        {
          "langCultureName": "Lt-sr-SP",
          "displayName": "Serbian (Latin) - Serbia",
          "cultureCode": "0x081A"
        }
      ]
    },
    {
      "iso639_1": "gd",
      "iso639_2": "gla",
      "iso639_2en": "gla",
      "iso639_3": "gla",
      "name": [
        "Scottish Gaelic",
        "Gaelic"
      ],
      "nativeName": [
        "Gàidhlig"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "sn",
      "iso639_2": "sna",
      "iso639_2en": "sna",
      "iso639_3": "sna",
      "name": [
        "Shona"
      ],
      "nativeName": [
        "chiShona"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "ZWE"
      ]
    },
    {
      "iso639_1": "si",
      "iso639_2": "sin",
      "iso639_2en": "sin",
      "iso639_3": "sin",
      "name": [
        "Sinhala",
        "Sinhalese"
      ],
      "nativeName": [
        "සිංහල"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "LKA"
      ]
    },
    {
      "iso639_1": "sk",
      "iso639_2": "slk",
      "iso639_2en": "slo",
      "iso639_3": "slk",
      "name": [
        "Slovak"
      ],
      "nativeName": [
        "slovenčina",
        "slovenský jazyk"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "SVK",
        "CZE"
      ],
      "langCultureMs": [
        {
          "langCultureName": "sk-SK",
          "displayName": "Slovak - Slovakia",
          "cultureCode": "0x041B"
        }
      ]
    },
    {
      "iso639_1": "sl",
      "iso639_2": "slv",
      "iso639_2en": "slv",
      "iso639_3": "slv",
      "name": [
        "Slovene"
      ],
      "nativeName": [
        "slovenski jezik",
        "slovenščina"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "SVN"
      ],
      "langCultureMs": [
        {
          "langCultureName": "sl-SI",
          "displayName": "Slovenian - Slovenia",
          "cultureCode": "0x0424"
        }
      ]
    },
    {
      "iso639_1": "so",
      "iso639_2": "som",
      "iso639_2en": "som",
      "iso639_3": "som",
      "name": [
        "Somali"
      ],
      "nativeName": [
        "Soomaaliga",
        "af Soomaali"
      ],
      "direction": "LTR",
      "family": "Afro-Asiatic",
      "countries": [
        "DJI",
        "SOM"
      ]
    },
    {
      "iso639_1": "st",
      "iso639_2": "sot",
      "iso639_2en": "sot",
      "iso639_3": "sot",
      "name": [
        "Southern Sotho"
      ],
      "nativeName": [
        "Sesotho"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "LSO",
        "ZAF"
      ]
    },
    {
      "iso639_1": "es",
      "iso639_2": "spa",
      "iso639_2en": "spa",
      "iso639_3": "spa",
      "name": [
        "Spanish",
        "Castilian"
      ],
      "nativeName": [
        "español",
        "castellano"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "ARG",
        "BOL",
        "CHL",
        "COL",
        "CRI",
        "CUB",
        "DOM",
        "ECU",
        "SLV",
        "GNQ",
        "GTM",
        "HND",
        "MEX",
        "NIC",
        "PAN",
        "PRY",
        "PER",
        "PRI",
        "ESP",
        "URY",
        "VEN",
        "ESH"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-AR",
          "displayName": "Spanish - Argentina",
          "cultureCode": "0x2C0A"
        },
        {
          "langCultureName": "es-BO",
          "displayName": "Spanish - Bolivia",
          "cultureCode": "0x400A"
        },
        {
          "langCultureName": "es-CL",
          "displayName": "Spanish - Chile",
          "cultureCode": "0x340A"
        },
        {
          "langCultureName": "es-CO",
          "displayName": "Spanish - Colombia",
          "cultureCode": "0x240A"
        },
        {
          "langCultureName": "es-CR",
          "displayName": "Spanish - Costa Rica",
          "cultureCode": "0x140A"
        },
        {
          "langCultureName": "es-DO",
          "displayName": "Spanish - Dominican Republic",
          "cultureCode": "0x1C0A"
        },
        {
          "langCultureName": "es-EC",
          "displayName": "Spanish - Ecuador",
          "cultureCode": "0x300A"
        },
        {
          "langCultureName": "es-SV",
          "displayName": "Spanish - El Salvador",
          "cultureCode": "0x440A"
        },
        {
          "langCultureName": "es-GT",
          "displayName": "Spanish - Guatemala",
          "cultureCode": "0x100A"
        },
        {
          "langCultureName": "es-HN",
          "displayName": "Spanish - Honduras",
          "cultureCode": "0x480A"
        },
        {
          "langCultureName": "es-MX",
          "displayName": "Spanish - Mexico",
          "cultureCode": "0x080A"
        },
        {
          "langCultureName": "es-NI",
          "displayName": "Spanish - Nicaragua",
          "cultureCode": "0x4C0A"
        },
        {
          "langCultureName": "es-PA",
          "displayName": "Spanish - Panama",
          "cultureCode": "0x180A"
        },
        {
          "langCultureName": "es-PY",
          "displayName": "Spanish - Paraguay",
          "cultureCode": "0x3C0A"
        },
        {
          "langCultureName": "es-PE",
          "displayName": "Spanish - Peru",
          "cultureCode": "0x280A"
        },
        {
          "langCultureName": "es-PR",
          "displayName": "Spanish - Puerto Rico",
          "cultureCode": "0x500A"
        },
        {
          "langCultureName": "es-ES",
          "displayName": "Spanish - Spain",
          "cultureCode": "0x0C0A"
        },
        {
          "langCultureName": "es-UY",
          "displayName": "Spanish - Uruguay",
          "cultureCode": "0x380A"
        },
        {
          "langCultureName": "es-VE",
          "displayName": "Spanish - Venezuela",
          "cultureCode": "0x200A"
        }
      ]
    },
    {
      "iso639_1": "su",
      "iso639_2": "sun",
      "iso639_2en": "sun",
      "iso639_3": "sun",
      "name": [
        "Sundanese"
      ],
      "nativeName": [
        "Basa Sunda"
      ],
      "direction": "LTR",
      "family": "Austronesian"
    },
    {
      "iso639_1": "sw",
      "iso639_2": "swa",
      "iso639_2en": "swa",
      "iso639_3": "swa",
      "name": [
        "Swahili"
      ],
      "nativeName": [
        "Kiswahili"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "COD",
        "KEN",
        "TZA",
        "UGA"
      ],
      "langCultureMs": [
        {
          "langCultureName": "sw-KE",
          "displayName": "Swahili - Kenya",
          "cultureCode": "0x0441"
        }
      ]
    },
    {
      "iso639_1": "ss",
      "iso639_2": "ssw",
      "iso639_2en": "ssw",
      "iso639_3": "ssw",
      "name": [
        "Swati"
      ],
      "nativeName": [
        "SiSwati"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "SWZ",
        "ZAF"
      ]
    },
    {
      "iso639_1": "sv",
      "iso639_2": "swe",
      "iso639_2en": "swe",
      "iso639_3": "swe",
      "name": [
        "Swedish"
      ],
      "nativeName": [
        "Svenska"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "SWE",
        "FIN",
        "ALA"
      ],
      "langCultureMs": [
        {
          "langCultureName": "sv-FI",
          "displayName": "Swedish - Finland",
          "cultureCode": "0x081D"
        },
        {
          "langCultureName": "sv-SE",
          "displayName": "Swedish - Sweden",
          "cultureCode": "0x041D"
        }
      ]
    },
    {
      "iso639_1": "ta",
      "iso639_2": "tam",
      "iso639_2en": "tam",
      "iso639_3": "tam",
      "name": [
        "Tamil"
      ],
      "nativeName": [
        "தமிழ்"
      ],
      "direction": "LTR",
      "family": "Dravidian",
      "countries": [
        "IND",
        "SGP",
        "LKA",
        "MYS",
        "MUS"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ta-IN",
          "displayName": "Tamil - India",
          "cultureCode": "0x0449"
        }
      ]
    },
    {
      "iso639_1": "te",
      "iso639_2": "tel",
      "iso639_2en": "tel",
      "iso639_3": "tel",
      "name": [
        "Telugu"
      ],
      "nativeName": [
        "తెలుగు"
      ],
      "direction": "LTR",
      "family": "Dravidian",
      "countries": [
        "IND"
      ],
      "langCultureMs": [
        {
          "langCultureName": "te-IN",
          "displayName": "Telugu - India",
          "cultureCode": "0x044A"
        }
      ]
    },
    {
      "iso639_1": "tg",
      "iso639_2": "tgk",
      "iso639_2en": "tgk",
      "iso639_3": "tgk",
      "name": [
        "Tajik"
      ],
      "nativeName": [
        "тоҷикӣ",
        "toğikī",
        "تاجیکی‎"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "TJK"
      ]
    },
    {
      "iso639_1": "th",
      "iso639_2": "tha",
      "iso639_2en": "tha",
      "iso639_3": "tha",
      "name": [
        "Thai"
      ],
      "nativeName": [
        "ไทย"
      ],
      "direction": "LTR",
      "family": "Tai–Kadai",
      "countries": [
        "THA"
      ],
      "langCultureMs": [
        {
          "langCultureName": "th-TH",
          "displayName": "Thai - Thailand",
          "cultureCode": "0x041E"
        }
      ]
    },
    {
      "iso639_1": "ti",
      "iso639_2": "tir",
      "iso639_2en": "tir",
      "iso639_3": "tir",
      "name": [
        "Tigrinya"
      ],
      "nativeName": [
        "ትግርኛ"
      ],
      "direction": "LTR",
      "family": "Afro-Asiatic",
      "countries": [
        "ERI"
      ]
    },
    {
      "iso639_1": "bo",
      "iso639_2": "bod",
      "iso639_2en": "tib",
      "iso639_3": "bod",
      "name": [
        "Tibetan Standard",
        "Tibetan",
        "Central"
      ],
      "nativeName": [
        "བོད་ཡིག"
      ],
      "direction": "LTR",
      "family": "Sino-Tibetan"
    },
    {
      "iso639_1": "tk",
      "iso639_2": "tuk",
      "iso639_2en": "tuk",
      "iso639_3": "tuk",
      "name": [
        "Turkmen"
      ],
      "nativeName": [
        "Türkmen",
        "Түркмен"
      ],
      "direction": "LTR",
      "family": "Turkic",
      "countries": [
        "TKM"
      ]
    },
    {
      "iso639_1": "tl",
      "iso639_2": "tgl",
      "iso639_2en": "tgl",
      "iso639_3": "tgl",
      "name": [
        "Tagalog"
      ],
      "nativeName": [
        "Wikang Tagalog"
      ],
      "direction": "LTR",
      "family": "Austronesian",
      "countries": [
        "PHL"
      ]
    },
    {
      "iso639_1": "",
      "iso639_2": "fil",
      "iso639_2en": "fil",
      "iso639_3": "fil",
      "name": [
        "Filipino"
      ],
      "nativeName": [
        "Filipino"
      ],
      "direction": "LTR",
      "family": "Austronesian",
      "countries": [
        "PHL"
      ]
    },
    {
      "iso639_1": "tn",
      "iso639_2": "tsn",
      "iso639_2en": "tsn",
      "iso639_3": "tsn",
      "name": [
        "Tswana"
      ],
      "nativeName": [
        "Setswana"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "BWA",
        "ZAF"
      ]
    },
    {
      "iso639_1": "to",
      "iso639_2": "ton",
      "iso639_2en": "ton",
      "iso639_3": "ton",
      "name": [
        "Tonga (Tonga Islands)"
      ],
      "nativeName": [
        "faka Tonga"
      ],
      "direction": "LTR",
      "family": "Austronesian"
    },
    {
      "iso639_1": "tr",
      "iso639_2": "tur",
      "iso639_2en": "tur",
      "iso639_3": "tur",
      "name": [
        "Turkish"
      ],
      "nativeName": [
        "Türkçe"
      ],
      "direction": "LTR",
      "family": "Turkic",
      "countries": [
        "TUR",
        "CYP"
      ],
      "langCultureMs": [
        {
          "langCultureName": "tr-TR",
          "displayName": "Turkish - Turkey",
          "cultureCode": "0x041F"
        }
      ]
    },
    {
      "iso639_1": "ts",
      "iso639_2": "tso",
      "iso639_2en": "tso",
      "iso639_3": "tso",
      "name": [
        "Tsonga"
      ],
      "nativeName": [
        "Xitsonga"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "ZAF"
      ]
    },
    {
      "iso639_1": "tt",
      "iso639_2": "tat",
      "iso639_2en": "tat",
      "iso639_3": "tat",
      "name": [
        "Tatar"
      ],
      "nativeName": [
        "татар теле",
        "tatar tele"
      ],
      "direction": "LTR",
      "family": "Turkic",
      "langCultureMs": [
        {
          "langCultureName": "tt-RU",
          "displayName": "Tatar - Russia",
          "cultureCode": "0x0444"
        }
      ]
    },
    {
      "iso639_1": "tw",
      "iso639_2": "twi",
      "iso639_2en": "twi",
      "iso639_3": "twi",
      "name": [
        "Twi"
      ],
      "nativeName": [
        "Twi"
      ],
      "direction": "LTR",
      "family": "Niger–Congo"
    },
    {
      "iso639_1": "ty",
      "iso639_2": "tah",
      "iso639_2en": "tah",
      "iso639_3": "tah",
      "name": [
        "Tahitian"
      ],
      "nativeName": [
        "Reo Tahiti"
      ],
      "direction": "LTR",
      "family": "Austronesian"
    },
    {
      "iso639_1": "ug",
      "iso639_2": "uig",
      "iso639_2en": "uig",
      "iso639_3": "uig",
      "name": [
        "Uyghur",
        "Uighur"
      ],
      "nativeName": [
        "Uyƣurqə",
        "ئۇيغۇرچە‎"
      ],
      "direction": "RTL",
      "family": "Turkic"
    },
    {
      "iso639_1": "uk",
      "iso639_2": "ukr",
      "iso639_2en": "ukr",
      "iso639_3": "ukr",
      "name": [
        "Ukrainian"
      ],
      "nativeName": [
        "українська мова"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "UKR"
      ],
      "langCultureMs": [
        {
          "langCultureName": "uk-UA",
          "displayName": "Ukrainian - Ukraine",
          "cultureCode": "0x0422"
        }
      ]
    },
    {
      "iso639_1": "ur",
      "iso639_2": "urd",
      "iso639_2en": "urd",
      "iso639_3": "urd",
      "name": [
        "Urdu"
      ],
      "nativeName": [
        "اردو"
      ],
      "direction": "RTL",
      "family": "Indo-European",
      "countries": [
        "PAK",
        "FJI"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ur-PK",
          "displayName": "Urdu - Pakistan",
          "cultureCode": "0x0420"
        }
      ]
    },
    {
      "iso639_1": "uz",
      "iso639_2": "uzb",
      "iso639_2en": "uzb",
      "iso639_3": "uzb",
      "name": [
        "Uzbek"
      ],
      "nativeName": [
        "O‘zbek",
        "Ўзбек",
        "أۇزبېك‎"
      ],
      "direction": "LTR",
      "family": "Turkic",
      "countries": [
        "UZB"
      ],
      "langCultureMs": [
        {
          "langCultureName": "Cy-uz-UZ",
          "displayName": "Uzbek (Cyrillic) - Uzbekistan",
          "cultureCode": "0x0843"
        },
        {
          "langCultureName": "Lt-uz-UZ",
          "displayName": "Uzbek (Latin) - Uzbekistan",
          "cultureCode": "0x0443"
        }
      ]
    },
    {
      "iso639_1": "ve",
      "iso639_2": "ven",
      "iso639_2en": "ven",
      "iso639_3": "ven",
      "name": [
        "Venda"
      ],
      "nativeName": [
        "Tshivenḓa"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "ZAF"
      ]
    },
    {
      "iso639_1": "vi",
      "iso639_2": "vie",
      "iso639_2en": "vie",
      "iso639_3": "vie",
      "name": [
        "Vietnamese"
      ],
      "nativeName": [
        "Tiếng Việt"
      ],
      "direction": "LTR",
      "family": "Austroasiatic",
      "countries": [
        "VNM"
      ],
      "langCultureMs": [
        {
          "langCultureName": "vi-VN",
          "displayName": "Vietnamese - Vietnam",
          "cultureCode": "0x042A"
        }
      ]
    },
    {
      "iso639_1": "vo",
      "iso639_2": "vol",
      "iso639_2en": "vol",
      "iso639_3": "vol",
      "name": [
        "Volapük"
      ],
      "nativeName": [
        "Volapük"
      ],
      "direction": "LTR",
      "family": "Constructed"
    },
    {
      "iso639_1": "wa",
      "iso639_2": "wln",
      "iso639_2en": "wln",
      "iso639_3": "wln",
      "name": [
        "Walloon"
      ],
      "nativeName": [
        "walon"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "cy",
      "iso639_2": "cym",
      "iso639_2en": "wel",
      "iso639_3": "cym",
      "name": [
        "Welsh"
      ],
      "nativeName": [
        "Cymraeg"
      ],
      "direction": "LTR",
      "family": "Indo-European",
      "countries": [
        "GBR"
      ]
    },
    {
      "iso639_1": "wo",
      "iso639_2": "wol",
      "iso639_2en": "wol",
      "iso639_3": "wol",
      "name": [
        "Wolof"
      ],
      "nativeName": [
        "Wollof"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "SEN"
      ]
    },
    {
      "iso639_1": "fy",
      "iso639_2": "fry",
      "iso639_2en": "fry",
      "iso639_3": "fry",
      "name": [
        "Western Frisian"
      ],
      "nativeName": [
        "Frysk"
      ],
      "direction": "LTR",
      "family": "Indo-European"
    },
    {
      "iso639_1": "xh",
      "iso639_2": "xho",
      "iso639_2en": "xho",
      "iso639_3": "xho",
      "name": [
        "Xhosa"
      ],
      "nativeName": [
        "isiXhosa"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "ZAF"
      ]
    },
    {
      "iso639_1": "yi",
      "iso639_2": "yid",
      "iso639_2en": "yid",
      "iso639_3": "yid",
      "name": [
        "Yiddish"
      ],
      "nativeName": [
        "ייִדיש"
      ],
      "direction": "RTL",
      "family": "Indo-European"
    },
    {
      "iso639_1": "yo",
      "iso639_2": "yor",
      "iso639_2en": "yor",
      "iso639_3": "yor",
      "name": [
        "Yoruba"
      ],
      "nativeName": [
        "Yorùbá"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "BEN",
        "NGA"
      ]
    },
    {
      "iso639_1": "za",
      "iso639_2": "zha",
      "iso639_2en": "zha",
      "iso639_3": "zha",
      "name": [
        "Zhuang",
        "Chuang"
      ],
      "nativeName": [
        "Saɯ cueŋƅ",
        "Saw cuengh"
      ],
      "direction": "LTR",
      "family": "Tai–Kadai"
    },
    {
      "iso639_1": "zu",
      "iso639_2": "zul",
      "iso639_2en": "zul",
      "iso639_3": "zul",
      "name": [
        "Zulu"
      ],
      "nativeName": [
        "isiZulu"
      ],
      "direction": "LTR",
      "family": "Niger–Congo",
      "countries": [
        "ZAF"
      ]
    },
    {
      "iso639_1": "",
      "iso639_2": "ace",
      "iso639_2en": "ace",
      "iso639_3": "ace",
      "name": [
        "Achinese"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ach",
      "iso639_2en": "ach",
      "iso639_3": "ach",
      "name": [
        "Acoli"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ada",
      "iso639_2en": "ada",
      "iso639_3": "ada",
      "name": [
        "Adangme"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ady",
      "iso639_2en": "ady",
      "iso639_3": "ady",
      "name": [
        "Adyghe",
        " Adygei"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "afa",
      "iso639_2en": "afa",
      "iso639_3": "afa",
      "name": [
        "Afro-Asiatic languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "afh",
      "iso639_2en": "afh",
      "iso639_3": "afh",
      "name": [
        "Afrihili"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ain",
      "iso639_2en": "ain",
      "iso639_3": "ain",
      "name": [
        "Ainu"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "akk",
      "iso639_2en": "akk",
      "iso639_3": "akk",
      "name": [
        "Akkadian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ale",
      "iso639_2en": "ale",
      "iso639_3": "ale",
      "name": [
        "Aleut"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "alg",
      "iso639_2en": "alg",
      "iso639_3": "alg",
      "name": [
        "Algonquian languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "alt",
      "iso639_2en": "alt",
      "iso639_3": "alt",
      "name": [
        "Southern Altai"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ang",
      "iso639_2en": "ang",
      "iso639_3": "ang",
      "name": [
        "English, Old (ca.450-1100)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "anp",
      "iso639_2en": "anp",
      "iso639_3": "anp",
      "name": [
        "Angika"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "apa",
      "iso639_2en": "apa",
      "iso639_3": "apa",
      "name": [
        "Apache languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "arc",
      "iso639_2en": "arc",
      "iso639_3": "arc",
      "name": [
        "Official Aramaic (700-300 BCE)",
        " Imperial Aramaic (700-300 BCE)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "RTL",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "arn",
      "iso639_2en": "arn",
      "iso639_3": "arn",
      "name": [
        "Mapudungun",
        " Mapuche"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "arp",
      "iso639_2en": "arp",
      "iso639_3": "arp",
      "name": [
        "Arapaho"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "art",
      "iso639_2en": "art",
      "iso639_3": "art",
      "name": [
        "Artificial languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "arw",
      "iso639_2en": "arw",
      "iso639_3": "arw",
      "name": [
        "Arawak"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ast",
      "iso639_2en": "ast",
      "iso639_3": "ast",
      "name": [
        "Asturian",
        " Bable",
        " Leonese",
        " Asturleonese"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ath",
      "iso639_2en": "ath",
      "iso639_3": "ath",
      "name": [
        "Athapascan languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "aus",
      "iso639_2en": "aus",
      "iso639_3": "aus",
      "name": [
        "Australian languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "awa",
      "iso639_2en": "awa",
      "iso639_3": "awa",
      "name": [
        "Awadhi"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "bad",
      "iso639_2en": "bad",
      "iso639_3": "bad",
      "name": [
        "Banda languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "bai",
      "iso639_2en": "bai",
      "iso639_3": "bai",
      "name": [
        "Bamileke languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "bal",
      "iso639_2en": "bal",
      "iso639_3": "bal",
      "name": [
        "Baluchi",
        "Balochi"
      ],
      "nativeName": [
        ""
      ],
      "direction": "RTL",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ban",
      "iso639_2en": "ban",
      "iso639_3": "ban",
      "name": [
        "Balinese"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "bas",
      "iso639_2en": "bas",
      "iso639_3": "bas",
      "name": [
        "Basa"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "bat",
      "iso639_2en": "bat",
      "iso639_3": "bat",
      "name": [
        "Baltic languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "bej",
      "iso639_2en": "bej",
      "iso639_3": "bej",
      "name": [
        "Beja",
        " Bedawiyet"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "bem",
      "iso639_2en": "bem",
      "iso639_3": "bem",
      "name": [
        "Bemba"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "bho",
      "iso639_2en": "bho",
      "iso639_3": "bho",
      "name": [
        "Bhojpuri"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "bik",
      "iso639_2en": "bik",
      "iso639_3": "bik",
      "name": [
        "Bikol"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "bin",
      "iso639_2en": "bin",
      "iso639_3": "bin",
      "name": [
        "Bini",
        " Edo"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "bla",
      "iso639_2en": "bla",
      "iso639_3": "bla",
      "name": [
        "Siksika"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "bnt",
      "iso639_2en": "bnt",
      "iso639_3": "bnt",
      "name": [
        "Bantu languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "bra",
      "iso639_2en": "bra",
      "iso639_3": "bra",
      "name": [
        "Braj"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "btk",
      "iso639_2en": "btk",
      "iso639_3": "btk",
      "name": [
        "Batak languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "bua",
      "iso639_2en": "bua",
      "iso639_3": "bua",
      "name": [
        "Buriat"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "bug",
      "iso639_2en": "bug",
      "iso639_3": "bug",
      "name": [
        "Buginese"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "byn",
      "iso639_2en": "byn",
      "iso639_3": "byn",
      "name": [
        "Blin",
        " Bilin"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "cad",
      "iso639_2en": "cad",
      "iso639_3": "cad",
      "name": [
        "Caddo"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "cai",
      "iso639_2en": "cai",
      "iso639_3": "cai",
      "name": [
        "Central American Indian languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "car",
      "iso639_2en": "car",
      "iso639_3": "car",
      "name": [
        "Galibi Carib"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "cau",
      "iso639_2en": "cau",
      "iso639_3": "cau",
      "name": [
        "Caucasian languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ceb",
      "iso639_2en": "ceb",
      "iso639_3": "ceb",
      "name": [
        "Cebuano"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "cel",
      "iso639_2en": "cel",
      "iso639_3": "cel",
      "name": [
        "Celtic languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "chb",
      "iso639_2en": "chb",
      "iso639_3": "chb",
      "name": [
        "Chibcha"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "chg",
      "iso639_2en": "chg",
      "iso639_3": "chg",
      "name": [
        "Chagatai"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "chk",
      "iso639_2en": "chk",
      "iso639_3": "chk",
      "name": [
        "Chuukese"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "chm",
      "iso639_2en": "chm",
      "iso639_3": "chm",
      "name": [
        "Mari"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "chn",
      "iso639_2en": "chn",
      "iso639_3": "chn",
      "name": [
        "Chinook jargon"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "cho",
      "iso639_2en": "cho",
      "iso639_3": "cho",
      "name": [
        "Choctaw"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "chp",
      "iso639_2en": "chp",
      "iso639_3": "chp",
      "name": [
        "Chipewyan",
        " Dene Suline"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "chr",
      "iso639_2en": "chr",
      "iso639_3": "chr",
      "name": [
        "Cherokee"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "chy",
      "iso639_2en": "chy",
      "iso639_3": "chy",
      "name": [
        "Cheyenne"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "cmc",
      "iso639_2en": "cmc",
      "iso639_3": "cmc",
      "name": [
        "Chamic languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "cop",
      "iso639_2en": "cop",
      "iso639_3": "cop",
      "name": [
        "Coptic"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "cpe",
      "iso639_2en": "cpe",
      "iso639_3": "cpe",
      "name": [
        "Creoles and pidgins, English based"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "cpf",
      "iso639_2en": "cpf",
      "iso639_3": "cpf",
      "name": [
        "Creoles and pidgins, French-based"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "cpp",
      "iso639_2en": "cpp",
      "iso639_3": "cpp",
      "name": [
        "Creoles and pidgins, Portuguese-based"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "crh",
      "iso639_2en": "crh",
      "iso639_3": "crh",
      "name": [
        "Crimean Tatar",
        " Crimean Turkish"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "crp",
      "iso639_2en": "crp",
      "iso639_3": "crp",
      "name": [
        "Creoles and pidgins"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "csb",
      "iso639_2en": "csb",
      "iso639_3": "csb",
      "name": [
        "Kashubian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "cus",
      "iso639_2en": "cus",
      "iso639_3": "cus",
      "name": [
        "Cushitic languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "dak",
      "iso639_2en": "dak",
      "iso639_3": "dak",
      "name": [
        "Dakota"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "dar",
      "iso639_2en": "dar",
      "iso639_3": "dar",
      "name": [
        "Dargwa"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "day",
      "iso639_2en": "day",
      "iso639_3": "day",
      "name": [
        "Land Dayak languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "del",
      "iso639_2en": "del",
      "iso639_3": "del",
      "name": [
        "Delaware"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "den",
      "iso639_2en": "den",
      "iso639_3": "den",
      "name": [
        "Slave (Athapascan)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "dgr",
      "iso639_2en": "dgr",
      "iso639_3": "dgr",
      "name": [
        "Dogrib"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "din",
      "iso639_2en": "din",
      "iso639_3": "din",
      "name": [
        "Dinka"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "doi",
      "iso639_2en": "doi",
      "iso639_3": "doi",
      "name": [
        "Dogri"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "dra",
      "iso639_2en": "dra",
      "iso639_3": "dra",
      "name": [
        "Dravidian languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "dsb",
      "iso639_2en": "dsb",
      "iso639_3": "dsb",
      "name": [
        "Lower Sorbian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "dua",
      "iso639_2en": "dua",
      "iso639_3": "dua",
      "name": [
        "Duala"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "dum",
      "iso639_2en": "dum",
      "iso639_3": "dum",
      "name": [
        "Dutch, Middle (ca.1050-1350)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "dyu",
      "iso639_2en": "dyu",
      "iso639_3": "dyu",
      "name": [
        "Dyula"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "efi",
      "iso639_2en": "efi",
      "iso639_3": "efi",
      "name": [
        "Efik"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "egy",
      "iso639_2en": "egy",
      "iso639_3": "egy",
      "name": [
        "Egyptian (Ancient)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "eka",
      "iso639_2en": "eka",
      "iso639_3": "eka",
      "name": [
        "Ekajuk"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "elx",
      "iso639_2en": "elx",
      "iso639_3": "elx",
      "name": [
        "Elamite"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "enm",
      "iso639_2en": "enm",
      "iso639_3": "enm",
      "name": [
        "English, Middle (1100-1500)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ewo",
      "iso639_2en": "ewo",
      "iso639_3": "ewo",
      "name": [
        "Ewondo"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "fan",
      "iso639_2en": "fan",
      "iso639_3": "fan",
      "name": [
        "Fang"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "fat",
      "iso639_2en": "fat",
      "iso639_3": "fat",
      "name": [
        "Fanti"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "fiu",
      "iso639_2en": "fiu",
      "iso639_3": "fiu",
      "name": [
        "Finno-Ugrian languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "fon",
      "iso639_2en": "fon",
      "iso639_3": "fon",
      "name": [
        "Fon"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "frm",
      "iso639_2en": "frm",
      "iso639_3": "frm",
      "name": [
        "French, Middle (ca.1400-1600)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "fro",
      "iso639_2en": "fro",
      "iso639_3": "fro",
      "name": [
        "French, Old (842-ca.1400)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "frr",
      "iso639_2en": "frr",
      "iso639_3": "frr",
      "name": [
        "Northern Frisian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "frs",
      "iso639_2en": "frs",
      "iso639_3": "frs",
      "name": [
        "Eastern Frisian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "fur",
      "iso639_2en": "fur",
      "iso639_3": "fur",
      "name": [
        "Friulian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "gaa",
      "iso639_2en": "gaa",
      "iso639_3": "gaa",
      "name": [
        "Ga"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": "",
      "countries": [
        "GHA"
      ]
    },
    {
      "iso639_1": "",
      "iso639_2": "gay",
      "iso639_2en": "gay",
      "iso639_3": "gay",
      "name": [
        "Gayo"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "gba",
      "iso639_2en": "gba",
      "iso639_3": "gba",
      "name": [
        "Gbaya"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "gem",
      "iso639_2en": "gem",
      "iso639_3": "gem",
      "name": [
        "Germanic languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "gez",
      "iso639_2en": "gez",
      "iso639_3": "gez",
      "name": [
        "Geez"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "gil",
      "iso639_2en": "gil",
      "iso639_3": "gil",
      "name": [
        "Gilbertese"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "gmh",
      "iso639_2en": "gmh",
      "iso639_3": "gmh",
      "name": [
        "German, Middle High (ca.1050-1500)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "goh",
      "iso639_2en": "goh",
      "iso639_3": "goh",
      "name": [
        "German, Old High (ca.750-1050)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "gon",
      "iso639_2en": "gon",
      "iso639_3": "gon",
      "name": [
        "Gondi"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "gor",
      "iso639_2en": "gor",
      "iso639_3": "gor",
      "name": [
        "Gorontalo"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "got",
      "iso639_2en": "got",
      "iso639_3": "got",
      "name": [
        "Gothic"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "grb",
      "iso639_2en": "grb",
      "iso639_3": "grb",
      "name": [
        "Grebo"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "grc",
      "iso639_2en": "grc",
      "iso639_3": "grc",
      "name": [
        "Greek, Ancient (to 1453)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "gsw",
      "iso639_2en": "gsw",
      "iso639_3": "gsw",
      "name": [
        "Swiss German",
        " Alemannic",
        " Alsatian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "gwi",
      "iso639_2en": "gwi",
      "iso639_3": "gwi",
      "name": [
        "Gwich'in"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "hai",
      "iso639_2en": "hai",
      "iso639_3": "hai",
      "name": [
        "Haida"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "haw",
      "iso639_2en": "haw",
      "iso639_3": "haw",
      "name": [
        "Hawaiian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "hil",
      "iso639_2en": "hil",
      "iso639_3": "hil",
      "name": [
        "Hiligaynon"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "him",
      "iso639_2en": "him",
      "iso639_3": "him",
      "name": [
        "Himachali languages",
        " Western Pahari languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "hit",
      "iso639_2en": "hit",
      "iso639_3": "hit",
      "name": [
        "Hittite"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "hmn",
      "iso639_2en": "hmn",
      "iso639_3": "hmn",
      "name": [
        "Hmong",
        " Mong"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "hsb",
      "iso639_2en": "hsb",
      "iso639_3": "hsb",
      "name": [
        "Upper Sorbian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "hup",
      "iso639_2en": "hup",
      "iso639_3": "hup",
      "name": [
        "Hupa"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "iba",
      "iso639_2en": "iba",
      "iso639_3": "iba",
      "name": [
        "Iban"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ijo",
      "iso639_2en": "ijo",
      "iso639_3": "ijo",
      "name": [
        "Ijo languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ilo",
      "iso639_2en": "ilo",
      "iso639_3": "ilo",
      "name": [
        "Iloko"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "inc",
      "iso639_2en": "inc",
      "iso639_3": "inc",
      "name": [
        "Indic languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ine",
      "iso639_2en": "ine",
      "iso639_3": "ine",
      "name": [
        "Indo-European languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "inh",
      "iso639_2en": "inh",
      "iso639_3": "inh",
      "name": [
        "Ingush"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ira",
      "iso639_2en": "ira",
      "iso639_3": "ira",
      "name": [
        "Iranian languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "iro",
      "iso639_2en": "iro",
      "iso639_3": "iro",
      "name": [
        "Iroquoian languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "jbo",
      "iso639_2en": "jbo",
      "iso639_3": "jbo",
      "name": [
        "Lojban"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "jpr",
      "iso639_2en": "jpr",
      "iso639_3": "jpr",
      "name": [
        "Judeo-Persian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "jrb",
      "iso639_2en": "jrb",
      "iso639_3": "jrb",
      "name": [
        "Judeo-Arabic"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "kaa",
      "iso639_2en": "kaa",
      "iso639_3": "kaa",
      "name": [
        "Kara-Kalpak"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "kab",
      "iso639_2en": "kab",
      "iso639_3": "kab",
      "name": [
        "Kabyle"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "kac",
      "iso639_2en": "kac",
      "iso639_3": "kac",
      "name": [
        "Kachin",
        " Jingpho"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "kam",
      "iso639_2en": "kam",
      "iso639_3": "kam",
      "name": [
        "Kamba"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "kar",
      "iso639_2en": "kar",
      "iso639_3": "kar",
      "name": [
        "Karen languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "kaw",
      "iso639_2en": "kaw",
      "iso639_3": "kaw",
      "name": [
        "Kawi"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "kbd",
      "iso639_2en": "kbd",
      "iso639_3": "kbd",
      "name": [
        "Kabardian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "kha",
      "iso639_2en": "kha",
      "iso639_3": "kha",
      "name": [
        "Khasi"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "khi",
      "iso639_2en": "khi",
      "iso639_3": "khi",
      "name": [
        "Khoisan languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "kho",
      "iso639_2en": "kho",
      "iso639_3": "kho",
      "name": [
        "Khotanese",
        " Sakan"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "kmb",
      "iso639_2en": "kmb",
      "iso639_3": "kmb",
      "name": [
        "Kimbundu"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": "",
      "countries": [
        "AGO"
      ]
    },
    {
      "iso639_1": "",
      "iso639_2": "kok",
      "iso639_2en": "kok",
      "iso639_3": "kok",
      "name": [
        "Konkani"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": "",
      "langCultureMs": [
        {
          "langCultureName": "kok-IN",
          "displayName": "Konkani - India",
          "cultureCode": "0x0457"
        }
      ]
    },
    {
      "iso639_1": "",
      "iso639_2": "kos",
      "iso639_2en": "kos",
      "iso639_3": "kos",
      "name": [
        "Kosraean"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "kpe",
      "iso639_2en": "kpe",
      "iso639_3": "kpe",
      "name": [
        "Kpelle"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "krc",
      "iso639_2en": "krc",
      "iso639_3": "krc",
      "name": [
        "Karachay-Balkar"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "krl",
      "iso639_2en": "krl",
      "iso639_3": "krl",
      "name": [
        "Karelian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "kro",
      "iso639_2en": "kro",
      "iso639_3": "kro",
      "name": [
        "Kru languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "kru",
      "iso639_2en": "kru",
      "iso639_3": "kru",
      "name": [
        "Kurukh"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "kum",
      "iso639_2en": "kum",
      "iso639_3": "kum",
      "name": [
        "Kumyk"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "kut",
      "iso639_2en": "kut",
      "iso639_3": "kut",
      "name": [
        "Kutenai"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "lad",
      "iso639_2en": "lad",
      "iso639_3": "lad",
      "name": [
        "Ladino"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "lah",
      "iso639_2en": "lah",
      "iso639_3": "lah",
      "name": [
        "Lahnda"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "lam",
      "iso639_2en": "lam",
      "iso639_3": "lam",
      "name": [
        "Lamba"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "lez",
      "iso639_2en": "lez",
      "iso639_3": "lez",
      "name": [
        "Lezghian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "lol",
      "iso639_2en": "lol",
      "iso639_3": "lol",
      "name": [
        "Mongo"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "loz",
      "iso639_2en": "loz",
      "iso639_3": "loz",
      "name": [
        "Lozi"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "lua",
      "iso639_2en": "lua",
      "iso639_3": "lua",
      "name": [
        "Luba-Lulua"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "lui",
      "iso639_2en": "lui",
      "iso639_3": "lui",
      "name": [
        "Luiseno"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "lun",
      "iso639_2en": "lun",
      "iso639_3": "lun",
      "name": [
        "Lunda"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "luo",
      "iso639_2en": "luo",
      "iso639_3": "luo",
      "name": [
        "Luo (Kenya and Tanzania)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "lus",
      "iso639_2en": "lus",
      "iso639_3": "lus",
      "name": [
        "Lushai"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mad",
      "iso639_2en": "mad",
      "iso639_3": "mad",
      "name": [
        "Madurese"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mag",
      "iso639_2en": "mag",
      "iso639_3": "mag",
      "name": [
        "Magahi"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mai",
      "iso639_2en": "mai",
      "iso639_3": "mai",
      "name": [
        "Maithili"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mak",
      "iso639_2en": "mak",
      "iso639_3": "mak",
      "name": [
        "Makasar"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "man",
      "iso639_2en": "man",
      "iso639_3": "man",
      "name": [
        "Mandingo"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "map",
      "iso639_2en": "map",
      "iso639_3": "map",
      "name": [
        "Austronesian languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mas",
      "iso639_2en": "mas",
      "iso639_3": "mas",
      "name": [
        "Masai"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mdf",
      "iso639_2en": "mdf",
      "iso639_3": "mdf",
      "name": [
        "Moksha"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mdr",
      "iso639_2en": "mdr",
      "iso639_3": "mdr",
      "name": [
        "Mandar"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "men",
      "iso639_2en": "men",
      "iso639_3": "men",
      "name": [
        "Mende"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mga",
      "iso639_2en": "mga",
      "iso639_3": "mga",
      "name": [
        "Irish, Middle (900-1200)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mic",
      "iso639_2en": "mic",
      "iso639_3": "mic",
      "name": [
        "Mi'kmaq",
        " Micmac"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "min",
      "iso639_2en": "min",
      "iso639_3": "min",
      "name": [
        "Minangkabau"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mis",
      "iso639_2en": "mis",
      "iso639_3": "mis",
      "name": [
        "Uncoded languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mkh",
      "iso639_2en": "mkh",
      "iso639_3": "mkh",
      "name": [
        "Mon-Khmer languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mnc",
      "iso639_2en": "mnc",
      "iso639_3": "mnc",
      "name": [
        "Manchu"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mni",
      "iso639_2en": "mni",
      "iso639_3": "mni",
      "name": [
        "Manipuri"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mno",
      "iso639_2en": "mno",
      "iso639_3": "mno",
      "name": [
        "Manobo languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "moh",
      "iso639_2en": "moh",
      "iso639_3": "moh",
      "name": [
        "Mohawk"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mos",
      "iso639_2en": "mos",
      "iso639_3": "mos",
      "name": [
        "Mossi"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": "",
      "countries": [
        "BFA"
      ]
    },
    {
      "iso639_1": "",
      "iso639_2": "mul",
      "iso639_2en": "mul",
      "iso639_3": "mul",
      "name": [
        "Multiple languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mun",
      "iso639_2en": "mun",
      "iso639_3": "mun",
      "name": [
        "Munda languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mus",
      "iso639_2en": "mus",
      "iso639_3": "mus",
      "name": [
        "Creek"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mwl",
      "iso639_2en": "mwl",
      "iso639_3": "mwl",
      "name": [
        "Mirandese"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "mwr",
      "iso639_2en": "mwr",
      "iso639_3": "mwr",
      "name": [
        "Marwari"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "myn",
      "iso639_2en": "myn",
      "iso639_3": "myn",
      "name": [
        "Mayan languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "myv",
      "iso639_2en": "myv",
      "iso639_3": "myv",
      "name": [
        "Erzya"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "nah",
      "iso639_2en": "nah",
      "iso639_3": "nah",
      "name": [
        "Nahuatl languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "nai",
      "iso639_2en": "nai",
      "iso639_3": "nai",
      "name": [
        "North American Indian languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "nap",
      "iso639_2en": "nap",
      "iso639_3": "nap",
      "name": [
        "Neapolitan"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "nds",
      "iso639_2en": "nds",
      "iso639_3": "nds",
      "name": [
        "Low German",
        " Low Saxon",
        " German, Low",
        " Saxon, Low"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "new",
      "iso639_2en": "new",
      "iso639_3": "new",
      "name": [
        "Nepal Bhasa",
        " Newari"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "nia",
      "iso639_2en": "nia",
      "iso639_3": "nia",
      "name": [
        "Nias"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "nic",
      "iso639_2en": "nic",
      "iso639_3": "nic",
      "name": [
        "Niger-Kordofanian languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "niu",
      "iso639_2en": "niu",
      "iso639_3": "niu",
      "name": [
        "Niuean"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "nog",
      "iso639_2en": "nog",
      "iso639_3": "nog",
      "name": [
        "Nogai"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "non",
      "iso639_2en": "non",
      "iso639_3": "non",
      "name": [
        "Norse, Old"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "nqo",
      "iso639_2en": "nqo",
      "iso639_3": "nqo",
      "name": [
        "N'Ko"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "nub",
      "iso639_2en": "nub",
      "iso639_3": "nub",
      "name": [
        "Nubian languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "nwc",
      "iso639_2en": "nwc",
      "iso639_3": "nwc",
      "name": [
        "Classical Newari",
        " Old Newari",
        " Classical Nepal Bhasa"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "nym",
      "iso639_2en": "nym",
      "iso639_3": "nym",
      "name": [
        "Nyamwezi"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "nyn",
      "iso639_2en": "nyn",
      "iso639_3": "nyn",
      "name": [
        "Nyankole"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "nyo",
      "iso639_2en": "nyo",
      "iso639_3": "nyo",
      "name": [
        "Nyoro"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "nzi",
      "iso639_2en": "nzi",
      "iso639_3": "nzi",
      "name": [
        "Nzima"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "osa",
      "iso639_2en": "osa",
      "iso639_3": "osa",
      "name": [
        "Osage"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ota",
      "iso639_2en": "ota",
      "iso639_3": "ota",
      "name": [
        "Turkish, Ottoman (1500-1928)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "oto",
      "iso639_2en": "oto",
      "iso639_3": "oto",
      "name": [
        "Otomian languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "paa",
      "iso639_2en": "paa",
      "iso639_3": "paa",
      "name": [
        "Papuan languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "pag",
      "iso639_2en": "pag",
      "iso639_3": "pag",
      "name": [
        "Pangasinan"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "pal",
      "iso639_2en": "pal",
      "iso639_3": "pal",
      "name": [
        "Pahlavi"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "pam",
      "iso639_2en": "pam",
      "iso639_3": "pam",
      "name": [
        "Pampanga",
        " Kapampangan"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "pap",
      "iso639_2en": "pap",
      "iso639_3": "pap",
      "name": [
        "Papiamento"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": "",
      "countries": [
        "ABW",
        "CUW"
      ]
    },
    {
      "iso639_1": "",
      "iso639_2": "pau",
      "iso639_2en": "pau",
      "iso639_3": "pau",
      "name": [
        "Palauan"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "peo",
      "iso639_2en": "peo",
      "iso639_3": "peo",
      "name": [
        "Persian, Old (ca.600-400 B.C.)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "phi",
      "iso639_2en": "phi",
      "iso639_3": "phi",
      "name": [
        "Philippine languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "phn",
      "iso639_2en": "phn",
      "iso639_3": "phn",
      "name": [
        "Phoenician"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "pon",
      "iso639_2en": "pon",
      "iso639_3": "pon",
      "name": [
        "Pohnpeian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "pra",
      "iso639_2en": "pra",
      "iso639_3": "pra",
      "name": [
        "Prakrit languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "pro",
      "iso639_2en": "pro",
      "iso639_3": "pro",
      "name": [
        "Provençal, Old (to 1500)",
        "Occitan, Old (to 1500)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "qaa-qtz",
      "iso639_2en": "qaa-qtz",
      "iso639_3": "qaa-qtz",
      "name": [
        "Reserved for local use"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "raj",
      "iso639_2en": "raj",
      "iso639_3": "raj",
      "name": [
        "Rajasthani"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "rap",
      "iso639_2en": "rap",
      "iso639_3": "rap",
      "name": [
        "Rapanui"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "rar",
      "iso639_2en": "rar",
      "iso639_3": "rar",
      "name": [
        "Rarotongan",
        " Cook Islands Maori"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "roa",
      "iso639_2en": "roa",
      "iso639_3": "roa",
      "name": [
        "Romance languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "rom",
      "iso639_2en": "rom",
      "iso639_3": "rom",
      "name": [
        "Romany"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "rup",
      "iso639_2en": "rup",
      "iso639_3": "rup",
      "name": [
        "Aromanian",
        " Arumanian",
        " Macedo-Romanian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sad",
      "iso639_2en": "sad",
      "iso639_3": "sad",
      "name": [
        "Sandawe"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sah",
      "iso639_2en": "sah",
      "iso639_3": "sah",
      "name": [
        "Yakut"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sai",
      "iso639_2en": "sai",
      "iso639_3": "sai",
      "name": [
        "South American Indian languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sal",
      "iso639_2en": "sal",
      "iso639_3": "sal",
      "name": [
        "Salishan languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sam",
      "iso639_2en": "sam",
      "iso639_3": "sam",
      "name": [
        "Samaritan Aramaic"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sas",
      "iso639_2en": "sas",
      "iso639_3": "sas",
      "name": [
        "Sasak"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sat",
      "iso639_2en": "sat",
      "iso639_3": "sat",
      "name": [
        "Santali"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "scn",
      "iso639_2en": "scn",
      "iso639_3": "scn",
      "name": [
        "Sicilian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sco",
      "iso639_2en": "sco",
      "iso639_3": "sco",
      "name": [
        "Scots"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sel",
      "iso639_2en": "sel",
      "iso639_3": "sel",
      "name": [
        "Selkup"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sem",
      "iso639_2en": "sem",
      "iso639_3": "sem",
      "name": [
        "Semitic languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sga",
      "iso639_2en": "sga",
      "iso639_3": "sga",
      "name": [
        "Irish, Old (to 900)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sgn",
      "iso639_2en": "sgn",
      "iso639_3": "sgn",
      "name": [
        "Sign Languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "shn",
      "iso639_2en": "shn",
      "iso639_3": "shn",
      "name": [
        "Shan"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sid",
      "iso639_2en": "sid",
      "iso639_3": "sid",
      "name": [
        "Sidamo"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sio",
      "iso639_2en": "sio",
      "iso639_3": "sio",
      "name": [
        "Siouan languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sit",
      "iso639_2en": "sit",
      "iso639_3": "sit",
      "name": [
        "Sino-Tibetan languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sla",
      "iso639_2en": "sla",
      "iso639_3": "sla",
      "name": [
        "Slavic languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sma",
      "iso639_2en": "sma",
      "iso639_3": "sma",
      "name": [
        "Southern Sami"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "smi",
      "iso639_2en": "smi",
      "iso639_3": "smi",
      "name": [
        "Sami languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "smj",
      "iso639_2en": "smj",
      "iso639_3": "smj",
      "name": [
        "Lule Sami"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "smn",
      "iso639_2en": "smn",
      "iso639_3": "smn",
      "name": [
        "Inari Sami"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sms",
      "iso639_2en": "sms",
      "iso639_3": "sms",
      "name": [
        "Skolt Sami"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "snk",
      "iso639_2en": "snk",
      "iso639_3": "snk",
      "name": [
        "Soninke"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": "",
      "countries": [
        "MLI",
        "SEN"
      ]
    },
    {
      "iso639_1": "",
      "iso639_2": "sog",
      "iso639_2en": "sog",
      "iso639_3": "sog",
      "name": [
        "Sogdian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "son",
      "iso639_2en": "son",
      "iso639_3": "son",
      "name": [
        "Songhai languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "srn",
      "iso639_2en": "srn",
      "iso639_3": "srn",
      "name": [
        "Sranan Tongo"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "srr",
      "iso639_2en": "srr",
      "iso639_3": "srr",
      "name": [
        "Serer"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": "",
      "countries": [
        "SEN"
      ]
    },
    {
      "iso639_1": "",
      "iso639_2": "ssa",
      "iso639_2en": "ssa",
      "iso639_3": "ssa",
      "name": [
        "Nilo-Saharan languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "suk",
      "iso639_2en": "suk",
      "iso639_3": "suk",
      "name": [
        "Sukuma"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sus",
      "iso639_2en": "sus",
      "iso639_3": "sus",
      "name": [
        "Susu"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "sux",
      "iso639_2en": "sux",
      "iso639_3": "sux",
      "name": [
        "Sumerian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "syc",
      "iso639_2en": "syc",
      "iso639_3": "syc",
      "name": [
        "Classical Syriac"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "syr",
      "iso639_2en": "syr",
      "iso639_3": "syr",
      "name": [
        "Syriac"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": "",
      "langCultureMs": [
        {
          "langCultureName": "syr-SY",
          "displayName": "Syriac - Syria",
          "cultureCode": "0x045A"
        }
      ]
    },
    {
      "iso639_1": "",
      "iso639_2": "tai",
      "iso639_2en": "tai",
      "iso639_3": "tai",
      "name": [
        "Tai languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "tem",
      "iso639_2en": "tem",
      "iso639_3": "tem",
      "name": [
        "Timne"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ter",
      "iso639_2en": "ter",
      "iso639_3": "ter",
      "name": [
        "Tereno"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "tet",
      "iso639_2en": "tet",
      "iso639_3": "tet",
      "name": [
        "Tetum"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": "",
      "countries": [
        "TLS"
      ]
    },
    {
      "iso639_1": "",
      "iso639_2": "tig",
      "iso639_2en": "tig",
      "iso639_3": "tig",
      "name": [
        "Tigre"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "tiv",
      "iso639_2en": "tiv",
      "iso639_3": "tiv",
      "name": [
        "Tiv"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "tkl",
      "iso639_2en": "tkl",
      "iso639_3": "tkl",
      "name": [
        "Tokelau"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "tlh",
      "iso639_2en": "tlh",
      "iso639_3": "tlh",
      "name": [
        "Klingon",
        " tlhIngan-Hol"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "tli",
      "iso639_2en": "tli",
      "iso639_3": "tli",
      "name": [
        "Tlingit"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "tmh",
      "iso639_2en": "tmh",
      "iso639_3": "tmh",
      "name": [
        "Tamashek"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "tog",
      "iso639_2en": "tog",
      "iso639_3": "tog",
      "name": [
        "Tonga (Nyasa)"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "tpi",
      "iso639_2en": "tpi",
      "iso639_3": "tpi",
      "name": [
        "Tok Pisin"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": "",
      "countries": [
        "PNG"
      ]
    },
    {
      "iso639_1": "",
      "iso639_2": "tsi",
      "iso639_2en": "tsi",
      "iso639_3": "tsi",
      "name": [
        "Tsimshian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "tum",
      "iso639_2en": "tum",
      "iso639_3": "tum",
      "name": [
        "Tumbuka"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "tup",
      "iso639_2en": "tup",
      "iso639_3": "tup",
      "name": [
        "Tupi languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "tut",
      "iso639_2en": "tut",
      "iso639_3": "tut",
      "name": [
        "Altaic languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "tvl",
      "iso639_2en": "tvl",
      "iso639_3": "tvl",
      "name": [
        "Tuvalu"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "tyv",
      "iso639_2en": "tyv",
      "iso639_3": "tyv",
      "name": [
        "Tuvinian"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "udm",
      "iso639_2en": "udm",
      "iso639_3": "udm",
      "name": [
        "Udmurt"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "uga",
      "iso639_2en": "uga",
      "iso639_3": "uga",
      "name": [
        "Ugaritic"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "umb",
      "iso639_2en": "umb",
      "iso639_3": "umb",
      "name": [
        "Umbundu"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": "",
      "countries": [
        "AGO"
      ]
    },
    {
      "iso639_1": "",
      "iso639_2": "und",
      "iso639_2en": "und",
      "iso639_3": "und",
      "name": [
        "Undetermined"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "vai",
      "iso639_2en": "vai",
      "iso639_3": "vai",
      "name": [
        "Vai"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "vot",
      "iso639_2en": "vot",
      "iso639_3": "vot",
      "name": [
        "Votic"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "wak",
      "iso639_2en": "wak",
      "iso639_3": "wak",
      "name": [
        "Wakashan languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "wal",
      "iso639_2en": "wal",
      "iso639_3": "wal",
      "name": [
        "Wolaitta",
        " Wolaytta"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "war",
      "iso639_2en": "war",
      "iso639_3": "war",
      "name": [
        "Waray"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "was",
      "iso639_2en": "was",
      "iso639_3": "was",
      "name": [
        "Washo"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "wen",
      "iso639_2en": "wen",
      "iso639_3": "wen",
      "name": [
        "Sorbian languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "xal",
      "iso639_2en": "xal",
      "iso639_3": "xal",
      "name": [
        "Kalmyk",
        " Oirat"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "yao",
      "iso639_2en": "yao",
      "iso639_3": "yao",
      "name": [
        "Yao"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "yap",
      "iso639_2en": "yap",
      "iso639_3": "yap",
      "name": [
        "Yapese"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "ypk",
      "iso639_2en": "ypk",
      "iso639_3": "ypk",
      "name": [
        "Yupik languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "zap",
      "iso639_2en": "zap",
      "iso639_3": "zap",
      "name": [
        "Zapotec"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "zbl",
      "iso639_2en": "zbl",
      "iso639_3": "zbl",
      "name": [
        "Blissymbols",
        " Blissymbolics",
        " Bliss"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "zen",
      "iso639_2en": "zen",
      "iso639_3": "zen",
      "name": [
        "Zenaga"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "zgh",
      "iso639_2en": "zgh",
      "iso639_3": "zgh",
      "name": [
        "Standard Moroccan Tamazight"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "znd",
      "iso639_2en": "znd",
      "iso639_3": "znd",
      "name": [
        "Zande languages"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "zun",
      "iso639_2en": "zun",
      "iso639_3": "zun",
      "name": [
        "Zuni"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "zxx",
      "iso639_2en": "zxx",
      "iso639_3": "zxx",
      "name": [
        "No linguistic content",
        " Not applicable"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    },
    {
      "iso639_1": "",
      "iso639_2": "zza",
      "iso639_2en": "zza",
      "iso639_3": "zza",
      "name": [
        "Zaza",
        " Dimili",
        " Dimli",
        " Kirdki",
        " Kirmanjki",
        " Zazaki"
      ],
      "nativeName": [
        ""
      ],
      "direction": "LTR",
      "family": ""
    }
  ],
  "countries": [
    {
      "code_2": "AF",
      "code_3": "AFG",
      "numCode": "004",
      "name": "Afghanistan",
      "languages": [
        "prs",
        "pus",
        "fas"
      ]
    },
    {
      "code_2": "AX",
      "code_3": "ALA",
      "numCode": "248",
      "name": "Åland Islands",
      "languages": [
        "swe"
      ]
    },
    {
      "code_2": "AL",
      "code_3": "ALB",
      "numCode": "008",
      "name": "Albania",
      "languages": [
        "sqi"
      ],
      "langCultureMs": [
        {
          "langCultureName": "sq-AL",
          "displayName": "Albanian - Albania",
          "cultureCode": "0x041C"
        }
      ]
    },
    {
      "code_2": "DZ",
      "code_3": "DZA",
      "numCode": "012",
      "name": "Algeria",
      "languages": [
        "ara",
        "ber"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-DZ",
          "displayName": "Arabic - Algeria",
          "cultureCode": "0x1401"
        }
      ]
    },
    {
      "code_2": "AS",
      "code_3": "ASM",
      "numCode": "016",
      "name": "American Samoa"
    },
    {
      "code_2": "AD",
      "code_3": "AND",
      "numCode": "020",
      "name": "Andorra",
      "languages": [
        "cat"
      ]
    },
    {
      "code_2": "AO",
      "code_3": "AGO",
      "numCode": "024",
      "name": "Angola",
      "languages": [
        "kon",
        "kmb",
        "kua",
        "por",
        "umb"
      ]
    },
    {
      "code_2": "AI",
      "code_3": "AIA",
      "numCode": "660",
      "name": "Anguilla"
    },
    {
      "code_2": "AQ",
      "code_3": "ATA",
      "numCode": "010",
      "name": "Antarctica"
    },
    {
      "code_2": "AG",
      "code_3": "ATG",
      "numCode": "028",
      "name": "Antigua and Barbuda",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "AR",
      "code_3": "ARG",
      "numCode": "032",
      "name": "Argentina",
      "languages": [
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-AR",
          "displayName": "Spanish - Argentina",
          "cultureCode": "0x2C0A"
        }
      ]
    },
    {
      "code_2": "AM",
      "code_3": "ARM",
      "numCode": "051",
      "name": "Armenia",
      "languages": [
        "hye"
      ],
      "langCultureMs": [
        {
          "langCultureName": "hy-AM",
          "displayName": "Armenian - Armenia",
          "cultureCode": "0x042B"
        }
      ]
    },
    {
      "code_2": "AW",
      "code_3": "ABW",
      "numCode": "533",
      "name": "Aruba",
      "languages": [
        "nld",
        "pap"
      ]
    },
    {
      "code_2": "AU",
      "code_3": "AUS",
      "numCode": "036",
      "name": "Australia",
      "languages": [
        "eng"
      ],
      "langCultureMs": [
        {
          "langCultureName": "en-AU",
          "displayName": "English - Australia",
          "cultureCode": "0x0C09"
        }
      ]
    },
    {
      "code_2": "AT",
      "code_3": "AUT",
      "numCode": "040",
      "name": "Austria",
      "languages": [
        "deu"
      ],
      "langCultureMs": [
        {
          "langCultureName": "de-AT",
          "displayName": "German - Austria",
          "cultureCode": "0x0C07"
        }
      ]
    },
    {
      "code_2": "AZ",
      "code_3": "AZE",
      "numCode": "031",
      "name": "Azerbaijan",
      "languages": [
        "aze"
      ],
      "langCultureMs": [
        {
          "langCultureName": "Cy-az-AZ",
          "displayName": "Azeri (Cyrillic) - Azerbaijan",
          "cultureCode": "0x082C"
        },
        {
          "langCultureName": "Lt-az-AZ",
          "displayName": "Azeri (Latin) - Azerbaijan",
          "cultureCode": "0x042C"
        }
      ]
    },
    {
      "code_2": "BS",
      "code_3": "BHS",
      "numCode": "044",
      "name": "Bahamas",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "BH",
      "code_3": "BHR",
      "numCode": "048",
      "name": "Bahrain",
      "languages": [
        "ara"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-BH",
          "displayName": "Arabic - Bahrain",
          "cultureCode": "0x3C01"
        }
      ]
    },
    {
      "code_2": "BD",
      "code_3": "BGD",
      "numCode": "050",
      "name": "Bangladesh",
      "languages": [
        "ben"
      ]
    },
    {
      "code_2": "BB",
      "code_3": "BRB",
      "numCode": "052",
      "name": "Barbados",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "BY",
      "code_3": "BLR",
      "numCode": "112",
      "name": "Belarus",
      "languages": [
        "bel",
        "rus"
      ],
      "langCultureMs": [
        {
          "langCultureName": "be-BY",
          "displayName": "Belarusian - Belarus",
          "cultureCode": "0x0423"
        }
      ]
    },
    {
      "code_2": "BE",
      "code_3": "BEL",
      "numCode": "056",
      "name": "Belgium",
      "languages": [
        "nld",
        "fra",
        "deu"
      ],
      "langCultureMs": [
        {
          "langCultureName": "nl-BE",
          "displayName": "Dutch - Belgium",
          "cultureCode": "0x0813"
        },
        {
          "langCultureName": "fr-BE",
          "displayName": "French - Belgium",
          "cultureCode": "0x080C"
        }
      ]
    },
    {
      "code_2": "BZ",
      "code_3": "BLZ",
      "numCode": "084",
      "name": "Belize",
      "languages": [
        "eng"
      ],
      "langCultureMs": [
        {
          "langCultureName": "en-BZ",
          "displayName": "English - Belize",
          "cultureCode": "0x2809"
        }
      ]
    },
    {
      "code_2": "BJ",
      "code_3": "BEN",
      "numCode": "204",
      "name": "Benin",
      "languages": [
        "fra",
        "ful",
        "yor"
      ]
    },
    {
      "code_2": "BM",
      "code_3": "BMU",
      "numCode": "060",
      "name": "Bermuda"
    },
    {
      "code_2": "BT",
      "code_3": "BTN",
      "numCode": "064",
      "name": "Bhutan",
      "languages": [
        "dzo"
      ]
    },
    {
      "code_2": "BO",
      "code_3": "BOL",
      "numCode": "068",
      "name": "Bolivia, Plurinational State of",
      "languages": [
        "aym",
        "grn",
        "que",
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-BO",
          "displayName": "Spanish - Bolivia",
          "cultureCode": "0x400A"
        }
      ]
    },
    {
      "code_2": "BQ",
      "code_3": "BES",
      "numCode": "535",
      "name": "Bonaire, Sint Eustatius and Saba"
    },
    {
      "code_2": "BA",
      "code_3": "BIH",
      "numCode": "070",
      "name": "Bosnia and Herzegovina",
      "languages": [
        "bos",
        "hrv",
        "srp"
      ]
    },
    {
      "code_2": "BW",
      "code_3": "BWA",
      "numCode": "072",
      "name": "Botswana",
      "languages": [
        "eng",
        "tsn"
      ]
    },
    {
      "code_2": "BV",
      "code_3": "BVT",
      "numCode": "074",
      "name": "Bouvet Island"
    },
    {
      "code_2": "BR",
      "code_3": "BRA",
      "numCode": "076",
      "name": "Brazil",
      "languages": [
        "por"
      ],
      "langCultureMs": [
        {
          "langCultureName": "pt-BR",
          "displayName": "Portuguese - Brazil",
          "cultureCode": "0x0416"
        }
      ]
    },
    {
      "code_2": "IO",
      "code_3": "IOT",
      "numCode": "086",
      "name": "British Indian Ocean Territory"
    },
    {
      "code_2": "BN",
      "code_3": "BRN",
      "numCode": "096",
      "name": "Brunei Darussalam",
      "languages": [
        "msa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ms-BN",
          "displayName": "Malay - Brunei",
          "cultureCode": "0x083E"
        }
      ]
    },
    {
      "code_2": "BG",
      "code_3": "BGR",
      "numCode": "100",
      "name": "Bulgaria",
      "languages": [
        "bul"
      ],
      "langCultureMs": [
        {
          "langCultureName": "bg-BG",
          "displayName": "Bulgarian - Bulgaria",
          "cultureCode": "0x0402"
        }
      ]
    },
    {
      "code_2": "BF",
      "code_3": "BFA",
      "numCode": "854",
      "name": "Burkina Faso",
      "languages": [
        "fra",
        "ful",
        "mos"
      ]
    },
    {
      "code_2": "BI",
      "code_3": "BDI",
      "numCode": "108",
      "name": "Burundi",
      "languages": [
        "fra",
        "run"
      ]
    },
    {
      "code_2": "KH",
      "code_3": "KHM",
      "numCode": "116",
      "name": "Cambodia",
      "languages": [
        "khm"
      ]
    },
    {
      "code_2": "CM",
      "code_3": "CMR",
      "numCode": "120",
      "name": "Cameroon",
      "languages": [
        "eng",
        "fra"
      ]
    },
    {
      "code_2": "CA",
      "code_3": "CAN",
      "numCode": "124",
      "name": "Canada",
      "languages": [
        "eng",
        "fra"
      ],
      "langCultureMs": [
        {
          "langCultureName": "en-CA",
          "displayName": "English - Canada",
          "cultureCode": "0x1009"
        },
        {
          "langCultureName": "fr-CA",
          "displayName": "French - Canada",
          "cultureCode": "0x0C0C"
        }
      ]
    },
    {
      "code_2": "CV",
      "code_3": "CPV",
      "numCode": "132",
      "name": "Cape Verde",
      "languages": [
        "por"
      ]
    },
    {
      "code_2": "KY",
      "code_3": "CYM",
      "numCode": "136",
      "name": "Cayman Islands"
    },
    {
      "code_2": "CF",
      "code_3": "CAF",
      "numCode": "140",
      "name": "Central African Republic",
      "languages": [
        "fra",
        "sag"
      ]
    },
    {
      "code_2": "TD",
      "code_3": "TCD",
      "numCode": "148",
      "name": "Chad",
      "languages": [
        "ara",
        "fra"
      ]
    },
    {
      "code_2": "CL",
      "code_3": "CHL",
      "numCode": "152",
      "name": "Chile",
      "languages": [
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-CL",
          "displayName": "Spanish - Chile",
          "cultureCode": "0x340A"
        }
      ]
    },
    {
      "code_2": "CN",
      "code_3": "CHN",
      "numCode": "156",
      "name": "China",
      "languages": [
        "zho"
      ],
      "langCultureMs": [
        {
          "langCultureName": "zh-CN",
          "displayName": "Chinese - China",
          "cultureCode": "0x0804"
        },
        {
          "langCultureName": "zh-CHS",
          "displayName": "Chinese (Simplified)",
          "cultureCode": "0x0004"
        },
        {
          "langCultureName": "zh-CHT",
          "displayName": "Chinese (Traditional)",
          "cultureCode": "0x7C04"
        }
      ]
    },
    {
      "code_2": "CX",
      "code_3": "CXR",
      "numCode": "162",
      "name": "Christmas Island"
    },
    {
      "code_2": "CC",
      "code_3": "CCK",
      "numCode": "166",
      "name": "Cocos (Keeling) Islands"
    },
    {
      "code_2": "CO",
      "code_3": "COL",
      "numCode": "170",
      "name": "Colombia",
      "languages": [
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-CO",
          "displayName": "Spanish - Colombia",
          "cultureCode": "0x240A"
        }
      ]
    },
    {
      "code_2": "KM",
      "code_3": "COM",
      "numCode": "174",
      "name": "Comoros",
      "languages": [
        "ara",
        "fra"
      ]
    },
    {
      "code_2": "CG",
      "code_3": "COG",
      "numCode": "178",
      "name": "Congo",
      "languages": [
        "fra",
        "kon",
        "lin"
      ]
    },
    {
      "code_2": "CD",
      "code_3": "COD",
      "numCode": "180",
      "name": "Congo, the Democratic Republic of the",
      "languages": [
        "fra",
        "kon",
        "lin",
        "swa",
        "lub"
      ]
    },
    {
      "code_2": "CK",
      "code_3": "COK",
      "numCode": "184",
      "name": "Cook Islands"
    },
    {
      "code_2": "CR",
      "code_3": "CRI",
      "numCode": "188",
      "name": "Costa Rica",
      "languages": [
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-CR",
          "displayName": "Spanish - Costa Rica",
          "cultureCode": "0x140A"
        }
      ]
    },
    {
      "code_2": "CI",
      "code_3": "CIV",
      "numCode": "384",
      "name": "Côte d'Ivoire",
      "languages": [
        "fra"
      ]
    },
    {
      "code_2": "HR",
      "code_3": "HRV",
      "numCode": "191",
      "name": "Croatia",
      "languages": [
        "hrv",
        "ita"
      ],
      "langCultureMs": [
        {
          "langCultureName": "hr-HR",
          "displayName": "Croatian - Croatia",
          "cultureCode": "0x041A"
        }
      ]
    },
    {
      "code_2": "CU",
      "code_3": "CUB",
      "numCode": "192",
      "name": "Cuba",
      "languages": [
        "spa"
      ]
    },
    {
      "code_2": "CW",
      "code_3": "CUW",
      "numCode": "531",
      "name": "Curaçao",
      "languages": [
        "nld",
        "eng",
        "pap"
      ]
    },
    {
      "code_2": "CY",
      "code_3": "CYP",
      "numCode": "196",
      "name": "Cyprus",
      "languages": [
        "ell",
        "tur"
      ]
    },
    {
      "code_2": "CZ",
      "code_3": "CZE",
      "numCode": "203",
      "name": "Czech Republic",
      "languages": [
        "ces",
        "slk"
      ],
      "langCultureMs": [
        {
          "langCultureName": "cs-CZ",
          "displayName": "Czech - Czech Republic",
          "cultureCode": "0x0405"
        }
      ]
    },
    {
      "code_2": "DK",
      "code_3": "DNK",
      "numCode": "208",
      "name": "Denmark",
      "languages": [
        "dan"
      ],
      "langCultureMs": [
        {
          "langCultureName": "da-DK",
          "displayName": "Danish - Denmark",
          "cultureCode": "0x0406"
        }
      ]
    },
    {
      "code_2": "DJ",
      "code_3": "DJI",
      "numCode": "262",
      "name": "Djibouti",
      "languages": [
        "aar",
        "ara",
        "fra",
        "som"
      ]
    },
    {
      "code_2": "DM",
      "code_3": "DMA",
      "numCode": "212",
      "name": "Dominica",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "DO",
      "code_3": "DOM",
      "numCode": "214",
      "name": "Dominican Republic",
      "languages": [
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-DO",
          "displayName": "Spanish - Dominican Republic",
          "cultureCode": "0x1C0A"
        }
      ]
    },
    {
      "code_2": "EC",
      "code_3": "ECU",
      "numCode": "218",
      "name": "Ecuador",
      "languages": [
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-EC",
          "displayName": "Spanish - Ecuador",
          "cultureCode": "0x300A"
        }
      ]
    },
    {
      "code_2": "EG",
      "code_3": "EGY",
      "numCode": "818",
      "name": "Egypt",
      "languages": [
        "ara"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-EG",
          "displayName": "Arabic - Egypt",
          "cultureCode": "0x0C01"
        }
      ]
    },
    {
      "code_2": "SV",
      "code_3": "SLV",
      "numCode": "222",
      "name": "El Salvador",
      "languages": [
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-SV",
          "displayName": "Spanish - El Salvador",
          "cultureCode": "0x440A"
        }
      ]
    },
    {
      "code_2": "GQ",
      "code_3": "GNQ",
      "numCode": "226",
      "name": "Equatorial Guinea",
      "languages": [
        "fra",
        "por",
        "spa"
      ]
    },
    {
      "code_2": "ER",
      "code_3": "ERI",
      "numCode": "232",
      "name": "Eritrea",
      "languages": [
        "ara",
        "eng",
        "tir"
      ]
    },
    {
      "code_2": "EE",
      "code_3": "EST",
      "numCode": "233",
      "name": "Estonia",
      "languages": [
        "est"
      ],
      "langCultureMs": [
        {
          "langCultureName": "et-EE",
          "displayName": "Estonian - Estonia",
          "cultureCode": "0x0425"
        }
      ]
    },
    {
      "code_2": "ET",
      "code_3": "ETH",
      "numCode": "231",
      "name": "Ethiopia",
      "languages": [
        "amh"
      ]
    },
    {
      "code_2": "FK",
      "code_3": "FLK",
      "numCode": "238",
      "name": "Falkland Islands (Malvinas)"
    },
    {
      "code_2": "FO",
      "code_3": "FRO",
      "numCode": "234",
      "name": "Faroe Islands",
      "languages": [
        "dan"
      ],
      "langCultureMs": [
        {
          "langCultureName": "fo-FO",
          "displayName": "Faroese - Faroe Islands",
          "cultureCode": "0x0438"
        }
      ]
    },
    {
      "code_2": "FJ",
      "code_3": "FJI",
      "numCode": "242",
      "name": "Fiji",
      "languages": [
        "eng",
        "fij",
        "hin",
        "urd"
      ]
    },
    {
      "code_2": "FI",
      "code_3": "FIN",
      "numCode": "246",
      "name": "Finland",
      "languages": [
        "fin",
        "swe"
      ],
      "langCultureMs": [
        {
          "langCultureName": "fi-FI",
          "displayName": "Finnish - Finland",
          "cultureCode": "0x040B"
        },
        {
          "langCultureName": "sv-FI",
          "displayName": "Swedish - Finland",
          "cultureCode": "0x081D"
        }
      ]
    },
    {
      "code_2": "FR",
      "code_3": "FRA",
      "numCode": "250",
      "name": "France",
      "languages": [
        "fra"
      ],
      "langCultureMs": [
        {
          "langCultureName": "fr-FR",
          "displayName": "French - France",
          "cultureCode": "0x040C"
        }
      ]
    },
    {
      "code_2": "GF",
      "code_3": "GUF",
      "numCode": "254",
      "name": "French Guiana",
      "languages": [
        "fra"
      ]
    },
    {
      "code_2": "PF",
      "code_3": "PYF",
      "numCode": "258",
      "name": "French Polynesia",
      "languages": [
        "fra"
      ]
    },
    {
      "code_2": "TF",
      "code_3": "ATF",
      "numCode": "260",
      "name": "French Southern Territories"
    },
    {
      "code_2": "GA",
      "code_3": "GAB",
      "numCode": "266",
      "name": "Gabon",
      "languages": [
        "fra"
      ]
    },
    {
      "code_2": "GM",
      "code_3": "GMB",
      "numCode": "270",
      "name": "Gambia",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "GE",
      "code_3": "GEO",
      "numCode": "268",
      "name": "Georgia",
      "languages": [
        "kat"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ka-GE",
          "displayName": "Georgian - Georgia",
          "cultureCode": "0x0437"
        }
      ]
    },
    {
      "code_2": "DE",
      "code_3": "DEU",
      "numCode": "276",
      "name": "Germany",
      "languages": [
        "deu"
      ],
      "langCultureMs": [
        {
          "langCultureName": "de-DE",
          "displayName": "German - Germany",
          "cultureCode": "0x0407"
        }
      ]
    },
    {
      "code_2": "GH",
      "code_3": "GHA",
      "numCode": "288",
      "name": "Ghana",
      "languages": [
        "aka",
        "eng",
        "ewe",
        "gaa"
      ]
    },
    {
      "code_2": "GI",
      "code_3": "GIB",
      "numCode": "292",
      "name": "Gibraltar"
    },
    {
      "code_2": "GR",
      "code_3": "GRC",
      "numCode": "300",
      "name": "Greece",
      "languages": [
        "ell"
      ],
      "langCultureMs": [
        {
          "langCultureName": "el-GR",
          "displayName": "Greek - Greece",
          "cultureCode": "0x0408"
        }
      ]
    },
    {
      "code_2": "GL",
      "code_3": "GRL",
      "numCode": "304",
      "name": "Greenland"
    },
    {
      "code_2": "GD",
      "code_3": "GRD",
      "numCode": "308",
      "name": "Grenada",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "GP",
      "code_3": "GLP",
      "numCode": "312",
      "name": "Guadeloupe",
      "languages": [
        "fra"
      ]
    },
    {
      "code_2": "GU",
      "code_3": "GUM",
      "numCode": "316",
      "name": "Guam"
    },
    {
      "code_2": "GT",
      "code_3": "GTM",
      "numCode": "320",
      "name": "Guatemala",
      "languages": [
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-GT",
          "displayName": "Spanish - Guatemala",
          "cultureCode": "0x100A"
        }
      ]
    },
    {
      "code_2": "GG",
      "code_3": "GGY",
      "numCode": "831",
      "name": "Guernsey"
    },
    {
      "code_2": "GN",
      "code_3": "GIN",
      "numCode": "324",
      "name": "Guinea",
      "languages": [
        "fra"
      ]
    },
    {
      "code_2": "GW",
      "code_3": "GNB",
      "numCode": "624",
      "name": "Guinea-Bissau",
      "languages": [
        "por"
      ]
    },
    {
      "code_2": "GY",
      "code_3": "GUY",
      "numCode": "328",
      "name": "Guyana",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "HT",
      "code_3": "HTI",
      "numCode": "332",
      "name": "Haiti",
      "languages": [
        "fra",
        "hat"
      ]
    },
    {
      "code_2": "HM",
      "code_3": "HMD",
      "numCode": "334",
      "name": "Heard Island and McDonald Islands"
    },
    {
      "code_2": "VA",
      "code_3": "VAT",
      "numCode": "336",
      "name": "Holy See (Vatican City State)",
      "languages": [
        "ita",
        "lat"
      ]
    },
    {
      "code_2": "HN",
      "code_3": "HND",
      "numCode": "340",
      "name": "Honduras",
      "languages": [
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-HN",
          "displayName": "Spanish - Honduras",
          "cultureCode": "0x480A"
        }
      ]
    },
    {
      "code_2": "HK",
      "code_3": "HKG",
      "numCode": "344",
      "name": "Hong Kong",
      "languages": [
        "zho",
        "eng"
      ],
      "langCultureMs": [
        {
          "langCultureName": "zh-HK",
          "displayName": "Chinese - Hong Kong SAR",
          "cultureCode": "0x0C04"
        }
      ]
    },
    {
      "code_2": "HU",
      "code_3": "HUN",
      "numCode": "348",
      "name": "Hungary",
      "languages": [
        "hun"
      ],
      "langCultureMs": [
        {
          "langCultureName": "hu-HU",
          "displayName": "Hungarian - Hungary",
          "cultureCode": "0x040E"
        }
      ]
    },
    {
      "code_2": "IS",
      "code_3": "ISL",
      "numCode": "352",
      "name": "Iceland",
      "languages": [
        "isl"
      ],
      "langCultureMs": [
        {
          "langCultureName": "is-IS",
          "displayName": "Icelandic - Iceland",
          "cultureCode": "0x040F"
        }
      ]
    },
    {
      "code_2": "IN",
      "code_3": "IND",
      "numCode": "356",
      "name": "India",
      "languages": [
        "ben",
        "eng",
        "guj",
        "hin",
        "pan",
        "tam",
        "tel"
      ],
      "langCultureMs": [
        {
          "langCultureName": "gu-IN",
          "displayName": "Gujarati - India",
          "cultureCode": "0x0447"
        },
        {
          "langCultureName": "hi-IN",
          "displayName": "Hindi - India",
          "cultureCode": "0x0439"
        },
        {
          "langCultureName": "kn-IN",
          "displayName": "Kannada - India",
          "cultureCode": "0x044B"
        },
        {
          "langCultureName": "kok-IN",
          "displayName": "Konkani - India",
          "cultureCode": "0x0457"
        },
        {
          "langCultureName": "mr-IN",
          "displayName": "Marathi - India",
          "cultureCode": "0x044E"
        },
        {
          "langCultureName": "pa-IN",
          "displayName": "Punjabi - India",
          "cultureCode": "0x0446"
        },
        {
          "langCultureName": "sa-IN",
          "displayName": "Sanskrit - India",
          "cultureCode": "0x044F"
        },
        {
          "langCultureName": "ta-IN",
          "displayName": "Tamil - India",
          "cultureCode": "0x0449"
        },
        {
          "langCultureName": "te-IN",
          "displayName": "Telugu - India",
          "cultureCode": "0x044A"
        }
      ]
    },
    {
      "code_2": "ID",
      "code_3": "IDN",
      "numCode": "360",
      "name": "Indonesia",
      "languages": [
        "ind",
        "msa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "id-ID",
          "displayName": "Indonesian - Indonesia",
          "cultureCode": "0x0421"
        }
      ]
    },
    {
      "code_2": "IR",
      "code_3": "IRN",
      "numCode": "364",
      "name": "Iran, Islamic Republic of",
      "languages": [
        "fas"
      ],
      "langCultureMs": [
        {
          "langCultureName": "fa-IR",
          "displayName": "Farsi - Iran",
          "cultureCode": "0x0429"
        }
      ]
    },
    {
      "code_2": "IQ",
      "code_3": "IRQ",
      "numCode": "368",
      "name": "Iraq",
      "languages": [
        "ara",
        "kur"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-IQ",
          "displayName": "Arabic - Iraq",
          "cultureCode": "0x0801"
        }
      ]
    },
    {
      "code_2": "IE",
      "code_3": "IRL",
      "numCode": "372",
      "name": "Ireland",
      "languages": [
        "eng",
        "gle"
      ],
      "langCultureMs": [
        {
          "langCultureName": "en-IE",
          "displayName": "English - Ireland",
          "cultureCode": "0x1809"
        }
      ]
    },
    {
      "code_2": "IM",
      "code_3": "IMN",
      "numCode": "833",
      "name": "Isle of Man"
    },
    {
      "code_2": "IL",
      "code_3": "ISR",
      "numCode": "376",
      "name": "Israel",
      "languages": [
        "ara",
        "heb"
      ],
      "langCultureMs": [
        {
          "langCultureName": "he-IL",
          "displayName": "Hebrew - Israel",
          "cultureCode": "0x040D"
        }
      ]
    },
    {
      "code_2": "IT",
      "code_3": "ITA",
      "numCode": "380",
      "name": "Italy",
      "languages": [
        "fra",
        "deu",
        "ita"
      ],
      "langCultureMs": [
        {
          "langCultureName": "it-IT",
          "displayName": "Italian - Italy",
          "cultureCode": "0x0410"
        }
      ]
    },
    {
      "code_2": "JM",
      "code_3": "JAM",
      "numCode": "388",
      "name": "Jamaica",
      "languages": [
        "eng"
      ],
      "langCultureMs": [
        {
          "langCultureName": "en-JM",
          "displayName": "English - Jamaica",
          "cultureCode": "0x2009"
        }
      ]
    },
    {
      "code_2": "JP",
      "code_3": "JPN",
      "numCode": "392",
      "name": "Japan",
      "languages": [
        "jpn"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ja-JP",
          "displayName": "Japanese - Japan",
          "cultureCode": "0x0411"
        }
      ]
    },
    {
      "code_2": "JE",
      "code_3": "JEY",
      "numCode": "832",
      "name": "Jersey",
      "languages": [
        "fra"
      ]
    },
    {
      "code_2": "JO",
      "code_3": "JOR",
      "numCode": "400",
      "name": "Jordan",
      "languages": [
        "ara"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-JO",
          "displayName": "Arabic - Jordan",
          "cultureCode": "0x2C01"
        }
      ]
    },
    {
      "code_2": "KZ",
      "code_3": "KAZ",
      "numCode": "398",
      "name": "Kazakhstan",
      "languages": [
        "kaz",
        "rus"
      ],
      "langCultureMs": [
        {
          "langCultureName": "kk-KZ",
          "displayName": "Kazakh - Kazakhstan",
          "cultureCode": "0x043F"
        },
        {
          "langCultureName": "ky-KZ",
          "displayName": "Kyrgyz - Kazakhstan",
          "cultureCode": "0x0440"
        }
      ]
    },
    {
      "code_2": "KE",
      "code_3": "KEN",
      "numCode": "404",
      "name": "Kenya",
      "languages": [
        "eng",
        "swa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "sw-KE",
          "displayName": "Swahili - Kenya",
          "cultureCode": "0x0441"
        }
      ]
    },
    {
      "code_2": "KI",
      "code_3": "KIR",
      "numCode": "296",
      "name": "Kiribati",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "KP",
      "code_3": "PRK",
      "numCode": "408",
      "name": "Korea, Democratic People's Republic of",
      "languages": [
        "kor"
      ]
    },
    {
      "code_2": "KR",
      "code_3": "KOR",
      "numCode": "410",
      "name": "Korea, Republic of",
      "languages": [
        "kor"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ko-KR",
          "displayName": "Korean - Korea",
          "cultureCode": "0x0412"
        }
      ]
    },
    {
      "code_2": "KW",
      "code_3": "KWT",
      "numCode": "414",
      "name": "Kuwait",
      "languages": [
        "ara"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-KW",
          "displayName": "Arabic - Kuwait",
          "cultureCode": "0x3401"
        }
      ]
    },
    {
      "code_2": "KG",
      "code_3": "KGZ",
      "numCode": "417",
      "name": "Kyrgyzstan",
      "languages": [
        "kir",
        "rus"
      ]
    },
    {
      "code_2": "LA",
      "code_3": "LAO",
      "numCode": "418",
      "name": "Lao People's Democratic Republic",
      "languages": [
        "lao"
      ]
    },
    {
      "code_2": "LV",
      "code_3": "LVA",
      "numCode": "428",
      "name": "Latvia",
      "languages": [
        "lav"
      ],
      "langCultureMs": [
        {
          "langCultureName": "lv-LV",
          "displayName": "Latvian - Latvia",
          "cultureCode": "0x0426"
        }
      ]
    },
    {
      "code_2": "LB",
      "code_3": "LBN",
      "numCode": "422",
      "name": "Lebanon",
      "languages": [
        "ara"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-LB",
          "displayName": "Arabic - Lebanon",
          "cultureCode": "0x3001"
        }
      ]
    },
    {
      "code_2": "LS",
      "code_3": "LSO",
      "numCode": "426",
      "name": "Lesotho",
      "languages": [
        "eng",
        "sot"
      ]
    },
    {
      "code_2": "LR",
      "code_3": "LBR",
      "numCode": "430",
      "name": "Liberia",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "LY",
      "code_3": "LBY",
      "numCode": "434",
      "name": "Libya",
      "languages": [
        "ara"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-LY",
          "displayName": "Arabic - Libya",
          "cultureCode": "0x1001"
        }
      ]
    },
    {
      "code_2": "LI",
      "code_3": "LIE",
      "numCode": "438",
      "name": "Liechtenstein",
      "languages": [
        "deu"
      ],
      "langCultureMs": [
        {
          "langCultureName": "de-LI",
          "displayName": "German - Liechtenstein",
          "cultureCode": "0x1407"
        }
      ]
    },
    {
      "code_2": "LT",
      "code_3": "LTU",
      "numCode": "440",
      "name": "Lithuania",
      "languages": [
        "lit"
      ],
      "langCultureMs": [
        {
          "langCultureName": "lt-LT",
          "displayName": "Lithuanian - Lithuania",
          "cultureCode": "0x0427"
        }
      ]
    },
    {
      "code_2": "LU",
      "code_3": "LUX",
      "numCode": "442",
      "name": "Luxembourg",
      "languages": [
        "fra",
        "deu",
        "ltz"
      ],
      "langCultureMs": [
        {
          "langCultureName": "fr-LU",
          "displayName": "French - Luxembourg",
          "cultureCode": "0x140C"
        },
        {
          "langCultureName": "de-LU",
          "displayName": "German - Luxembourg",
          "cultureCode": "0x1007"
        }
      ]
    },
    {
      "code_2": "MO",
      "code_3": "MAC",
      "numCode": "446",
      "name": "Macao",
      "languages": [
        "zho",
        "por"
      ],
      "langCultureMs": [
        {
          "langCultureName": "zh-MO",
          "displayName": "Chinese - Macau SAR",
          "cultureCode": "0x1404"
        }
      ]
    },
    {
      "code_2": "MK",
      "code_3": "MKD",
      "numCode": "807",
      "name": "Macedonia, the former Yugoslav Republic of",
      "languages": [
        "mkd"
      ],
      "langCultureMs": [
        {
          "langCultureName": "mk-MK",
          "displayName": "Macedonian (FYROM)",
          "cultureCode": "0x042F"
        }
      ]
    },
    {
      "code_2": "MG",
      "code_3": "MDG",
      "numCode": "450",
      "name": "Madagascar",
      "languages": [
        "fra",
        "mlg"
      ]
    },
    {
      "code_2": "MW",
      "code_3": "MWI",
      "numCode": "454",
      "name": "Malawi",
      "languages": [
        "nya",
        "eng"
      ]
    },
    {
      "code_2": "MY",
      "code_3": "MYS",
      "numCode": "458",
      "name": "Malaysia",
      "languages": [
        "eng",
        "msa",
        "tam"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ms-MY",
          "displayName": "Malay - Malaysia",
          "cultureCode": "0x043E"
        }
      ]
    },
    {
      "code_2": "MV",
      "code_3": "MDV",
      "numCode": "462",
      "name": "Maldives",
      "languages": [
        "div"
      ],
      "langCultureMs": [
        {
          "langCultureName": "div-MV",
          "displayName": "Dhivehi - Maldives",
          "cultureCode": "0x0465"
        }
      ]
    },
    {
      "code_2": "ML",
      "code_3": "MLI",
      "numCode": "466",
      "name": "Mali",
      "languages": [
        "bam",
        "fra",
        "ful",
        "snk"
      ]
    },
    {
      "code_2": "MT",
      "code_3": "MLT",
      "numCode": "470",
      "name": "Malta",
      "languages": [
        "eng",
        "mlt"
      ]
    },
    {
      "code_2": "MH",
      "code_3": "MHL",
      "numCode": "584",
      "name": "Marshall Islands",
      "languages": [
        "eng",
        "mah"
      ]
    },
    {
      "code_2": "MQ",
      "code_3": "MTQ",
      "numCode": "474",
      "name": "Martinique",
      "languages": [
        "fra"
      ]
    },
    {
      "code_2": "MR",
      "code_3": "MRT",
      "numCode": "478",
      "name": "Mauritania",
      "languages": [
        "ara"
      ]
    },
    {
      "code_2": "MU",
      "code_3": "MUS",
      "numCode": "480",
      "name": "Mauritius",
      "languages": [
        "eng",
        "fra",
        "tam"
      ]
    },
    {
      "code_2": "YT",
      "code_3": "MYT",
      "numCode": "175",
      "name": "Mayotte",
      "languages": [
        "fra"
      ]
    },
    {
      "code_2": "MX",
      "code_3": "MEX",
      "numCode": "484",
      "name": "Mexico",
      "languages": [
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-MX",
          "displayName": "Spanish - Mexico",
          "cultureCode": "0x080A"
        }
      ]
    },
    {
      "code_2": "FM",
      "code_3": "FSM",
      "numCode": "583",
      "name": "Micronesia, Federated States of",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "MD",
      "code_3": "MDA",
      "numCode": "498",
      "name": "Moldova, Republic of",
      "languages": [
        "ron"
      ]
    },
    {
      "code_2": "MC",
      "code_3": "MCO",
      "numCode": "492",
      "name": "Monaco",
      "languages": [
        "fra"
      ],
      "langCultureMs": [
        {
          "langCultureName": "fr-MC",
          "displayName": "French - Monaco",
          "cultureCode": "0x180C"
        }
      ]
    },
    {
      "code_2": "MN",
      "code_3": "MNG",
      "numCode": "496",
      "name": "Mongolia",
      "languages": [
        "mon"
      ],
      "langCultureMs": [
        {
          "langCultureName": "mn-MN",
          "displayName": "Mongolian - Mongolia",
          "cultureCode": "0x0450"
        }
      ]
    },
    {
      "code_2": "ME",
      "code_3": "MNE",
      "numCode": "499",
      "name": "Montenegro"
    },
    {
      "code_2": "MS",
      "code_3": "MSR",
      "numCode": "500",
      "name": "Montserrat"
    },
    {
      "code_2": "MA",
      "code_3": "MAR",
      "numCode": "504",
      "name": "Morocco",
      "languages": [
        "ara",
        "ber"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-MA",
          "displayName": "Arabic - Morocco",
          "cultureCode": "0x1801"
        }
      ]
    },
    {
      "code_2": "MZ",
      "code_3": "MOZ",
      "numCode": "508",
      "name": "Mozambique",
      "languages": [
        "por"
      ]
    },
    {
      "code_2": "MM",
      "code_3": "MMR",
      "numCode": "104",
      "name": "Myanmar",
      "languages": [
        "mya"
      ]
    },
    {
      "code_2": "NA",
      "code_3": "NAM",
      "numCode": "516",
      "name": "Namibia",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "NR",
      "code_3": "NRU",
      "numCode": "520",
      "name": "Nauru",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "NP",
      "code_3": "NPL",
      "numCode": "524",
      "name": "Nepal",
      "languages": [
        "nep"
      ]
    },
    {
      "code_2": "NL",
      "code_3": "NLD",
      "numCode": "528",
      "name": "Netherlands",
      "languages": [
        "nld"
      ],
      "langCultureMs": [
        {
          "langCultureName": "nl-NL",
          "displayName": "Dutch - The Netherlands",
          "cultureCode": "0x0413"
        }
      ]
    },
    {
      "code_2": "NC",
      "code_3": "NCL",
      "numCode": "540",
      "name": "New Caledonia",
      "languages": [
        "fra"
      ]
    },
    {
      "code_2": "NZ",
      "code_3": "NZL",
      "numCode": "554",
      "name": "New Zealand",
      "languages": [
        "eng",
        "mri"
      ],
      "langCultureMs": [
        {
          "langCultureName": "en-NZ",
          "displayName": "English - New Zealand",
          "cultureCode": "0x1409"
        }
      ]
    },
    {
      "code_2": "NI",
      "code_3": "NIC",
      "numCode": "558",
      "name": "Nicaragua",
      "languages": [
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-NI",
          "displayName": "Spanish - Nicaragua",
          "cultureCode": "0x4C0A"
        }
      ]
    },
    {
      "code_2": "NE",
      "code_3": "NER",
      "numCode": "562",
      "name": "Niger",
      "languages": [
        "ara",
        "fra",
        "ful",
        "hau",
        "kau"
      ]
    },
    {
      "code_2": "NG",
      "code_3": "NGA",
      "numCode": "566",
      "name": "Nigeria",
      "languages": [
        "eng",
        "hau",
        "ibo",
        "yor"
      ]
    },
    {
      "code_2": "NU",
      "code_3": "NIU",
      "numCode": "570",
      "name": "Niue"
    },
    {
      "code_2": "NF",
      "code_3": "NFK",
      "numCode": "574",
      "name": "Norfolk Island"
    },
    {
      "code_2": "MP",
      "code_3": "MNP",
      "numCode": "580",
      "name": "Northern Mariana Islands"
    },
    {
      "code_2": "NO",
      "code_3": "NOR",
      "numCode": "578",
      "name": "Norway",
      "languages": [
        "nob",
        "nno",
        "nor"
      ],
      "langCultureMs": [
        {
          "langCultureName": "nb-NO",
          "displayName": "Norwegian (Bokmål) - Norway",
          "cultureCode": "0x0414"
        },
        {
          "langCultureName": "nn-NO",
          "displayName": "Norwegian (Nynorsk) - Norway",
          "cultureCode": "0x0814"
        }
      ]
    },
    {
      "code_2": "OM",
      "code_3": "OMN",
      "numCode": "512",
      "name": "Oman",
      "languages": [
        "ara"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-OM",
          "displayName": "Arabic - Oman",
          "cultureCode": "0x2001"
        }
      ]
    },
    {
      "code_2": "PK",
      "code_3": "PAK",
      "numCode": "586",
      "name": "Pakistan",
      "languages": [
        "eng",
        "urd"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ur-PK",
          "displayName": "Urdu - Pakistan",
          "cultureCode": "0x0420"
        }
      ]
    },
    {
      "code_2": "PW",
      "code_3": "PLW",
      "numCode": "585",
      "name": "Palau",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "PS",
      "code_3": "PSE",
      "numCode": "275",
      "name": "Palestine, State of"
    },
    {
      "code_2": "PA",
      "code_3": "PAN",
      "numCode": "591",
      "name": "Panama",
      "languages": [
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-PA",
          "displayName": "Spanish - Panama",
          "cultureCode": "0x180A"
        }
      ]
    },
    {
      "code_2": "PG",
      "code_3": "PNG",
      "numCode": "598",
      "name": "Papua New Guinea",
      "languages": [
        "eng",
        "hmo",
        "tpi"
      ]
    },
    {
      "code_2": "PY",
      "code_3": "PRY",
      "numCode": "600",
      "name": "Paraguay",
      "languages": [
        "grn",
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-PY",
          "displayName": "Spanish - Paraguay",
          "cultureCode": "0x3C0A"
        }
      ]
    },
    {
      "code_2": "PE",
      "code_3": "PER",
      "numCode": "604",
      "name": "Peru",
      "languages": [
        "aym",
        "que",
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-PE",
          "displayName": "Spanish - Peru",
          "cultureCode": "0x280A"
        }
      ]
    },
    {
      "code_2": "PH",
      "code_3": "PHL",
      "numCode": "608",
      "name": "Philippines",
      "languages": [
        "eng",
        "fil",
        "tgl"
      ],
      "langCultureMs": [
        {
          "langCultureName": "en-PH",
          "displayName": "English - Philippines",
          "cultureCode": "0x3409"
        }
      ]
    },
    {
      "code_2": "PN",
      "code_3": "PCN",
      "numCode": "612",
      "name": "Pitcairn"
    },
    {
      "code_2": "PL",
      "code_3": "POL",
      "numCode": "616",
      "name": "Poland",
      "languages": [
        "pol"
      ],
      "langCultureMs": [
        {
          "langCultureName": "pl-PL",
          "displayName": "Polish - Poland",
          "cultureCode": "0x0415"
        }
      ]
    },
    {
      "code_2": "PT",
      "code_3": "PRT",
      "numCode": "620",
      "name": "Portugal",
      "languages": [
        "por"
      ],
      "langCultureMs": [
        {
          "langCultureName": "pt-PT",
          "displayName": "Portuguese - Portugal",
          "cultureCode": "0x0816"
        }
      ]
    },
    {
      "code_2": "PR",
      "code_3": "PRI",
      "numCode": "630",
      "name": "Puerto Rico",
      "languages": [
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-PR",
          "displayName": "Spanish - Puerto Rico",
          "cultureCode": "0x500A"
        }
      ]
    },
    {
      "code_2": "QA",
      "code_3": "QAT",
      "numCode": "634",
      "name": "Qatar",
      "languages": [
        "ara"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-QA",
          "displayName": "Arabic - Qatar",
          "cultureCode": "0x4001"
        }
      ]
    },
    {
      "code_2": "RE",
      "code_3": "REU",
      "numCode": "638",
      "name": "Réunion",
      "languages": [
        "fra"
      ]
    },
    {
      "code_2": "RO",
      "code_3": "ROU",
      "numCode": "642",
      "name": "Romania",
      "languages": [
        "ron"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ro-RO",
          "displayName": "Romanian - Romania",
          "cultureCode": "0x0418"
        }
      ]
    },
    {
      "code_2": "RU",
      "code_3": "RUS",
      "numCode": "643",
      "name": "Russian Federation",
      "languages": [
        "rus"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ru-RU",
          "displayName": "Russian - Russia",
          "cultureCode": "0x0419"
        },
        {
          "langCultureName": "tt-RU",
          "displayName": "Tatar - Russia",
          "cultureCode": "0x0444"
        }
      ]
    },
    {
      "code_2": "RW",
      "code_3": "RWA",
      "numCode": "646",
      "name": "Rwanda",
      "languages": [
        "eng",
        "fra",
        "kin"
      ]
    },
    {
      "code_2": "BL",
      "code_3": "BLM",
      "numCode": "652",
      "name": "Saint Barthélemy",
      "languages": [
        "fra"
      ]
    },
    {
      "code_2": "SH",
      "code_3": "SHN",
      "numCode": "654",
      "name": "Saint Helena, Ascension and Tristan da Cunha"
    },
    {
      "code_2": "KN",
      "code_3": "KNA",
      "numCode": "659",
      "name": "Saint Kitts and Nevis",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "LC",
      "code_3": "LCA",
      "numCode": "662",
      "name": "Saint Lucia",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "MF",
      "code_3": "MAF",
      "numCode": "663",
      "name": "Saint Martin (French part)"
    },
    {
      "code_2": "PM",
      "code_3": "SPM",
      "numCode": "666",
      "name": "Saint Pierre and Miquelon",
      "languages": [
        "fra"
      ]
    },
    {
      "code_2": "VC",
      "code_3": "VCT",
      "numCode": "670",
      "name": "Saint Vincent and the Grenadines",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "WS",
      "code_3": "WSM",
      "numCode": "882",
      "name": "Samoa",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "SM",
      "code_3": "SMR",
      "numCode": "674",
      "name": "San Marino",
      "languages": [
        "ita"
      ]
    },
    {
      "code_2": "ST",
      "code_3": "STP",
      "numCode": "678",
      "name": "Sao Tome and Principe",
      "languages": [
        "por"
      ]
    },
    {
      "code_2": "SA",
      "code_3": "SAU",
      "numCode": "682",
      "name": "Saudi Arabia",
      "languages": [
        "ara"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-SA",
          "displayName": "Arabic - Saudi Arabia",
          "cultureCode": "0x0401"
        }
      ]
    },
    {
      "code_2": "SN",
      "code_3": "SEN",
      "numCode": "686",
      "name": "Senegal",
      "languages": [
        "fra",
        "ful",
        "srr",
        "snk",
        "wol"
      ]
    },
    {
      "code_2": "RS",
      "code_3": "SRB",
      "numCode": "688",
      "name": "Serbia",
      "languages": [
        "srp"
      ],
      "langCultureMs": [
        {
          "langCultureName": "Cy-sr-SP",
          "displayName": "Serbian (Cyrillic) - Serbia",
          "cultureCode": "0x0C1A"
        },
        {
          "langCultureName": "Lt-sr-SP",
          "displayName": "Serbian (Latin) - Serbia",
          "cultureCode": "0x081A"
        }
      ]
    },
    {
      "code_2": "SC",
      "code_3": "SYC",
      "numCode": "690",
      "name": "Seychelles",
      "languages": [
        "eng",
        "fra"
      ]
    },
    {
      "code_2": "SL",
      "code_3": "SLE",
      "numCode": "694",
      "name": "Sierra Leone",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "SG",
      "code_3": "SGP",
      "numCode": "702",
      "name": "Singapore",
      "languages": [
        "eng",
        "zho",
        "msa",
        "tam"
      ],
      "langCultureMs": [
        {
          "langCultureName": "en-SG",
          "displayName": "English - Singapore",
          "cultureCode": "0x1004"
        }
      ]
    },
    {
      "code_2": "SX",
      "code_3": "SXM",
      "numCode": "534",
      "name": "Sint Maarten (Dutch part)",
      "languages": [
        "nld",
        "eng"
      ]
    },
    {
      "code_2": "SK",
      "code_3": "SVK",
      "numCode": "703",
      "name": "Slovakia",
      "languages": [
        "ces",
        "slk"
      ],
      "langCultureMs": [
        {
          "langCultureName": "sk-SK",
          "displayName": "Slovak - Slovakia",
          "cultureCode": "0x041B"
        }
      ]
    },
    {
      "code_2": "SI",
      "code_3": "SVN",
      "numCode": "705",
      "name": "Slovenia",
      "languages": [
        "ita",
        "slv"
      ],
      "langCultureMs": [
        {
          "langCultureName": "sl-SI",
          "displayName": "Slovenian - Slovenia",
          "cultureCode": "0x0424"
        }
      ]
    },
    {
      "code_2": "SB",
      "code_3": "SLB",
      "numCode": "090",
      "name": "Solomon Islands",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "SO",
      "code_3": "SOM",
      "numCode": "706",
      "name": "Somalia",
      "languages": [
        "ara",
        "som"
      ]
    },
    {
      "code_2": "ZA",
      "code_3": "ZAF",
      "numCode": "710",
      "name": "South Africa",
      "languages": [
        "afr",
        "eng",
        "nde",
        "nbl",
        "nso",
        "sot",
        "ssw",
        "tso",
        "tsn",
        "ven",
        "xho",
        "zul"
      ],
      "langCultureMs": [
        {
          "langCultureName": "af-ZA",
          "displayName": "Afrikaans - South Africa",
          "cultureCode": "0x0436"
        },
        {
          "langCultureName": "en-ZA",
          "displayName": "English - South Africa",
          "cultureCode": "0x1C09"
        }
      ]
    },
    {
      "code_2": "GS",
      "code_3": "SGS",
      "numCode": "239",
      "name": "South Georgia and the South Sandwich Islands"
    },
    {
      "code_2": "SS",
      "code_3": "SSD",
      "numCode": "728",
      "name": "South Sudan",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "ES",
      "code_3": "ESP",
      "numCode": "724",
      "name": "Spain",
      "languages": [
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "eu-ES",
          "displayName": "Basque - Basque",
          "cultureCode": "0x042D"
        },
        {
          "langCultureName": "ca-ES",
          "displayName": "Catalan - Catalan",
          "cultureCode": "0x0403"
        },
        {
          "langCultureName": "gl-ES",
          "displayName": "Galician - Galician",
          "cultureCode": "0x0456"
        },
        {
          "langCultureName": "es-ES",
          "displayName": "Spanish - Spain",
          "cultureCode": "0x0C0A"
        }
      ]
    },
    {
      "code_2": "LK",
      "code_3": "LKA",
      "numCode": "144",
      "name": "Sri Lanka",
      "languages": [
        "eng",
        "sin",
        "tam"
      ]
    },
    {
      "code_2": "SD",
      "code_3": "SDN",
      "numCode": "729",
      "name": "Sudan",
      "languages": [
        "ara",
        "eng"
      ]
    },
    {
      "code_2": "SR",
      "code_3": "SUR",
      "numCode": "740",
      "name": "Suriname",
      "languages": [
        "nld"
      ]
    },
    {
      "code_2": "SJ",
      "code_3": "SJM",
      "numCode": "744",
      "name": "Svalbard and Jan Mayen"
    },
    {
      "code_2": "SZ",
      "code_3": "SWZ",
      "numCode": "748",
      "name": "Swaziland",
      "languages": [
        "eng",
        "ssw"
      ]
    },
    {
      "code_2": "SE",
      "code_3": "SWE",
      "numCode": "752",
      "name": "Sweden",
      "languages": [
        "swe"
      ],
      "langCultureMs": [
        {
          "langCultureName": "sv-SE",
          "displayName": "Swedish - Sweden",
          "cultureCode": "0x041D"
        }
      ]
    },
    {
      "code_2": "CH",
      "code_3": "CHE",
      "numCode": "756",
      "name": "Switzerland",
      "languages": [
        "fra",
        "deu",
        "ita",
        "roh"
      ],
      "langCultureMs": [
        {
          "langCultureName": "fr-CH",
          "displayName": "French - Switzerland",
          "cultureCode": "0x100C"
        },
        {
          "langCultureName": "de-CH",
          "displayName": "German - Switzerland",
          "cultureCode": "0x0807"
        },
        {
          "langCultureName": "it-CH",
          "displayName": "Italian - Switzerland",
          "cultureCode": "0x0810"
        }
      ]
    },
    {
      "code_2": "SY",
      "code_3": "SYR",
      "numCode": "760",
      "name": "Syrian Arab Republic",
      "languages": [
        "ara"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-SY",
          "displayName": "Arabic - Syria",
          "cultureCode": "0x2801"
        },
        {
          "langCultureName": "syr-SY",
          "displayName": "Syriac - Syria",
          "cultureCode": "0x045A"
        }
      ]
    },
    {
      "code_2": "TW",
      "code_3": "TWN",
      "numCode": "158",
      "name": "Taiwan, Province of China",
      "languages": [
        "zho"
      ],
      "langCultureMs": [
        {
          "langCultureName": "zh-TW",
          "displayName": "Chinese - Taiwan",
          "cultureCode": "0x0404"
        }
      ]
    },
    {
      "code_2": "TJ",
      "code_3": "TJK",
      "numCode": "762",
      "name": "Tajikistan",
      "languages": [
        "fas",
        "rus",
        "tgk"
      ]
    },
    {
      "code_2": "TZ",
      "code_3": "TZA",
      "numCode": "834",
      "name": "Tanzania, United Republic of",
      "languages": [
        "eng",
        "swa"
      ]
    },
    {
      "code_2": "TH",
      "code_3": "THA",
      "numCode": "764",
      "name": "Thailand",
      "languages": [
        "tha"
      ],
      "langCultureMs": [
        {
          "langCultureName": "th-TH",
          "displayName": "Thai - Thailand",
          "cultureCode": "0x041E"
        }
      ]
    },
    {
      "code_2": "TL",
      "code_3": "TLS",
      "numCode": "626",
      "name": "Timor-Leste",
      "languages": [
        "por",
        "tet"
      ]
    },
    {
      "code_2": "TG",
      "code_3": "TGO",
      "numCode": "768",
      "name": "Togo",
      "languages": [
        "ewe",
        "fra"
      ]
    },
    {
      "code_2": "TK",
      "code_3": "TKL",
      "numCode": "772",
      "name": "Tokelau"
    },
    {
      "code_2": "TO",
      "code_3": "TON",
      "numCode": "776",
      "name": "Tonga",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "TT",
      "code_3": "TTO",
      "numCode": "780",
      "name": "Trinidad and Tobago",
      "languages": [
        "eng"
      ],
      "langCultureMs": [
        {
          "langCultureName": "en-TT",
          "displayName": "English - Trinidad and Tobago",
          "cultureCode": "0x2C09"
        }
      ]
    },
    {
      "code_2": "TN",
      "code_3": "TUN",
      "numCode": "788",
      "name": "Tunisia",
      "languages": [
        "ara"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-TN",
          "displayName": "Arabic - Tunisia",
          "cultureCode": "0x1C01"
        }
      ]
    },
    {
      "code_2": "TR",
      "code_3": "TUR",
      "numCode": "792",
      "name": "Turkey",
      "languages": [
        "tur"
      ],
      "langCultureMs": [
        {
          "langCultureName": "tr-TR",
          "displayName": "Turkish - Turkey",
          "cultureCode": "0x041F"
        }
      ]
    },
    {
      "code_2": "TM",
      "code_3": "TKM",
      "numCode": "795",
      "name": "Turkmenistan",
      "languages": [
        "tuk"
      ]
    },
    {
      "code_2": "TC",
      "code_3": "TCA",
      "numCode": "796",
      "name": "Turks and Caicos Islands"
    },
    {
      "code_2": "TV",
      "code_3": "TUV",
      "numCode": "798",
      "name": "Tuvalu",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "UG",
      "code_3": "UGA",
      "numCode": "800",
      "name": "Uganda",
      "languages": [
        "eng",
        "swa"
      ]
    },
    {
      "code_2": "UA",
      "code_3": "UKR",
      "numCode": "804",
      "name": "Ukraine",
      "languages": [
        "ukr"
      ],
      "langCultureMs": [
        {
          "langCultureName": "uk-UA",
          "displayName": "Ukrainian - Ukraine",
          "cultureCode": "0x0422"
        }
      ]
    },
    {
      "code_2": "AE",
      "code_3": "ARE",
      "numCode": "784",
      "name": "United Arab Emirates",
      "languages": [
        "ara"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-AE",
          "displayName": "Arabic - United Arab Emirates",
          "cultureCode": "0x3801"
        }
      ]
    },
    {
      "code_2": "GB",
      "code_3": "GBR",
      "numCode": "826",
      "name": "United Kingdom",
      "languages": [
        "eng",
        "cym"
      ],
      "langCultureMs": [
        {
          "langCultureName": "en-GB",
          "displayName": "English - United Kingdom",
          "cultureCode": "0x0809"
        }
      ]
    },
    {
      "code_2": "US",
      "code_3": "USA",
      "numCode": "840",
      "name": "United States",
      "languages": [
        "eng"
      ],
      "langCultureMs": [
        {
          "langCultureName": "en-US",
          "displayName": "English - United States",
          "cultureCode": "0x0409"
        }
      ]
    },
    {
      "code_2": "UM",
      "code_3": "UMI",
      "numCode": "581",
      "name": "United States Minor Outlying Islands"
    },
    {
      "code_2": "UY",
      "code_3": "URY",
      "numCode": "858",
      "name": "Uruguay",
      "languages": [
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-UY",
          "displayName": "Spanish - Uruguay",
          "cultureCode": "0x380A"
        }
      ]
    },
    {
      "code_2": "UZ",
      "code_3": "UZB",
      "numCode": "860",
      "name": "Uzbekistan",
      "languages": [
        "uzb"
      ],
      "langCultureMs": [
        {
          "langCultureName": "Cy-uz-UZ",
          "displayName": "Uzbek (Cyrillic) - Uzbekistan",
          "cultureCode": "0x0843"
        },
        {
          "langCultureName": "Lt-uz-UZ",
          "displayName": "Uzbek (Latin) - Uzbekistan",
          "cultureCode": "0x0443"
        }
      ]
    },
    {
      "code_2": "VU",
      "code_3": "VUT",
      "numCode": "548",
      "name": "Vanuatu",
      "languages": [
        "bis",
        "eng",
        "fra"
      ]
    },
    {
      "code_2": "VE",
      "code_3": "VEN",
      "numCode": "862",
      "name": "Venezuela, Bolivarian Republic of",
      "languages": [
        "spa"
      ],
      "langCultureMs": [
        {
          "langCultureName": "es-VE",
          "displayName": "Spanish - Venezuela",
          "cultureCode": "0x200A"
        }
      ]
    },
    {
      "code_2": "VN",
      "code_3": "VNM",
      "numCode": "704",
      "name": "Viet Nam",
      "languages": [
        "vie"
      ],
      "langCultureMs": [
        {
          "langCultureName": "vi-VN",
          "displayName": "Vietnamese - Vietnam",
          "cultureCode": "0x042A"
        }
      ]
    },
    {
      "code_2": "VG",
      "code_3": "VGB",
      "numCode": "092",
      "name": "Virgin Islands, British"
    },
    {
      "code_2": "VI",
      "code_3": "VIR",
      "numCode": "850",
      "name": "Virgin Islands, U.S."
    },
    {
      "code_2": "WF",
      "code_3": "WLF",
      "numCode": "876",
      "name": "Wallis and Futuna",
      "languages": [
        "fra"
      ]
    },
    {
      "code_2": "EH",
      "code_3": "ESH",
      "numCode": "732",
      "name": "Western Sahara",
      "languages": [
        "spa"
      ]
    },
    {
      "code_2": "YE",
      "code_3": "YEM",
      "numCode": "887",
      "name": "Yemen",
      "languages": [
        "ara"
      ],
      "langCultureMs": [
        {
          "langCultureName": "ar-YE",
          "displayName": "Arabic - Yemen",
          "cultureCode": "0x2401"
        }
      ]
    },
    {
      "code_2": "ZM",
      "code_3": "ZMB",
      "numCode": "894",
      "name": "Zambia",
      "languages": [
        "eng"
      ]
    },
    {
      "code_2": "ZW",
      "code_3": "ZWE",
      "numCode": "716",
      "name": "Zimbabwe",
      "languages": [
        "eng",
        "nde",
        "sna"
      ],
      "langCultureMs": [
        {
          "langCultureName": "en-ZW",
          "displayName": "English - Zimbabwe",
          "cultureCode": "0x3009"
        }
      ]
    }
  ],
  "locales": [
    [
      "af",
      "ZA"
    ],
    [
      "am",
      "ET"
    ],
    [
      "ar",
      "AE"
    ],
    [
      "ar",
      "BH"
    ],
    [
      "ar",
      "DZ"
    ],
    [
      "ar",
      "EG"
    ],
    [
      "ar",
      "IQ"
    ],
    [
      "ar",
      "JO"
    ],
    [
      "ar",
      "KW"
    ],
    [
      "ar",
      "LB"
    ],
    [
      "ar",
      "LY"
    ],
    [
      "ar",
      "MA"
    ],
    [
      "arn",
      "CL"
    ],
    [
      "ar",
      "OM"
    ],
    [
      "ar",
      "QA"
    ],
    [
      "ar",
      "SA"
    ],
    [
      "ar",
      "SY"
    ],
    [
      "ar",
      "TN"
    ],
    [
      "ar",
      "YE"
    ],
    [
      "as",
      "IN"
    ],
    [
      "az",
      "Cyrl",
      "AZ"
    ],
    [
      "az",
      "Latn",
      "AZ"
    ],
    [
      "ba",
      "RU"
    ],
    [
      "be",
      "BY"
    ],
    [
      "bg",
      "BG"
    ],
    [
      "bn",
      "BD"
    ],
    [
      "bn",
      "IN"
    ],
    [
      "bo",
      "CN"
    ],
    [
      "br",
      "FR"
    ],
    [
      "bs",
      "Cyrl",
      "BA"
    ],
    [
      "bs",
      "Latn",
      "BA"
    ],
    [
      "ca",
      "ES"
    ],
    [
      "co",
      "FR"
    ],
    [
      "cs",
      "CZ"
    ],
    [
      "cy",
      "GB"
    ],
    [
      "da",
      "DK"
    ],
    [
      "de",
      "AT"
    ],
    [
      "de",
      "CH"
    ],
    [
      "de",
      "DE"
    ],
    [
      "de",
      "LI"
    ],
    [
      "de",
      "LU"
    ],
    [
      "dsb",
      "DE"
    ],
    [
      "dv",
      "MV"
    ],
    [
      "el",
      "GR"
    ],
    [
      "en",
      "029"
    ],
    [
      "en",
      "AU"
    ],
    [
      "en",
      "BZ"
    ],
    [
      "en",
      "CA"
    ],
    [
      "en",
      "GB"
    ],
    [
      "en",
      "IE"
    ],
    [
      "en",
      "IN"
    ],
    [
      "en",
      "JM"
    ],
    [
      "en",
      "MY"
    ],
    [
      "en",
      "NZ"
    ],
    [
      "en",
      "PH"
    ],
    [
      "en",
      "SG"
    ],
    [
      "en",
      "TT"
    ],
    [
      "en",
      "US"
    ],
    [
      "en",
      "ZA"
    ],
    [
      "en",
      "ZW"
    ],
    [
      "es",
      "AR"
    ],
    [
      "es",
      "BO"
    ],
    [
      "es",
      "CL"
    ],
    [
      "es",
      "CO"
    ],
    [
      "es",
      "CR"
    ],
    [
      "es",
      "DO"
    ],
    [
      "es",
      "EC"
    ],
    [
      "es",
      "ES"
    ],
    [
      "es",
      "GT"
    ],
    [
      "es",
      "HN"
    ],
    [
      "es",
      "MX"
    ],
    [
      "es",
      "NI"
    ],
    [
      "es",
      "PA"
    ],
    [
      "es",
      "PE"
    ],
    [
      "es",
      "PR"
    ],
    [
      "es",
      "PY"
    ],
    [
      "es",
      "SV"
    ],
    [
      "es",
      "US"
    ],
    [
      "es",
      "UY"
    ],
    [
      "es",
      "VE"
    ],
    [
      "et",
      "EE"
    ],
    [
      "eu",
      "ES"
    ],
    [
      "fa",
      "IR"
    ],
    [
      "fi",
      "FI"
    ],
    [
      "fil",
      "PH"
    ],
    [
      "fo",
      "FO"
    ],
    [
      "fr",
      "BE"
    ],
    [
      "fr",
      "CA"
    ],
    [
      "fr",
      "CH"
    ],
    [
      "fr",
      "FR"
    ],
    [
      "fr",
      "LU"
    ],
    [
      "fr",
      "MC"
    ],
    [
      "fy",
      "NL"
    ],
    [
      "ga",
      "IE"
    ],
    [
      "gd",
      "GB"
    ],
    [
      "gl",
      "ES"
    ],
    [
      "gsw",
      "FR"
    ],
    [
      "gu",
      "IN"
    ],
    [
      "ha",
      "Latn",
      "NG"
    ],
    [
      "he",
      "IL"
    ],
    [
      "hi",
      "IN"
    ],
    [
      "hr",
      "BA"
    ],
    [
      "hr",
      "HR"
    ],
    [
      "hsb",
      "DE"
    ],
    [
      "hu",
      "HU"
    ],
    [
      "hy",
      "AM"
    ],
    [
      "id",
      "ID"
    ],
    [
      "ig",
      "NG"
    ],
    [
      "ii",
      "CN"
    ],
    [
      "is",
      "IS"
    ],
    [
      "it",
      "CH"
    ],
    [
      "it",
      "IT"
    ],
    [
      "iu",
      "Cans",
      "CA"
    ],
    [
      "iu",
      "Latn",
      "CA"
    ],
    [
      "ja",
      "JP"
    ],
    [
      "ka",
      "GE"
    ],
    [
      "kk",
      "KZ"
    ],
    [
      "kl",
      "GL"
    ],
    [
      "km",
      "KH"
    ],
    [
      "kn",
      "IN"
    ],
    [
      "kok",
      "IN"
    ],
    [
      "ko",
      "KR"
    ],
    [
      "ky",
      "KG"
    ],
    [
      "lb",
      "LU"
    ],
    [
      "lo",
      "LA"
    ],
    [
      "lt",
      "LT"
    ],
    [
      "lv",
      "LV"
    ],
    [
      "mi",
      "NZ"
    ],
    [
      "mk",
      "MK"
    ],
    [
      "ml",
      "IN"
    ],
    [
      "mn",
      "MN"
    ],
    [
      "mn",
      "Mong",
      "CN"
    ],
    [
      "moh",
      "CA"
    ],
    [
      "mr",
      "IN"
    ],
    [
      "ms",
      "BN"
    ],
    [
      "ms",
      "MY"
    ],
    [
      "mt",
      "MT"
    ],
    [
      "nb",
      "NO"
    ],
    [
      "ne",
      "NP"
    ],
    [
      "nl",
      "BE"
    ],
    [
      "nl",
      "NL"
    ],
    [
      "nn",
      "NO"
    ],
    [
      "nso",
      "ZA"
    ],
    [
      "oc",
      "FR"
    ],
    [
      "or",
      "IN"
    ],
    [
      "pa",
      "IN"
    ],
    [
      "pl",
      "PL"
    ],
    [
      "prs",
      "AF"
    ],
    [
      "ps",
      "AF"
    ],
    [
      "pt",
      "BR"
    ],
    [
      "pt",
      "PT"
    ],
    [
      "qut",
      "GT"
    ],
    [
      "quz",
      "BO"
    ],
    [
      "quz",
      "EC"
    ],
    [
      "quz",
      "PE"
    ],
    [
      "rm",
      "CH"
    ],
    [
      "ro",
      "RO"
    ],
    [
      "ru",
      "RU"
    ],
    [
      "rw",
      "RW"
    ],
    [
      "sah",
      "RU"
    ],
    [
      "sa",
      "IN"
    ],
    [
      "se",
      "FI"
    ],
    [
      "se",
      "NO"
    ],
    [
      "se",
      "SE"
    ],
    [
      "si",
      "LK"
    ],
    [
      "sk",
      "SK"
    ],
    [
      "sl",
      "SI"
    ],
    [
      "sma",
      "NO"
    ],
    [
      "sma",
      "SE"
    ],
    [
      "smj",
      "NO"
    ],
    [
      "smj",
      "SE"
    ],
    [
      "smn",
      "FI"
    ],
    [
      "sms",
      "FI"
    ],
    [
      "sq",
      "AL"
    ],
    [
      "sr",
      "Cyrl",
      "BA"
    ],
    [
      "sr",
      "Cyrl",
      "CS"
    ],
    [
      "sr",
      "Cyrl",
      "ME"
    ],
    [
      "sr",
      "Cyrl",
      "RS"
    ],
    [
      "sr",
      "Latn",
      "BA"
    ],
    [
      "sr",
      "Latn",
      "CS"
    ],
    [
      "sr",
      "Latn",
      "ME"
    ],
    [
      "sr",
      "Latn",
      "RS"
    ],
    [
      "sv",
      "FI"
    ],
    [
      "sv",
      "SE"
    ],
    [
      "sw",
      "KE"
    ],
    [
      "syr",
      "SY"
    ],
    [
      "ta",
      "IN"
    ],
    [
      "te",
      "IN"
    ],
    [
      "tg",
      "Cyrl",
      "TJ"
    ],
    [
      "th",
      "TH"
    ],
    [
      "tk",
      "TM"
    ],
    [
      "tn",
      "ZA"
    ],
    [
      "tr",
      "TR"
    ],
    [
      "tt",
      "RU"
    ],
    [
      "tzm",
      "Latn",
      "DZ"
    ],
    [
      "ug",
      "CN"
    ],
    [
      "uk",
      "UA"
    ],
    [
      "ur",
      "PK"
    ],
    [
      "uz",
      "Cyrl",
      "UZ"
    ],
    [
      "uz",
      "Latn",
      "UZ"
    ],
    [
      "vi",
      "VN"
    ],
    [
      "wo",
      "SN"
    ],
    [
      "xh",
      "ZA"
    ],
    [
      "yo",
      "NG"
    ],
    [
      "zh",
      "CN"
    ],
    [
      "zh",
      "HK"
    ],
    [
      "zh",
      "MO"
    ],
    [
      "zh",
      "SG"
    ],
    [
      "zh",
      "TW"
    ],
    [
      "zu",
      "ZA"
    ]
  ]
}
