export enum QuestionTypesEnum {
  SCORE = 'SCORE',
  SCORE_PLUS = 'SCORE_PLUS',
  RANGE = `RANGE`,
  OPTIONS = `OPTIONS`,
  LIST = 'LIST',
  LISTS = 'LISTS',
  GRID = 'GRID',
}

export const QuestionTypesOptions = [
  { label: 'Correct score format', value: QuestionTypesEnum.SCORE },
  { label: 'Custom Score', value: QuestionTypesEnum.SCORE_PLUS },
  { label: 'Select number from slider', value: QuestionTypesEnum.RANGE },
  { label: 'Select from 2 answers option', value: QuestionTypesEnum.OPTIONS },
  { label: '1 from 1 list', value: QuestionTypesEnum.LIST },
  { label: '1 from 2 list', value: QuestionTypesEnum.LISTS },
  { label: '1 from grid', value: QuestionTypesEnum.GRID },
]

