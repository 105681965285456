import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { catchError, map, Observable, of, switchMap } from 'rxjs';

import { APP_ROUTES } from "@app/app.routes.config";
import { AuthService } from "@services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}
  canActivate(): Observable<boolean> {

    return this.getCurrentUser().pipe(
      switchMap(() => {
        return of(true);
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  /**
   * Prevent Admin and Vendor open unavailable URL
   */
  getCurrentUser(): Observable<any> {
    return this.authService.getCurrentUser().pipe(
      map(user => {
        if (!user) {
          return true
        } else {
          const role = this.authService.getCurrentRole();
          if (role === 'VENDOR') {
            this.router.navigate([`/v/${APP_ROUTES.DASHBOARD}`]);
          } else {
            this.router.navigate([`/a/${APP_ROUTES.VENDORS}`]);
          }
        }
      })
    );
  }

}
