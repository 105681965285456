import { AsyncPipe, ViewportScroller } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Title } from "@angular/platform-browser";
import { RouterOutlet } from '@angular/router';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { CurrentLocationService } from "@services/current-location.service";
import { SaasSettingsService } from "@services/saas-settings.service";
import { TextService } from "@services/text.service";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, MatProgressSpinner, AsyncPipe]
})

export class AppComponent implements OnInit, OnDestroy {
  currentYear: number;

  pagePath: string;

  private APP_NAME = 'Hunch App';

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private titleService: Title,
    private currentLocationService: CurrentLocationService,
    private textService: TextService,
    public saasSettingsService: SaasSettingsService,
    public viewportScroller: ViewportScroller,
  ) {
    this.viewportScroller.setOffset([0, 64]);
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.currentLocationService.getCurrentLocation();
    this.currentLocationService.getCurrentPage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(pagePath => {
        this.pagePath = pagePath;
        const pageName = this.textService.createNameWithSpaces(pagePath);
        this.titleService.setTitle(pageName + ' - ' + this.APP_NAME);
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

