import { HttpClient } from '@angular/common/http';

import { LocalStorageEnum } from "@enums/LocalStorageEnum";
import { AuthService } from "@services/auth.service";
import { LocalStorageService } from "@services/local-storage.service";
import { SaasSettingsService } from "@services/saas-settings.service";

/**
 * Inject services, and before all app will load call function to get application config
 * Set up role of current user, becouse config need only for vendor
 */
export function initializeAppFactory( saasSetingsService: SaasSettingsService, http: HttpClient, authService: AuthService, localStorageService: LocalStorageService) {
  return () => {
    authService.setCurrentRole(localStorageService.getItem(LocalStorageEnum.ROLE));
    return saasSetingsService.loadConfig()
  };
}
