import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

import { UriConfig } from "@app/app.config";
import { UserDetailsModel } from "@models/UserDetailsModel";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  currentUser$ = new BehaviorSubject<UserDetailsModel>(null);

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
  ) { }

  fetchUserInfo():Observable<UserDetailsModel> {
    return this.http.get<UserDetailsModel>(this.uriConfig.user);
  }

  fetchAdminInfo():Observable<UserDetailsModel> {
    return this.http.get<UserDetailsModel>(this.uriConfig.admins);
  }


  updateVendorUser(body) {
    return this.http.patch<any>(this.uriConfig.user, body);
  }

  createBrand(body) {
    return this.http.post<any>(this.uriConfig.brands, body);
  }
}
