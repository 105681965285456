import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular";
import { Observable, of, tap } from "rxjs";

import { UriConfig } from "@app/app.config";
import { APP_ROUTES } from "@app/app.routes.config";
import { SentryUserModel } from "@models/SentryUserModel";
import { SnackBarService } from "@services/snack-bar.service";
import { UserRole } from 'src/app/common/Enums/UserRoleEnum';

import { LocalStorageService } from "./local-storage.service";





@Injectable({
  providedIn: 'root'
})
export class AuthService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  }

  currentRole: UserRole;

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
    private localStorageService: LocalStorageService,
    private snackBarService: SnackBarService
  ) { }

  logOut() {
    this.http.delete(this.uriConfig.logout, this.httpOptions)
      .subscribe(() => {
        this.localStorageService.logOut();
        window.location.href = '/' + APP_ROUTES.AUTH + '/login';
      });
  }


  logOutB2C(playerId: number): Observable<any> {
    return this.http.delete(this.uriConfig.logout + '/' + playerId).pipe(
      tap(() => {
        this.snackBarService.showSnackBar(`The user with ID:${playerId} was logout successfully`)
      })
    );
  }

  refreshToken (token) {
    return this.http.post<any>(this.uriConfig.refresh, {}, token);
  }

  getCurrentUser(): Observable<any> {
    return of(this.localStorageService.getToken());
  }

  login(user: any): Observable<{accessToken: string, refreshToken: string }> {
    return this.http.post<any>(this.uriConfig.login, user, this.httpOptions);
  }

  validateRestoreToken(body): Observable<any> {
    return this.http.post<string>(this.uriConfig.validateResetToken, body)
  }

  resetPassword(body): Observable<any> {
    return  this.http.post<string>(this.uriConfig.resetPassword, body)
  }

  restorePassword(body): Observable<any> {
    return this.http.post<string>(this.uriConfig.restorePassword, body)
  }

  changePassword(body): Observable<any> {
    return this.http.post<string>(this.uriConfig.changePassword, body)
  }

  getUserToken() {
    return this.http.post<{accessToken: string, refreshToken: string}>(this.uriConfig.auth + '/preview-user', {});
  }

  getAccessAndRefreshTokensForB2C(userId: number) {
    return this.http.post<{accessToken: string, refreshToken: string}>(this.uriConfig.auth + `/signin/${userId}/b2c`, {});
  }

  setSentryUser(user: SentryUserModel) {
    const { email } = user;
    Sentry.setUser({ email });
  }

  setCurrentRole(role) {
    this.currentRole = role;
  }

  getCurrentRole(): UserRole {
    return this.currentRole;
  }

}

