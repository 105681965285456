import { Routes } from '@angular/router';

import { AuthAdminGuard } from "@guards/auth-admin.guard";
import { AuthGuard } from "@guards/auth.guard";
import { SessionGuard } from "@guards/session.guard";

import { APP_ROUTES } from "./app.routes.config";

export const routes: Routes = [
  {
    path: APP_ROUTES.AUTH,
    canActivate: [SessionGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: APP_ROUTES.RESTORE_PASSWORD,
    canActivate: [SessionGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: APP_ROUTES.PORTAL,
    canActivate: [AuthGuard],
    loadChildren: () => import('./main-content-area-component/main-content-area-routing.module').then((m) => m.getRoutes()),
  },
  {
    path: APP_ROUTES.PORTAL_ADMIN,
    canActivate: [AuthAdminGuard],
    loadChildren: () => import('./main-content-area-component/main-content-area-routing.module').then((m) => m.getRoutes()),
  },
  {
    path: '',
    redirectTo: APP_ROUTES.PORTAL,
    pathMatch: 'full',
  },
];
