'use strict';

var $ = require('../internals/export');
var log2 = require('../internals/math-log2');

// `Math.log2` method
// https://tc39.es/ecma262/#sec-math.log2
$({
  target: 'Math',
  stat: true
}, {
  log2: log2
});