export enum LocalStorageEnum {
  TOKEN_KEY = 'auth-token',
  REFRESHTOKEN_KEY = 'auth-refreshtoken',
  HASH = 'hash',
  SETTINGS = 'settings',
  CHILD_WINDOWS_IDS = 'childWindowsIds',
  ROLE = 'role',
  TWO_FA = '2fa',
  EMAIL = 'email',
  IS_STREAK = 'is-streak'
}
